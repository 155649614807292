import React, { createElement, useState } from "react";
import {
  View,
  TextInput,
  Text,
  StyleSheet,
  Dimensions,
  Platform,
  Pressable,
} from "react-native";
import { Input } from "react-native-elements";
import { colors } from "./colour";
import DateTimePicker from "@react-native-community/datetimepicker";
const screenWidth = Dimensions.get("window").width;

const TextField = ({
  label,
  placeholder,
  value,
  onChangeText,
  keyboardType,
  validation,
  containerStyle,
  isRequired,
  onBlur,
  name,
  onChange,
}) => {
  const [error, setError] = useState("");

  const handleValidation = () => {
    if (validation && !validation.test(value)) {
      setError("Invalid " + label);
    } else {
      setError("");
    }
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.container}>
        <Text style={styles.label}>
          {label}
          {isRequired && <Text style={styles.requiredAsterisk}>*</Text>}
        </Text>
        <TextInput
          style={styles.input}
          placeholder={placeholder}
          placeholderTextColor="grey"
          value={value}
          onChangeText={(text) => {
            onChangeText(text);
            handleValidation();
          }}
          inputMode={keyboardType || "default"}
          onChange={undefined}
          // name={"name"}
        />
        {/* <Text style={styles.Required}>Required</Text> */}
        <Text style={styles.errorText}>{error}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: Platform.OS === "web" && screenWidth > 760 ? 5 : 0,
  },
  label: {
    paddingBottom: 10,
    fontFamily: "PlusJakartaSansMedium",
    paddingTop: 10,
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 14 : 16,
    color: colors.white,
  },
  input: {
    height: 34,
    fontSize: 15,
    fontFamily: "PlusJakartaSansRegular",
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 10,
    outlineColor: "transparent",
    outlineWidth: 0,
    borderColor: colors.borderColor,
    color: colors.white,
  },
  errorText: {
    color: "red",
    fontSize: 12,
  },
  requiredAsterisk: {
    color: "red",
    marginLeft: 4,
  },
  Required: {
    // color: "#fff",
    color: "red",
  },
});

export default TextField;

export const MobileNumberField = ({
  label,
  placeholder,
  value,
  onChangeText,
  validation,
  containerStyle,
  isRequired,
  onSubmitEditing,
}) => {
  const [error, setError] = useState("");

  const handleValidation = () => {
    if (validation && !validation.test(value)) {
      setError("Invalid " + label);
    } else {
      setError("");
    }
  };

  return (
    <View style={[MobTextfeildstyles.container, containerStyle]}>
      {label && (
        <Text style={styles.label}>
          {label}
          {isRequired && <Text style={styles.requiredAsterisk}>*</Text>}
        </Text>
      )}
      <View style={MobTextfeildstyles.inputContainer}>
        <Text style={MobTextfeildstyles.prefix}>+91 </Text>
        <TextInput
          style={MobTextfeildstyles.input}
          placeholder={placeholder}
          placeholderTextColor="grey"
          value={value}
          onSubmitEditing={onSubmitEditing}
          onChangeText={(text) => {
            onChangeText(text);
            handleValidation();
          }}
          keyboardType="phone-pad"
          selectionColor="transparent"
        />
      </View>
      <Text style={MobTextfeildstyles.errorText}>{error}</Text>
    </View>
  );
};

const MobTextfeildstyles = StyleSheet.create({
  container: {
    marginBottom: 5,
  },
  label: {
    paddingBottom: 10,
    paddingTop: 10,
    fontFamily: "PlusJakartaSansBold",
    fontSize: 14,
  },
  inputContainer: {
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 10,
    flexDirection: "row",
    alignItems: "center",
    height: Platform.OS === "web" && screenWidth > 760 ? 34 : 45,
    fontFamily: "PlusJakartaSansRegular",
  },
  prefix: {
    marginRight: 5,
    color: colors.white,
    fontSize: 18,
    fontFamily: "PlusJakartaSansRegular",
    borderRightWidth: 1,
    borderRightColor: "#222",
    paddingRight: 5,
  },
  input: {
    flex: 1,
    height: "100%",
    outlineColor: "transparent",
    outlineWidth: 0,
    fontSize: 15,
    color: colors.white,
  },
  errorText: {
    color: "red",
    fontSize: 12,
    fontFamily: "PlusJakartaSansRegular",
  },
  requiredAsterisk: {
    color: "red",
    marginLeft: 4,
  },
});

export const CustomDateField = ({
  label,
  containerStyle,
  placeholder,
  isRequired,
  value,
  onChangeText,
  onBlur,
  id,
}) => {
  // const [date, setDate] = useState(new Date(Date.now()));

  return (
    <View style={[datePickerStyles.container, containerStyle]}>
      {isRequired && (
        <>
          {label && <Text style={datePickerStyles.label}>{label}</Text>}
          {isRequired && (
            <Text style={datePickerStyles.requiredAsterisk}></Text>
          )}
        </>
      )}
      <input
        id={id}
        // name="name"
        type="date"
        placeholder={placeholder}
        autoFocus={false}
        // colorScheme= "dark"
        value={value}
        onChange={onChangeText}
        onBlur={onBlur}
        style={{
          borderColor: colors.borderColor,
          borderWidth: 1,
          borderRadius: 5,
          paddingLeft: 10,
          flexDirection: "row",
          alignItems: "center",
          // cursor: "pointer",
          height: 30,
          fontFamily: "PlusJakartaSansRegular",
          // outlineColor: "transparent",
          // outlineWidth: 0,
          backgroundColor: colors.royalBlue,
          color: colors.white,
        }}
      />
    </View>
  );
};

const datePickerStyles = {
  container: {
    marginBottom: 5,
  },
  label: {
    paddingBottom: 10,
    fontFamily: "PlusJakartaSansRegular",
    paddingTop: 10,
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 14 : 12,
    color: colors.white,
  },
  requiredAsterisk: {
    color: colors.red,
    marginLeft: 4,
  },
};

export const CustomDateFieldForMob = ({
  label,
  containerStyle,
  placeholder,
  isRequired,
  value,
  onChangeText,
  onBlur,
  id,
}) => {
  const [show, setShow] = useState(false);
  const [date, setDate] = useState(value ? new Date(value) : new Date());

  const onChange = (event, selectedDate) => {
    setShow(false);
    if (selectedDate) {
      setDate(selectedDate);
      onChangeText(selectedDate.toISOString().split("T")[0]);
    }
  };

  const showDatepicker = () => {
    setShow(true);
  };

  return (
    <View style={[customDateFieldForMobstyles.container, containerStyle]}>
      {label && (
        <View style={customDateFieldForMobstyles.labelContainer}>
          <Text style={customDateFieldForMobstyles.label}>{label}</Text>
          {isRequired && (
            <Text style={customDateFieldForMobstyles.requiredAsterisk}>*</Text>
          )}
        </View>
      )}
      <Pressable
        onPress={showDatepicker}
        style={customDateFieldForMobstyles.touchable}
      >
        <Text style={customDateFieldForMobstyles.dateText}>
          {date ? date.toLocaleDateString() : placeholder}
        </Text>
      </Pressable>
      {show && (
        <DateTimePicker
          value={date}
          mode="date"
          display="default"
          onChange={onChange}
        />
      )}
    </View>
  );
};

const customDateFieldForMobstyles = StyleSheet.create({
  container: {
    marginBottom: 5,
  },
  labelContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    paddingBottom: 10,
    fontFamily: "PlusJakartaSansRegular",
    paddingTop: 10,
    fontSize: Platform.OS === "web" && window.innerWidth > 760 ? 14 : 12,
    color: colors.white,
  },
  requiredAsterisk: {
    color: colors.red,
    marginLeft: 4,
  },
  touchable: {
    borderColor: colors.borderColor,
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 10,
    height: 30,
    justifyContent: "center",
    backgroundColor: colors.royalBlue,
  },
  dateText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
  },
});
