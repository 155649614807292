// import React, { useState, useEffect } from "react";
// import {
//   View,
//   Text,
//   TouchableOpacity,
//   Modal,
//   StyleSheet,
//   ScrollView,
//   TextInput,
// } from "react-native";
// import { colors } from "./colour";
// import Icon from "react-native-vector-icons/FontAwesome";

// const daysInMonth = (month, year) => new Date(year, month, 0).getDate();
// const getFirstDayOfMonth = (month, year) =>
//   new Date(year, month - 1, 1).getDay();

// const CustomDatePicker = ({ selected, onChange, containerStyle, portalId }) => {
//   const isValidDate = (date) => date instanceof Date && !isNaN(date);

//   const initialDate = isValidDate(new Date(selected))
//     ? new Date(selected)
//     : new Date();
//   const [selectedDate, setSelectedDate] = useState(initialDate);
//   const [modalVisible, setModalVisible] = useState(false);
//   const [yearListVisible, setYearListVisible] = useState(false);
//   const [currentDay, setCurrentDay] = useState(
//     initialDate.getDate().toString()
//   );
//   const [currentMonth, setCurrentMonth] = useState(
//     (initialDate.getMonth() + 1).toString()
//   );
//   const [currentYear, setCurrentYear] = useState(
//     initialDate.getFullYear().toString()
//   );

//   const today = new Date();
//   const daysArray = Array.from(
//     { length: daysInMonth(parseInt(currentMonth), parseInt(currentYear)) },
//     (_, i) => i + 1
//   );
//   const firstDay = getFirstDayOfMonth(
//     parseInt(currentMonth),
//     parseInt(currentYear)
//   );

//   const handleDateChange = (day) => {
//     const newDate = new Date(
//       parseInt(currentYear),
//       parseInt(currentMonth) - 1,
//       day
//     );
//     setSelectedDate(newDate);
//     setCurrentDay(day.toString());
//     setModalVisible(false);
//     onChange(newDate);
//   };

//   const handleMonthChange = (direction) => {
//     let newMonth = parseInt(currentMonth) + direction;
//     let newYear = parseInt(currentYear);
//     if (newMonth < 1) {
//       newMonth = 12;
//       newYear -= 1;
//     } else if (newMonth > 12) {
//       newMonth = 1;
//       newYear += 1;
//     }
//     setCurrentMonth(newMonth.toString());
//     setCurrentYear(newYear.toString());
//   };

//   const handleYearChange = (year) => {
//     setCurrentYear(year.toString());
//     setYearListVisible(false);
//   };

//   const handleDayInputChange = (text) => {
//     const day = parseInt(text);
//     if (
//       !isNaN(day) &&
//       day > 0 &&
//       day <= daysInMonth(parseInt(currentMonth), parseInt(currentYear))
//     ) {
//       setCurrentDay(text);
//       handleDateChange(day);
//     } else {
//       setCurrentDay(text);
//     }
//   };

//   const handleMonthInputChange = (text) => {
//     const month = parseInt(text);
//     if (text === "") {
//       setCurrentMonth(text);
//     } else {
//       if (!isNaN(month) && month > 0 && month <= 12) {
//         setCurrentMonth(text);
//         const newDate = new Date(
//           parseInt(currentYear),
//           month - 1,
//           parseInt(currentDay)
//         );
//         if (!isNaN(newDate.getTime())) {
//           setSelectedDate(newDate);
//           onChange(newDate);
//         }
//       }
//     }
//   };

//   const handleYearInputChange = (text) => {
//     const year = parseInt(text);
//     if (text === "") {
//       setCurrentYear(text);
//     } else {
//       if (!isNaN(year)) {
//         setCurrentYear(text);
//         if (currentDay && currentMonth) {
//           const newDate = new Date(
//             year,
//             parseInt(currentMonth) - 1,
//             parseInt(currentDay)
//           );
//           if (isValidDate(newDate)) {
//             setSelectedDate(newDate);
//             onChange(newDate);
//           }
//         }
//       }
//     }
//   };

//   const renderDays = () => {
//     const dayComponents = [];
//     for (let i = 0; i < firstDay; i++) {
//       dayComponents.push(<View key={`empty-${i}`} style={styles.dayCell} />);
//     }
//     daysArray.forEach((day) => {
//       const isSelected = selectedDate.getDate() === day;
//       const isToday =
//         today.getDate() === day &&
//         today.getMonth() === parseInt(currentMonth) - 1 &&
//         today.getFullYear() === parseInt(currentYear);
//       const cellStyle = isSelected
//         ? [styles.dayCell, styles.selectedDay]
//         : isToday
//           ? [styles.dayCell, styles.today]
//           : styles.dayCell;

//       dayComponents.push(
//         <TouchableOpacity
//           key={day}
//           onPress={() => handleDateChange(day)}
//           style={cellStyle}
//         >
//           <Text
//             style={[styles.dayText, isSelected ? styles.selectedText : null]}
//           >
//             {day}
//           </Text>
//         </TouchableOpacity>
//       );
//     });
//     return dayComponents;
//   };

//   const renderYearList = () => {
//     const years = Array.from(
//       { length: new Date().getFullYear() - 1950 },
//       (_, i) => 1951 + i
//     );
//     return (
//       <ScrollView style={styles.yearList}>
//         {years.map((year) => (
//           <TouchableOpacity
//             key={year}
//             onPress={() => handleYearChange(year)}
//             style={styles.yearItem}
//           >
//             <Text style={styles.yearText}>{year}</Text>
//           </TouchableOpacity>
//         ))}
//       </ScrollView>
//     );
//   };

//   useEffect(() => {
//     if (selected) {
//       const selectedDate = new Date(selected);
//       if (isValidDate(selectedDate)) {
//         setSelectedDate(selectedDate);
//         setCurrentDay(selectedDate.getDate().toString());
//         setCurrentMonth(
//           (selectedDate.getMonth() + 1).toString().padStart(2, "0")
//         );
//         setCurrentYear(selectedDate.getFullYear().toString());
//       }
//     }
//   }, [selected]);

//   useEffect(() => {
//     if (currentDay && currentMonth && currentYear) {
//       const newDate = new Date(
//         parseInt(currentYear),
//         parseInt(currentMonth) - 1,
//         parseInt(currentDay)
//       );
//       if (isValidDate(newDate)) {
//         setSelectedDate(newDate);
//         onChange(newDate);
//       }
//     }
//   }, [currentDay, currentMonth, currentYear]);

//   return (
//     <View style={[styles.dateButton, containerStyle]}>
//       <TextInput
//         style={[styles.input, { width: 15 }]}
//         value={currentDay}
//         onChangeText={handleDayInputChange}
//         keyboardType="numeric"
//         maxLength={2}
//       />
//       <Text style={styles.separator}>/</Text>
//       <TextInput
//         style={[styles.input, { width: 15 }]}
//         value={currentMonth}
//         onChangeText={handleMonthInputChange}
//         keyboardType="numeric"
//         maxLength={2}
//       />
//       <Text style={styles.separator}>/</Text>
//       <TextInput
//         style={[styles.input, { width: 30 }]}
//         value={currentYear}
//         onChangeText={handleYearInputChange}
//         keyboardType="numeric"
//         maxLength={4}
//       />

//       <TouchableOpacity onPress={() => setModalVisible(true)}>
//         <Icon
//           name="calendar"
//           size={15}
//           color={colors.aqua}
//           style={styles.icon}
//         />
//       </TouchableOpacity>

//       {modalVisible && (
//         <Modal transparent={true} animationType="slide" visible={modalVisible}>
//           <View style={styles.modalBackground}>
//             <View style={styles.modalContainer}>
//               <View style={styles.headerContainer}>
//                 <TouchableOpacity
//                   onPress={() => setYearListVisible(true)}
//                   style={styles.navButton}
//                 >
//                   <Text style={styles.navButtonText}>{currentYear}</Text>
//                 </TouchableOpacity>
//                 <TouchableOpacity
//                   onPress={() => handleMonthChange(-1)}
//                   style={styles.navButton}
//                 >
//                   <Text style={styles.navButtonText}>{"<"}</Text>
//                 </TouchableOpacity>
//                 <Text style={styles.modalTitle}>
//                   {new Date(
//                     parseInt(currentYear),
//                     parseInt(currentMonth) - 1
//                   ).toLocaleString("default", { month: "long" })}{" "}
//                   {currentYear}
//                 </Text>
//                 <TouchableOpacity
//                   onPress={() => handleMonthChange(1)}
//                   style={styles.navButton}
//                 >
//                   <Text style={styles.navButtonText}>{">"}</Text>
//                 </TouchableOpacity>
//               </View>

//               {yearListVisible ? (
//                 renderYearList()
//               ) : (
//                 <View style={styles.calendarContainer}>
//                   {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
//                     <Text key={index} style={styles.dayLabel}>
//                       {day}
//                     </Text>
//                   ))}
//                   {renderDays()}
//                 </View>
//               )}

//               <TouchableOpacity
//                 onPress={() => setModalVisible(false)}
//                 style={styles.closeButton}
//               >
//                 <Text style={styles.closeButtonText}>Close</Text>
//               </TouchableOpacity>
//             </View>
//           </View>
//         </Modal>
//       )}
//     </View>
//   );
// };

// const styles = StyleSheet.create({
//   container: {
//     alignItems: "center",
//     justifyContent: "space-between",
//     marginTop: 10,
//     paddingHorizontal: 5,
//   },
//   dateButton: {
//     flexDirection: "row",
//     alignItems: "center",
//     paddingHorizontal: 5,
//     border: "1px solid rgb(85, 85, 85)",
//     // backgroundColor: colors.bgColor,
//     borderRadius: 5,
//   },
//   dateText: {
//     color: "white",
//     fontSize: 14,
//     marginLeft: 10,
//   },
//   input: {
//     color: "white",
//     fontSize: 14,
//     width: 25,
//     textAlign: "center",
//     outlineColor: "transparent",
//     outlineWidth: 0,
//   },
//   separator: {
//     color: "white",
//     fontSize: 12,
//     marginHorizontal: 1,
//   },
//   modalBackground: {
//     flex: 1,
//     backgroundColor: "rgba(0, 0, 0, 0.5)",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   modalContainer: {
//     width: 250,
//     backgroundColor: colors.white,
//     padding: 15,
//     borderRadius: 10,
//   },
//   headerContainer: {
//     flexDirection: "row",
//     justifyContent: "space-between",
//     alignItems: "center",
//     marginBottom: 5,
//   },
//   navButton: {
//     padding: 5,
//     backgroundColor: colors.white,
//     borderRadius: 5,
//   },
//   navButtonText: {
//     color: colors.bgColor,
//     fontWeight: "bold",
//     fontSize: 12,
//   },
//   modalTitle: {
//     fontSize: 16,
//   },
//   yearText: {
//     fontSize: 16,
//   },
//   calendarContainer: {
//     flexDirection: "row",
//     flexWrap: "wrap",
//     justifyContent: "space-between",
//   },
//   dayLabel: {
//     width: 30,
//     textAlign: "center",
//     fontWeight: "bold",
//     fontSize: 12,
//   },
//   dayCell: {
//     width: 30,
//     height: 30,
//     justifyContent: "center",
//     alignItems: "center",
//     margin: 1,
//     backgroundColor: "#E0E0E0",
//     borderRadius: 5,
//   },
//   dayText: {
//     fontSize: 12,
//   },
//   selectedDay: {
//     backgroundColor: colors.orange,
//   },
//   today: {
//     backgroundColor: colors.bgColor,
//   },
//   selectedText: {
//     color: "white",
//   },
//   closeButton: {
//     marginTop: 10,
//     padding: 5,
//     backgroundColor: colors.aqua,
//     borderRadius: 5,
//     alignItems: "center",
//   },
//   closeButtonText: {
//     color: colors.blueColour,
//     fontSize: 14,
//     fontWeight: "bold",
//   },
//   yearList: {
//     maxHeight: 200,
//   },
//   yearItem: {
//     padding: 5,
//     alignItems: "center",
//   },
//   icon: {
//     marginLeft: 10,
//   },
// });

// export default CustomDatePicker;
import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Modal,
  StyleSheet,
  ScrollView,
  TextInput,
  Dimensions,Platform
} from "react-native";
import { colors } from "./colour";
import Icon from "react-native-vector-icons/FontAwesome";

const daysInMonth = (month, year) => new Date(year, month, 0).getDate();
const getFirstDayOfMonth = (month, year) =>
  new Date(year, month - 1, 1).getDay();

const CustomDatePicker = ({ selected, onChange, containerStyle, portalId }) => {
  const isValidDate = (date) => date instanceof Date && !isNaN(date);

  const initialDate = isValidDate(new Date(selected)) ? new Date(selected) : new Date();
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [modalVisible, setModalVisible] = useState(false);
  const [yearListVisible, setYearListVisible] = useState(false);
  const [currentDay, setCurrentDay] = useState(initialDate.getDate().toString());
  const [currentMonth, setCurrentMonth] = useState((initialDate.getMonth() + 1).toString());
  const [currentYear, setCurrentYear] = useState(initialDate.getFullYear().toString());

  const today = new Date();
  const daysArray = Array.from(
    { length: daysInMonth(parseInt(currentMonth), parseInt(currentYear)) },
    (_, i) => i + 1
  );
  const firstDay = getFirstDayOfMonth(parseInt(currentMonth), parseInt(currentYear));

  const handleDateChange = (day) => {
    const newDate = new Date(parseInt(currentYear), parseInt(currentMonth) - 1, day);
    setSelectedDate(newDate);
    setCurrentDay(day.toString());
    setModalVisible(false);
    onChange(newDate);
  };

  const handleMonthChange = (direction) => {
    let newMonth = parseInt(currentMonth) + direction;
    let newYear = parseInt(currentYear);
    if (newMonth < 1) {
      newMonth = 12;
      newYear -= 1;
    } else if (newMonth > 12) {
      newMonth = 1;
      newYear += 1;
    }
    setCurrentMonth(newMonth.toString());
    setCurrentYear(newYear.toString());
  };

  const handleYearChange = (year) => {
    setCurrentYear(year.toString());
    setYearListVisible(false);
  };

  const handleDayInputChange = (text) => {
    const day = parseInt(text);
    if (!isNaN(day) && day > 0 && day <= daysInMonth(parseInt(currentMonth), parseInt(currentYear))) {
      setCurrentDay(text);
      handleDateChange(day);
    } else {
      setCurrentDay(text);  // Optionally show error message here
    }
  };

  const handleMonthInputChange = (text) => {
    const month = parseInt(text);
    if (text === "") {
      setCurrentMonth(text);
    } else {
      if (!isNaN(month) && month > 0 && month <= 12) {
        setCurrentMonth(text);
        const newDate = new Date(parseInt(currentYear), month - 1, parseInt(currentDay));
        if (!isNaN(newDate.getTime())) {
          setSelectedDate(newDate);
          onChange(newDate);
        }
      }
    }
  };

  const handleYearInputChange = (text) => {
    const year = parseInt(text);
    if (text === "") {
      setCurrentYear(text);
    } else {
      if (!isNaN(year)) {
        setCurrentYear(text);
        if (currentDay && currentMonth) {
          const newDate = new Date(year, parseInt(currentMonth) - 1, parseInt(currentDay));
          if (isValidDate(newDate)) {
            setSelectedDate(newDate);
            onChange(newDate);
          }
        }
      }
    }
  };

  const renderDays = () => {
    const dayComponents = [];
    // Array of day names
    const dayNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    dayNames.forEach((dayName, index) => {
      dayComponents.push(
        <View key={`day-name-${index}`} style={styles.dayCell}>
          <Text style={styles.dayName}>{dayName}</Text>
        </View>
      );
    });
    for (let i = 0; i < firstDay; i++) {
      dayComponents.push(<View key={`empty-${i}`} style={styles.dayCell} />);
    }
    daysArray.forEach((day) => {
      const isSelected = selectedDate.getDate() === day;
      const isToday =
        today.getDate() === day &&
        today.getMonth() === parseInt(currentMonth) - 1 &&
        today.getFullYear() === parseInt(currentYear);
      const cellStyle = isSelected
        ? [styles.dayCell, styles.selectedDay]
        : isToday
        ? [styles.dayCell, styles.today]
        : styles.dayCell;

      dayComponents.push(
        <TouchableOpacity
          key={day}
          onPress={() => handleDateChange(day)}
          style={cellStyle}
        >
          <Text style={[styles.dayText, isSelected ? styles.selectedText : null]}>
            {day}
          </Text>
        </TouchableOpacity>
      );
    });
    return dayComponents;
  };

  const renderYearList = () => {
    const years = Array.from(
      { length: new Date().getFullYear() - 1950 },
      (_, i) => 1951 + i
    );
    return (
      <ScrollView style={styles.yearList}>
        {years.map((year) => (
          <TouchableOpacity
            key={year}
            onPress={() => handleYearChange(year)}
            style={styles.yearItem}
          >
            <Text style={styles.yearText}>{year}</Text>
          </TouchableOpacity>
        ))}
      </ScrollView>
    );
  };

  useEffect(() => {
    if (selected) {
      const selectedDate = new Date(selected);
      if (isValidDate(selectedDate)) {
        setSelectedDate(selectedDate);
        setCurrentDay(selectedDate.getDate().toString());
        setCurrentMonth((selectedDate.getMonth() + 1).toString().padStart(2, "0"));
        setCurrentYear(selectedDate.getFullYear().toString());
      }
    }
  }, [selected]);

  useEffect(() => {
    if (currentDay && currentMonth && currentYear) {
      const newDate = new Date(parseInt(currentYear), parseInt(currentMonth) - 1, parseInt(currentDay));
      if (isValidDate(newDate)) {
        setSelectedDate(newDate);
        onChange(newDate);
      }
    }
  }, [currentDay, currentMonth, currentYear]);

  return (
    <View style={[styles.dateButton, containerStyle]}>
      <TextInput
        style={[styles.input, { width: 15 }]}
        value={currentDay}
        onChangeText={handleDayInputChange}
        keyboardType="numeric"
        maxLength={2}
      />
      <Text style={styles.separator}>/</Text>
      <TextInput
        style={[styles.input, { width: 15 }]}
        value={currentMonth}
        onChangeText={handleMonthInputChange}
        keyboardType="numeric"
        maxLength={2}
      />
      <Text style={styles.separator}>/</Text>
      <TextInput
        style={[styles.input, { width: 30 }]}
        value={currentYear}
        onChangeText={handleYearInputChange}
        keyboardType="numeric"
        maxLength={4}
      />

      <TouchableOpacity onPress={() => setModalVisible(true)}>
        <Icon name="calendar" size={15} color={colors.aqua} style={styles.icon} />
      </TouchableOpacity>

      {modalVisible && (
        <Modal transparent={true} animationType="slide" visible={modalVisible}>
          <View style={styles.modalBackground}>
            <View style={styles.modalContainer}>
              <View style={styles.headerContainer}>
                <TouchableOpacity onPress={() => setYearListVisible(true)} style={styles.navButton}>
                  <Text style={styles.navButtonText}>{currentYear}</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => handleMonthChange(-1)} style={styles.navButton}>
                  <Text style={styles.navButtonText}>{"<"}</Text>
                </TouchableOpacity>
                <Text style={styles.modalTitle}>
                  {new Date(parseInt(currentYear), parseInt(currentMonth) - 1).toLocaleString("default", { month: "long" })}{" "}
                  {currentYear}
                </Text>
                <TouchableOpacity onPress={() => handleMonthChange(1)} style={styles.navButton}>
                  <Text style={styles.navButtonText}>{">"}</Text>
                </TouchableOpacity>
              </View>

              {yearListVisible ? renderYearList() : <View style={styles.calendarGrid}>{renderDays()}</View>}
            </View>
          </View>
        </Modal>
      )}
    </View>
  );
};

const screenWidth = Dimensions.get("window").width;
const modalWidth = Platform.OS === "web" && screenWidth > 760 ? "30%" : "80%"; // Adjust width for web and mobile

const styles = StyleSheet.create({
  dateButton: {
    flexDirection: "row",
    alignItems: "center",
  },
  input: {
    textAlign: "center",
    fontSize: 12,
    color: "white",
  },
  separator: {
    fontSize: 16,
    marginHorizontal: 5,
    color: "white",
  },
  icon: {
    marginLeft: 10,
  },
  modalBackground: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContainer: {
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 10,
    width: modalWidth, // Responsive width based on screen size
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  navButton: {
    padding: 10,
  },
  navButtonText: {
    fontSize: 18,
    color: "black",
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: "bold",
  },
  calendarGrid: {
    flexWrap: "wrap",
    flexDirection: "row",
  },
  dayCell: {
    width: "14%",
    height: 40,
    justifyContent: "center",
    alignItems: "center",
  },
  dayName: {
    fontSize: 14,
    fontWeight: "bold",
    color: colors.gray,
  },
  selectedDay: {
    backgroundColor: colors.aqua,
    borderRadius: 5,
  },
  today: {
    backgroundColor: colors.orange,
    borderRadius: 5,
  },
  dayText: {
    fontSize: 16,
    color: "black",
  },
  selectedText: {
    color: "white",
  },
  yearList: {
    maxHeight: 200,
  },
  yearItem: {
    paddingVertical: 10,
    alignItems: "center",
  },
  yearText: {
    fontSize: 16,
  },
});


export default CustomDatePicker;

