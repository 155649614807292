import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Pressable,
  Modal,
  StyleSheet,
  TextInput,
  Platform,
} from "react-native";
import { ErrorModal } from "./AreYouSure";
import { colors } from "./colour";
import UploadImage from "./UploadImage";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AutoExitModalTrophy } from "./AutoExitModal";
import { CustomDateField, CustomDateFieldForMob } from "./textFeild";

const PlayerDetailsModal = ({
  isVisible,
  onClose,
  imageSelection,
  onSubmit,
}) => {
  const [playersList, setPlayersList] = useState([
    {
      Name: "",
      DOB: "",
      JerseyNo: "",
      MobileNo: "",
      PhotoURL: "",
    },
  ]);

  const [selectedImageURI, setSelectedImageURI] = useState(null);
  const [isErrorModalVisible, setErrorModalVisible] = useState(false); // State for ErrorModal
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputPlayerChange = (field, event, idx) => {
    const updatedPlayerList = [...playersList];
    updatedPlayerList[idx][field] = event;
    setPlayersList(updatedPlayerList);
  };

  const handleImageSelection = (uri, index) => {
    const updatedPlayersList = [...playersList];
    updatedPlayersList[index].PhotoURL = uri;
    setPlayersList(updatedPlayersList);
    imageSelection(updatedPlayersList);
  };

  const handleSubmitPlayerDetails = () => {
    const hasInvalidPlayer = playersList.some((player) => {
      return (
        player.Name.trim() === "" ||
        player.DOB.trim() === "" ||
        player.JerseyNo.trim() === "" ||
        player.MobileNo.trim() === "" ||
        player.PhotoURL.trim() === ""
      );
    });

    if (hasInvalidPlayer) {
      setErrorMessage("All fields must be filled for each player.");
      setErrorModalVisible(true);
      return;
    }

    // If all players are valid, proceed with submission
    onSubmit(playersList);

    // Reset the modal state
    setPlayersList([
      {
        Name: "",
        DOB: "",
        JerseyNo: "",
        MobileNo: "",
        PhotoURL: "",
      },
    ]);
    onClose();
  };

  return (
    <View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={isVisible}
        onRequestClose={() => {
          onclose;
        }}
      >
        <View style={[styles.centeredView, { backgroundColor: "#04040491" }]}>
          {playersList && playersList.length > 0 ? (
            playersList.map((data, index) => (
              <View style={styles.modalView}>
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: -70,
                    left: 0,
                    right: 0,
                  }}
                >
                  <UploadImage
                    style={{
                      width: 120,
                      height: 120,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onImageSelect={(uri) => handleImageSelection(uri, index)}
                    kindOfFile="profilePic"
                    index={index}

                    // value={}
                  />
                </View>

                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: 10,
                    left: 20,
                  }}
                ></View>
                <View style={{ flexDirection: "column" }}>
                  <View style={{ flexDirection: "column", gap: 10 }}>
                    <View>
                      <TextInput
                        style={styles.TeamModalInput}
                        placeholder={"Enter Player Name"}
                        value={data.Name}
                        onChangeText={(text) =>
                          handleInputPlayerChange("Name", text, index)
                        }
                        placeholderTextColor={colors.darkGray}
                      />
                      {data.Name == "" ? (
                        <Text style={{ color: "red" }}>required</Text>
                      ) : null}
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: 10,
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        {Platform.OS === "android" || Platform.OS === "ios" ? (
                          <CustomDateFieldForMob
                            containerStyle={undefined}
                            placeholder={"Select Date"}
                            isRequired={true}
                            value={data.DOB}
                            onChangeText={(date) => {
                              const newList = [...playersList];
                              newList[index].DOB = date;
                              setPlayersList(newList);
                            }}
                            // onChangeText={(e) => {
                            //   const newList = [...playersList];
                            //   newList[index].DOB = e.target.value;
                            //   // console.log("date pick:", e.target.value, data.DOB);
                            //   console.log("e", e);
                            //   setPlayersList(newList);
                            // }}
                            label={undefined}
                            onBlur={undefined}
                            id={undefined}
                          />
                        ) : (
                          <CustomDateField
                            containerStyle={undefined}
                            placeholder={"Select Date"}
                            isRequired={true}
                            value={data.DOB}
                            onChangeText={(e) => {
                              const newList = [...playersList];
                              newList[index].DOB = e.target.value;
                              setPlayersList(newList);
                            }}
                            label={undefined}
                            onBlur={undefined}
                            id={undefined}
                          />
                        )}

                        {data.DOB == "" ? (
                          <Text style={{ color: "red" }}>required</Text>
                        ) : null}
                      </View>
                      <View style={{ width: "25%" }}>
                        <TextInput
                          style={[styles.TeamModalInput]}
                          placeholder={"Jersey no"}
                          inputMode="numeric"
                          value={data.JerseyNo}
                          onChangeText={(text) =>
                            handleInputPlayerChange("JerseyNo", text, index)
                          }
                          placeholderTextColor={colors.darkGray}
                        />

                        {data.JerseyNo == "" || null ? (
                          <Text style={{ color: "red" }}>required</Text>
                        ) : null}
                      </View>
                    </View>

                    <TextInput
                      style={styles.TeamModalInput}
                      placeholder={"Enter mobile no"}
                      placeholderTextColor={colors.darkGray}
                      inputMode="numeric"
                      maxLength={10}
                      value={data.MobileNo}
                      onChangeText={(text) =>
                        handleInputPlayerChange("MobileNo", text, index)
                      }
                    />
                    {(data.MobileNo && data.MobileNo.length < 10) ||
                    data.MobileNo.length > 10 ? (
                      <Text style={{ color: "red" }}>Invalid Number</Text>
                    ) : null}
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: 20,
                      marginTop: 15,
                    }}
                  >
                    <Pressable
                      onPress={onClose}
                      style={[
                        styles.btnPressable,
                        {
                          borderWidth: 1.5,
                          borderColor: colors.white,
                          width: 120,
                        },
                      ]}
                    >
                      <Text style={styles.btnText}>Cancel</Text>
                    </Pressable>
                    <Pressable
                      onPress={() => handleSubmitPlayerDetails()}
                      style={[
                        styles.btnPressable,
                        {
                          backgroundColor: colors.aqua,
                          borderWidth: 1.5,
                          borderColor: colors.aqua,
                          width: 120,
                        },
                      ]}
                    >
                      <Text style={styles.btnText1}>Submit</Text>
                    </Pressable>
                  </View>
                </View>
              </View>
            ))
          ) : (
            <Text style={{ color: "white" }}>No any players added yet !</Text>
          )}
        </View>
      </Modal>
      <ErrorModal
        visibleModal={isErrorModalVisible}
        header={"Validation Error"}
        SuccessMessage={errorMessage}
        onSubmit={() => setErrorModalVisible(false)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  //popup
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    padding: 15,
    width: 550,
    maxWidth: "90%",
    margin: 20,
    backgroundColor: colors.royalBlue,
    borderRadius: 7,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: "relative",
    paddingTop: 60,
  },

  btnText: {
    textAlign: "center",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
    color: colors.white,
  },
  btnText1: {
    textAlign: "center",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
    color: colors.royalBlue,
  },

  btnPressable: {
    width: 150,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },

  TeamModalInput: {
    borderColor: "#d3d3d3",
    borderWidth: 1,
    height: 32,
    width: "100%",
    borderRadius: 5,
    paddingHorizontal: 5,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
  },
});
export default PlayerDetailsModal;
