import React, { useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Platform,
  Switch,
  Pressable,
  Image,
  // Picker,
  TextInput,
} from "react-native";
import * as ImagePicker from "expo-image-picker";
import Layout from "../../../../components/include/Layout";
import { H2 } from "../../../../components/reusable/TextComponent";
import { colors } from "../../../../components/reusable/colour";
import { RadioButton } from "react-native-paper";
import TextField, {
  CustomDateFieldForMob,
} from "../../../../components/reusable/textFeild";
// import CustomDropdown from "../../../../components/reusable/CustomDropdown";
import { DataStore } from "aws-amplify/datastore";
import { uploadData } from "aws-amplify/storage";
import awsmobile from "../../../../aws-exports";
import UploadIcon from "react-native-vector-icons/SimpleLineIcons";
import {
  RoundMaster,
  Tournament,
  UserMaster,
  UserMasterTournament,
} from "../../../../models";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { RoundFormat } from "../../../../models";
import { getAuthenticatedUser } from "../../../../api/AmplifyUserAPI";
import { useNavigation } from "@react-navigation/native";
import { Picker } from "@react-native-picker/picker";
import Venue from "../../Home/Venue";
import { AutoExitModalTrophy } from "../../../../components/reusable/AutoExitModal";
import { Formik } from "formik";
import * as Yup from "yup";
import { ErrorModal } from "../../../../components/reusable/AreYouSure";
import { Substitution } from "../../../../models";
import AppBar from "../../../../components/include/AppBar";
import { ScrollView } from "react-native-gesture-handler";
import CustomDatePicker from "../../../../components/reusable/CustomeDatePicker";

const screenWidth = Dimensions.get("window").width;

const CreateTournament = () => {
  const [isEnabled, setIsEnabled] = useState(true);
  const bucketName = awsmobile.aws_user_files_s3_bucket;
  const toggleSwitch = () => setIsEnabled((previousState) => !previousState);
  const [selectedImage, setSelectedImage]: any = useState("");
  const [banerselectedImage, setbanerselectedImage]: any = useState("");
  const [selectedImageName, setSelectedImageName]: any = useState();
  const [banerselectedImageName, setbanerselectedImageName]: any = useState();
  // const [selectedImage, setSelectedImage]: any = useState();
  // const [banerselectedImage, setbanerselectedImage]: any = useState();
  const [selectedLogoSize, setSelectedLogoSize]: any = useState();
  const [banerselectedSize, setbanerselectedSize]: any = useState();
  const [hoverIn, setHoverIn] = useState(false);
  const [hoverIn2, setHoverIn2] = useState(false);
  const navigation: any = useNavigation();
  const [lastURL, setLastURL] = useState("");
  const [UserDetails, setUserDetails]: any = useState({});
  const [selectedValue, setSelectedValue] = useState("");
  const [checked, setChecked] = React.useState("first");
  const [Locationvar, setLocationvar]: any = useState({});
  const [storeArea, setStoreArea] = useState("");
  const [venueobj, setvenueobj]: any = useState({});
  const [SuccessModalVisible, setSuccessModalVisible] = useState(false);
  const [SunccessModalMesg, setSunccessModalMesg] = useState("");
  const [logoErrorModalVisible, setLogoErrorModalVisible] = useState(false);
  const [LogoErrorModalMesg, setLogoErrorModalMesg] = useState("");
  const [banerErrorModalVisible, setBanerErrorModalVisible] = useState(false);
  const [BanerErrorModalMesg, setBanerErrorModalMesg] = useState("");
  const tournamentNameRef = useRef(null);
  const organizerNameRef = useRef(null);
  const organizerEmailRef = useRef(null);
  const playingRef = useRef(null);
  const subsituteRef = useRef(null);
  const MaxPlayersInSquadRef = useRef(null);
  const NoOfTeamsparticipateRef = useRef(null);
  const [SuccessModalMesg, setSuccessModalMesg] = useState("");
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalMesg, setErrorModalMesg] = useState("");

  const [formInfo, setFormInfo] = useState({
    tournamentName: "",
    organizerName: "",
    organizerEmail: "",
    tournamentStartDate: "",
    tournamentEndDate: "",
    playing: "",
    subsitute: "",
    NoOfTeamsparticipate: "",
    MaxPlayersInSquad: "",
  });

  const [venueObj, setVenueObj] = useState([
    {
      Name: "",
      Address: "",
      Coordinates: "",
    },
  ]);
  const [inputText, setInputText] = useState({
    tournamentName: "",
    organizerName: "",
    organizerEmail: "",
    tournamentStartDate: "",
    tournamentEndDate: "",
    playing: "",
    subsitute: "",
    NoOfTeamsparticipate: "",
    MaxPlayersInSquad: "",
    level: "",
  });
  const [roundList, setRoundList] = useState([]);

  const handleHoverIn = () => {
    setHoverIn(true);
  };
  const handleHoverOut = () => {
    setHoverIn(false);
  };
  const handleHoverIn2 = () => {
    setHoverIn2(true);
  };
  const handleHoverOut2 = () => {
    setHoverIn2(false);
  };
  const pickImage = async (value) => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    console.log(result);

    if (!result.canceled) {
      if (value == "logo") {
        setSelectedImage(result.assets[0].uri);
      } else {
        setbanerselectedImage(result.assets[0].uri);
      }
    }
  };
  const handleRoundType = (value) => {
    console.log("roundtype", value);
    setSelectedValue(value);
    let arrseprate = value.split("+");
    console.log("arrseprate", arrseprate);
    let Roundlist = [];
    arrseprate.map((ele, index) => {
      Roundlist.push({
        No: index + 1,
        Name: ele,
        Format:
          ele == "League"
            ? RoundFormat.SINGLE_ROUND_ROBIN
            : ele == "Double Round Robin"
            ? RoundFormat.DOUBLE_ROUND_ROBIN
            : RoundFormat.KNOCKOUT,
      });
    });
    console.log("Roundlist", Roundlist);
    setRoundList(Roundlist);
  };

  // const inputEvent = (field, value) => {
  //   console.log("hellotext", field, value);
  //   // let values = event;
  //   // if (field == "firstPanNumber" && values == "") {
  //   //   setInputText({ ...inputText, blockType: "Individual" });
  //   // } else {

  //   switch (field) {
  //     case "tournamentName":
  //       console.log("tournamentName", value);
  //       setInputText((lastValue) => {
  //         return {
  //           ...lastValue,
  //           [field]: value,
  //         };
  //       });
  //       break;
  //   }

  //   // setInputText((lastValue) => {
  //   //   return {
  //   //     ...lastValue,
  //   //     [field]: values,
  //   //   };
  //   // });
  //   // }
  // };

  const inputEvent = (field, value) => {
    console.log("inputEvent", field, value);
    setInputText((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const pickImageAsync = async (value) => {
    if (Platform.OS === "web") {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.onchange = (event) => {
        const target = event.target as HTMLInputElement;
        const file = target.files && target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const uri = e.target.result as string;
            const fileName = file.name.replaceAll(" ", "_");
            if (value == "Logo") {
              setSelectedImage({ URL: uri, FileName: fileName });
              setSelectedLogoSize(file.size);
            }
            if (value == "Banner") {
              setbanerselectedImage({ URL: uri, FileName: fileName });
              setbanerselectedSize(file.size);
            }
          };
          reader.readAsDataURL(file);
        } else {
          console.log("No image uploaded");
        }
      };
      input.click();
    } else {
      const result = await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (result.granted === false) {
        setErrorModalVisible(true);
        setErrorModalMesg("Permission to access gallery is required!");
        return;
      }

      const pickerResult = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 1,
      });

      if (!pickerResult.canceled) {
        const uri = pickerResult.assets[0].uri;
        const fileName = uri.split("/").pop();

        if (value == "Logo") {
          setSelectedImage({ URL: uri, FileName: fileName });
        }
        if (value == "Banner") {
          setbanerselectedImage({ URL: uri, FileName: fileName });
        }
      } else {
        setErrorModalVisible(true);
        setErrorModalMesg("You did not select any image.");
      }
    }
  };

  // const uploadImage = async (event, name) => {
  //   console.log("in uploadeImage");
  //   if (event?.target?.files) {
  //     const file = event.target.files[0];
  //     if (name == "Logo") {
  //       // console.log("getURLfromS3", getURLfromS3);
  //       setSelectedImage(file);
  //       console.log("logo name:", file.Name);
  //     }
  //     if (name == "Banner") {
  //       setbanerselectedImage(file);
  //       console.log("baner name:", file.Name);
  //     }
  //     console.log("filename", file.name);
  //     uploadData({
  //       key: file.name,
  //       data: file,
  //     });
  //   }
  // };
  const BanerImageViewer = ({ placeholderImageSource, banerselectedImage }) => {
    const imageSource = banerselectedImage
      ? { uri: banerselectedImage.URL }
      : placeholderImageSource;

    return <Image source={imageSource} style={styles.banerImage} />;
  };

  const getUserdata = async () => {
    let data = await AsyncStorage.getItem("CurrentURL");
    setLastURL(data);
    console.log("FinalUrl", data);
    let userid = await AsyncStorage.getItem("UserDetail");
    console.log("isSignintrue", userid);
    // let Response = await getAuthenticatedUser();
    if (userid) {
      console.log("CreatePageResp", Response);

      const id = userid;
      console.log("helloid", id);
      let finaldata = await DataStore.query(UserMaster, id);
      console.log("finalUserdata", finaldata);
      setUserDetails(finaldata);
    } else {
      let userDetails = await AsyncStorage.getItem("UserDetail");
      const UserObject = await DataStore.query(UserMaster, userDetails);
      console.log("UserObject", UserObject);
      setUserDetails(UserObject);
    }
  };

  const validationSchema = Yup.object().shape({
    tournamentName: Yup.string()
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        "Tournament Name must not contain special characters"
      )
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Tournament name required"),

    organizerName: Yup.string()
      .matches(
        /^[a-zA-Z ]*$/,
        "OrganizerName  must not contain special characters"
      )
      .min(2, "Too Short!")
      .max(50, "OrganizerName must contain letters")
      .required("OrganizerName required"),
    organizerEmail: Yup.string()
      .email("Invalid email address")
      .required("Organizer email required"),
    tournamentStartDate: Yup.date().required("Start Date is required"),
    tournamentEndDate: Yup.date().required("EndDate is required"),
    playing: Yup.number().required("Playing is required"),
    subsitute: Yup.number().required("Subsitute is required"),
    NoOfTeamsparticipate: Yup.number().required("Max No Of Teams is required"),
    MaxPlayersInSquad: Yup.number().required(
      "Max Players In Squad is required"
    ),
  });

  useEffect(() => {
    let dropdown = RoundFormat;
    console.log("dropdown", dropdown);
    getUserdata();
  }, []);

  const uploadLogoFileToStorage = async (folderName, key) => {
    // const contentType = key.split(";")[0].split(":")[1];
    console.log(
      "folder name",
      folderName,
      "FileName",
      key.FileName,
      "key:",
      key
    );

    try {
      const response = await fetch(key.URL);
      const blob = await response.blob();
      let Response;
      await uploadData({
        key: `${folderName}/${key.FileName}`,
        data: blob,
      });
      Response = `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/${folderName}/${key.FileName}`;
      return Response;
    } catch (err) {
      console.log("Error uploading file:", err);
    }
  };

  const handleSubmitLogo = () => {
    setLogoErrorModalVisible(false);
  };

  const handleSubmitBaner = () => {
    setBanerErrorModalVisible(false);
  };

  const handleFocus = (
    formRefs: Record<string, React.RefObject<TextInput>>,
    values: Record<string, string>
  ) => {
    const fields = [
      "tournamentName",
      "organizerName",
      "organizerEmail",
      "playing",
      "subsitute",
      "MaxPlayersInSquad",
      "NoOfTeamsparticipate",
    ];

    for (let field of fields) {
      console.log("Empty field:", field, "Value:", values[field]);
      if (values[field] === "") {
        const ref = formRefs[field + "Ref"];
        if (ref && ref.current) {
          const currentRef = ref.current;
          if (currentRef.focus) {
            currentRef.focus();
          }
          return;
        }
      }
    }
  };

  // const handleSubmit = async () => {
  //   let logo = "";
  //   let banner = "";
  //   console.log("handleSubmit", inputText, isEnabled, roundList);

  //   let imageError = false;
  //   if (selectedLogoSize > 524288) {
  //     setErrorModalMesg(
  //       "Oops! You can't upload Logo of size more than 500 kb!"
  //     );
  //     setErrorModalVisible(true);
  //     imageError = true;
  //   }
  //   if (banerselectedSize > 1048576) {
  //     setErrorModalMesg(
  //       "Oops! You can't upload Banner of size more than 1 Mb!"
  //     );
  //     setErrorModalVisible(true);
  //     imageError = true;
  //   }

  //   if (!imageError) {
  //     if (selectedImage !== "") {
  //       logo = await uploadLogoFileToStorage(
  //         `tournament/${inputText.tournamentName.replace(/ /g, "_")}`,
  //         // `tournament/${selectedImageName}`,
  //         selectedImage
  //       );
  //       console.log("logo", logo);
  //       // setLogoURl(logo);
  //     } else {
  //       console.log("logo not upload");
  //       setLogoErrorModalVisible(true);
  //       setLogoErrorModalMesg("A Logo is required.");
  //     }
  //     if (banerselectedImage !== "") {
  //       banner = await uploadLogoFileToStorage(
  //         `tournament/${inputText.tournamentName.replace(/ /g, "_")}`,
  //         // `tournament/${banerselectedImageName}`,
  //         banerselectedImage
  //       );
  //       console.log("banner", banner);
  //       // setBannerURL(banner);
  //     } else {
  //       console.log("banner not upload");
  //       setBanerErrorModalVisible(true);
  //       setBanerErrorModalMesg("A banner is required.");
  //     }

  //     // };
  //     console.log("helloSubmit", UserDetails);
  //     console.log("SelectedImage", selectedImage);
  //     let dropdown = RoundFormat;
  //     console.log("dropdown", dropdown);
  //     let PostObj: any = {
  //       Name: inputText.tournamentName.trim(),
  //       Description: "",
  //       Organizer: inputText.organizerName.trim(),
  //       OrganizerEmail: inputText.organizerEmail.trim(),
  //       Level: inputText.level,
  //       Logo: logo,
  //       Banner: banner,
  //       StartDate: new Date(inputText.tournamentStartDate)
  //         .toISOString()
  //         .split("T")[0],
  //       // inputText.tournamentStartDate.toISOString(),
  //       EndDate: new Date(inputText.tournamentEndDate)
  //         .toISOString()
  //         .split("T")[0],
  //       // inputText.tournamentEndDate,
  //       Fees: "0",
  //       Country: Locationvar.Country,
  //       State: Locationvar.State,
  //       City: Locationvar.City,
  //       SubstitutionType: Substitution.REGULAR,
  //       Admin1: {
  //         Name: UserDetails.Name.trim(),
  //         PhotoURL: UserDetails.PhotoURL,
  //         UUID: UserDetails.UUID,
  //       },
  //       ExternalRegistration: checked == "first" ? true : false,
  //       // Format: RegistrationForm.roundFormateName,
  //       NoOfTeamsMin: 2,
  //       NoOfTeamsMax: Number(inputText.NoOfTeamsparticipate),
  //       MaxPlayersInSquad: Number(inputText.MaxPlayersInSquad),
  //       Playing: Number(inputText.playing),
  //       Substitute: Number(inputText.subsitute),
  //       Status: "Created",
  //       WinPoints: 3,
  //       LossPoints: 0,
  //       DrawPoints: 1,
  //       Area: storeArea,
  //       RulesDocument: `https://${bucketName}.s3.ap-south-1.amazonaws.com/image.jpeg`,
  //       CreatedBy: UserDetails.Name,
  //       LastUpdatedBy: UserDetails.Name,
  //       Venues: [],
  //       Prizes: [],
  //       Sponsors: [],
  //       Gallery: [],
  //       Sport: "Football",
  //       Type: "",
  //       AgeGroup: "",
  //       Views: 0,
  //     };
  //     console.log("CreateTournamentPostObj", PostObj);
  //     try {
  //       const Response = await DataStore.save(new Tournament(PostObj));
  //       console.log("responseData", Response);
  //       if (Response && Object.keys(Response).length > 0) {
  //         // if (data[0].text !== "") {
  //         let RoundTable = [];
  //         roundList.map((ele) => {
  //           RoundTable.push({
  //             No: ele.No,
  //             Name: ele.Name.trim(),
  //             Format: ele.Format,
  //             Completed: false,
  //             TeamsSelected: false,
  //             FixturesCreated: false,
  //             CreatedBy: UserDetails.Name,
  //             LastUpdatedBy: UserDetails.Name,
  //             Tournament: Response.id,
  //           });
  //         });
  //         console.log("RoundTable", RoundTable);
  //         RoundTable.forEach(async (obj) => {
  //           try {
  //             const Response2 = await DataStore.save(new RoundMaster(obj));
  //             console.log("responseData", Response2);
  //           } catch (error) {
  //             console.log("error", error);
  //           }
  //         });
  //         // }
  //         try {
  //           const Commontable = await DataStore.save(
  //             new UserMasterTournament({
  //               userMasterUUID: UserDetails.UUID,
  //               tournamentId: Response.id,
  //             })
  //           );
  //           console.log("Commontable", Commontable);
  //           if (Commontable) {
  //             const TournamentData = await DataStore.query(
  //               Tournament,
  //               Response.id
  //             );
  //             if (TournamentData) {
  //               console.log("TournamentData", TournamentData);
  //               await AsyncStorage.setItem(
  //                 "CurrentTournament",
  //                 TournamentData.id
  //               );
  //               console.log("TournamentData", TournamentData);

  //               //           setSuccessModalVisible(true);
  //               //           setSunccessModalMesg("Tournament Created SuccesFully");
  //               setSuccessModalVisible(true);
  //               setSuccessModalMesg("Tournament Created SuccesFully");

  //               //           // alert("Tournament Created SuccesFully");
  //               //           navigation.navigate("Dashboard");
  //               //         }
  //               //       }
  //               //     } catch (error) {
  //               //       console.log("error", error);
  //               //     }
  //               //   }
  //               // } catch (error) {
  //               //   console.log("error1", error);
  //               // }
  //               // alert("Tournament Created SuccesFully");
  //               navigation.navigate("Dashboard");
  //             }
  //           }
  //         } catch (error) {
  //           console.log("error", error);
  //         }
  //       }
  //     } catch (error) {
  //       console.log("error1", error);
  //     }
  //   }
  // };

  const handleSubmit = async () => {
    let logo = "";
    let banner = "";
    console.log("handleSubmit", inputText, isEnabled, roundList);

    let imageError = false;
    if (selectedLogoSize > 524288) {
      setErrorModalMesg(
        "Oops! You can't upload Logo of size more than 500 kb!"
      );
      setErrorModalVisible(true);
      imageError = true;
    }
    if (banerselectedSize > 1048576) {
      setErrorModalMesg(
        "Oops! You can't upload Banner of size more than 1 Mb!"
      );
      setErrorModalVisible(true);
      imageError = true;
    }

    if (!imageError) {
      if (selectedImage !== "") {
        logo = await uploadLogoFileToStorage(
          `tournament/${inputText.tournamentName.replace(/ /g, "_")}`,
          selectedImage
        );
        console.log("logo", logo);
      } else {
        console.log("logo not upload");
        setLogoErrorModalVisible(true);
        setLogoErrorModalMesg("A Logo is required.");
      }
      if (banerselectedImage !== "") {
        banner = await uploadLogoFileToStorage(
          `tournament/${inputText.tournamentName.replace(/ /g, "_")}`,
          banerselectedImage
        );
        console.log("banner", banner);
      } else {
        console.log("banner not upload");
        setBanerErrorModalVisible(true);
        setBanerErrorModalMesg("A banner is required.");
      }

      console.log("helloSubmit", UserDetails);
      console.log("SelectedImage", selectedImage);
      let dropdown = RoundFormat;
      console.log("dropdown", dropdown);
      const startDate = new Date(inputText.tournamentStartDate);
      const endDate = new Date(inputText.tournamentEndDate);
      let PostObj: any = {
        Name: inputText.tournamentName.trim(),
        Description: "",
        Organizer: inputText.organizerName.trim(),
        OrganizerEmail: inputText.organizerEmail.trim(),
        Level: inputText.level,
        Logo: logo,
        Banner: banner,
        // Format dates to YYYY-MM-DD strings in local timezone
        // Format dates to YYYY-MM-DD strings in local timezone
        StartDate: `${startDate.getFullYear()}-${String(
          startDate.getMonth() + 1
        ).padStart(2, "0")}-${String(startDate.getDate()).padStart(2, "0")}`,

        EndDate: `${endDate.getFullYear()}-${String(
          endDate.getMonth() + 1
        ).padStart(2, "0")}-${String(endDate.getDate()).padStart(2, "0")}`,

        Fees: "0",
        Country: Locationvar.Country,
        State: Locationvar.State,
        City: Locationvar.City,
        SubstitutionType: Substitution.REGULAR,
        Admin1: {
          Name: UserDetails.Name.trim(),
          PhotoURL: UserDetails.PhotoURL,
          UUID: UserDetails.UUID,
        },
        ExternalRegistration: checked == "first" ? true : false,
        NoOfTeamsMin: 2,
        NoOfTeamsMax: Number(inputText.NoOfTeamsparticipate),
        MaxPlayersInSquad: Number(inputText.MaxPlayersInSquad),
        Playing: Number(inputText.playing),
        Substitute: Number(inputText.subsitute),
        Status: "Created",
        WinPoints: 3,
        LossPoints: 0,
        DrawPoints: 1,
        Area: storeArea,
        RulesDocument: `https://${bucketName}.s3.ap-south-1.amazonaws.com/image.jpeg`,
        CreatedBy: UserDetails.Name,
        LastUpdatedBy: UserDetails.Name,
        Venues: [],
        Prizes: [],
        Sponsors: [],
        Gallery: [],
        Sport: "Football",
        Type: "",
        AgeGroup: "",
        Views: 0,
      };

      console.log("CreateTournamentPostObj", PostObj);

      try {
        const Response = await DataStore.save(new Tournament(PostObj));
        console.log("responseData", Response);
        if (Response && Object.keys(Response).length > 0) {
          let RoundTable = [];
          roundList.map((ele) => {
            RoundTable.push({
              No: ele.No,
              Name: ele.Name.trim(),
              Format: ele.Format,
              Completed: false,
              TeamsSelected: false,
              FixturesCreated: false,
              CreatedBy: UserDetails.Name,
              LastUpdatedBy: UserDetails.Name,
              Tournament: Response.id,
            });
          });
          console.log("RoundTable", RoundTable);

          RoundTable.forEach(async (obj) => {
            try {
              const Response2 = await DataStore.save(new RoundMaster(obj));
              console.log("responseData", Response2);
            } catch (error) {
              console.log("error", error);
            }
          });

          try {
            const Commontable = await DataStore.save(
              new UserMasterTournament({
                userMasterUUID: UserDetails.UUID,
                tournamentId: Response.id,
              })
            );
            console.log("Commontable", Commontable);
            if (Commontable) {
              const TournamentData = await DataStore.query(
                Tournament,
                Response.id
              );
              if (TournamentData) {
                console.log("TournamentData", TournamentData);
                await AsyncStorage.setItem(
                  "CurrentTournament",
                  TournamentData.id
                );
                console.log("TournamentData", TournamentData);

                setSuccessModalVisible(true);
                setSuccessModalMesg("Tournament Created SuccesFully");

                navigation.navigate("Dashboard");
              }
            }
          } catch (error) {
            console.log("error", error);
          }
        }
      } catch (error) {
        console.log("error1", error);
      }
    }
  };

  const handlegetVenuedata = (data: any, area: any) => {
    console.log("handlegetVenuedata", data, area);
    setvenueobj(data[0]);

    setStoreArea(area);
    let address = data[0].Address;
    let finalAddress = address.split(",").slice(-3).join(",").trim();
    console.log("finalAddress", finalAddress.split(",")[0]);
    let obj = {
      Country: finalAddress.split(",")[2],
      State: finalAddress.split(",")[1],
      City: finalAddress.split(",")[0],
    };
    console.log("Location", obj);
    setLocationvar(obj);
  };

  const handleBack = () => {
    navigation.goBack();
  };

  return (
    <>
      {Platform.OS !== "web" && (
        <AppBar
          title={"Create Tournament"}
          showBackButton={true}
          onBackPress={handleBack}
        />
      )}
      <Layout>
        <ScrollView>
          <View
            style={[
              Platform.OS == "web" && screenWidth > 760
                ? styles.formBgContainer
                : null,
            ]}
          >
            <View style={styles.formContainerCard}>
              {Platform.OS === "web" && (
                <H2 props="Create Tournament" style={styles.heading} />
              )}

              <Formik
                initialValues={{
                  // inputText: {
                  tournamentName: inputText.tournamentName,
                  organizerName: inputText.organizerName,
                  organizerEmail: inputText.organizerEmail,
                  tournamentStartDate: inputText.tournamentStartDate,
                  tournamentEndDate: inputText.tournamentEndDate,
                  playing: inputText.playing,
                  subsitute: inputText.subsitute,
                  NoOfTeamsparticipate: inputText.NoOfTeamsparticipate,
                  MaxPlayersInSquad: inputText.MaxPlayersInSquad,
                  // },
                  selectedValue: "",
                  checked: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  setFormInfo(values);
                  handleFocus(
                    {
                      tournamentNameRef,
                      organizerNameRef,
                      organizerEmailRef,
                      playingRef,
                      subsituteRef,
                      MaxPlayersInSquadRef,
                      NoOfTeamsparticipateRef,
                    },
                    values
                  );
                  handleSubmit();
                }}
                enableReinitialize={true}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  isSubmitting,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <View style={styles.formWrapper}>
                    {Platform.OS != "web" && (
                      <View
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          padding: 15,
                        }}
                      >
                        <Pressable
                          onPress={() => pickImageAsync("Logo")}
                          style={{ width: 120 }}
                        >
                          <View style={styles.circleContainer}>
                            <View style={styles.circle}>
                              {selectedImage ? (
                                <Image
                                  source={{ uri: selectedImage.URL }}
                                  style={{
                                    width: 100,
                                    height: 100,
                                    borderRadius: 60,
                                  }}
                                />
                              ) : (
                                <View style={styles.circle}>
                                  <Image
                                    source={require("../../../../../assets/icons/Camera.svg")}
                                    style={styles.cameraIcon}
                                  />
                                  <Text style={{ color: colors.darkGray }}>
                                    Upload Logo
                                  </Text>
                                </View>
                              )}
                            </View>
                          </View>
                        </Pressable>
                      </View>
                    )}
                    <View
                      style={[
                        styles.textField,
                        { maxWidth: "100%", minWidth: "100%" },
                      ]}
                    >
                      <Text style={styles.label}>
                        Tournament Name
                        <Text style={styles.requiredAsterisk}>*</Text>
                      </Text>
                      <TextInput
                        style={styles.input}
                        placeholder={"Enter Tournament Name"}
                        placeholderTextColor={colors.darkGray}
                        value={values.tournamentName}
                        keyboardType={undefined}
                        onChangeText={(text) =>
                          inputEvent("tournamentName", text)
                        }
                        onBlur={handleBlur("tournamentName")}
                        onChange={handleChange}
                        id="tournamentName"
                        ref={tournamentNameRef}
                      />
                      {touched.tournamentName && errors.tournamentName && (
                        <View>
                          <Text style={{ color: "red" }}>
                            {errors.tournamentName}
                          </Text>
                        </View>
                      )}
                    </View>

                    <View style={styles.textField}>
                      <Text style={styles.label}>
                        Organizer's Name
                        <Text style={styles.requiredAsterisk}>*</Text>
                      </Text>

                      <TextInput
                        placeholder={"Enter Organizer's Name"}
                        value={values.organizerName}
                        placeholderTextColor={colors.darkGray}
                        onChangeText={(text) =>
                          inputEvent("organizerName", text)
                        }
                        keyboardType={undefined}
                        style={styles.input}
                        onBlur={handleBlur("organizerName")}
                        id={"organizerName"}
                        onChange={handleChange}
                        ref={organizerNameRef}
                      />
                      {touched.organizerName && errors.organizerName && (
                        <View>
                          <Text style={{ color: "red" }}>
                            {errors.organizerName}
                          </Text>
                        </View>
                      )}
                    </View>

                    <View style={styles.textField}>
                      <Text style={styles.label}>
                        Organizer's Email
                        <Text style={styles.requiredAsterisk}>*</Text>
                      </Text>

                      <TextInput
                        placeholder={"Enter Organizer's Email"}
                        value={values.organizerEmail}
                        placeholderTextColor={colors.darkGray}
                        onChangeText={(text) =>
                          inputEvent("organizerEmail", text)
                        }
                        keyboardType={undefined}
                        style={styles.input}
                        onBlur={handleBlur("organizerEmail")}
                        id={"organizerEmail"}
                        onChange={handleChange}
                        ref={organizerEmailRef}
                      />
                      {touched.organizerEmail && errors.organizerEmail && (
                        <View>
                          <Text style={{ color: "red" }}>
                            {errors.organizerEmail}
                          </Text>
                        </View>
                      )}
                    </View>
                    <View style={styles.textField}>
                      {Platform.OS === "web" ? (
                        <>
                          <Text style={styles.label}>
                            Tournament Start Date
                          </Text>
                          <CustomDatePicker
                            onChange={(e) => {
                              setInputText({
                                ...inputText,
                                tournamentStartDate: e,
                              });

                              console.log("start date get,", e);
                            }}
                            portalId={undefined}
                            selected={values.tournamentStartDate}
                            // CustomDateField
                            // label={"Tournament Start Date"}
                            containerStyle={styles.input}
                            // placeholder={"Select Date"}
                            // isRequired={true}
                            // value={values.tournamentStartDate}
                            // onChangeText={(e) =>
                            //   setInputText({
                            //     ...inputText,
                            //     tournamentStartDate: e.target.value,
                            //   })
                            // }
                            // onBlur={undefined}
                            // id={"tournamentStartDate"} // onChangeText={(text) => inputEvent("tournamentStartDate", text)}
                          />
                        </>
                      ) : (
                        <CustomDateFieldForMob
                          label={"Tournament Start Date"}
                          containerStyle={styles.textField}
                          placeholder={"Select Date"}
                          isRequired={true}
                          value={values.tournamentStartDate}
                          onChangeText={(e: any) =>
                            setInputText({
                              ...inputText,
                              tournamentStartDate: e,
                            })
                          }
                          onBlur={undefined}
                          id={"tournamentStartDate"} // onChangeText={(text) => inputEvent("tournamentStartDate", text)}
                        />
                      )}

                      {touched.tournamentStartDate &&
                        errors.tournamentStartDate && (
                          <View>
                            <Text style={{ color: "red" }}>
                              {errors.tournamentStartDate}
                            </Text>
                          </View>
                        )}
                    </View>
                    <View style={styles.textField}>
                      {Platform.OS === "web" ? (
                        <>
                          <Text style={styles.label}>Tournament End Date</Text>
                          <CustomDatePicker
                            // label={"Tournament End Date"}
                            containerStyle={styles.input}
                            // placeholder={"Select Date"}
                            // isRequired={true}
                            selected={values.tournamentEndDate}
                            onChange={(e) =>
                              setInputText({
                                ...inputText,
                                tournamentEndDate: e,
                              })
                            }
                            portalId={undefined} // onBlur={undefined}
                            // id={"tournamentEndDate"}
                          />
                        </>
                      ) : (
                        <CustomDateFieldForMob
                          label={"Tournament End Date"}
                          containerStyle={styles.textField}
                          placeholder={"Select Date"}
                          isRequired={true}
                          value={values.tournamentEndDate}
                          onChangeText={(e) =>
                            setInputText({
                              ...inputText,
                              tournamentEndDate: e,
                            })
                          }
                          onBlur={undefined}
                          id={"tournamentEndDate"}
                        />
                      )}
                      {touched.tournamentEndDate &&
                        errors.tournamentEndDate && (
                          <View>
                            <Text style={{ color: "red" }}>
                              {errors.tournamentEndDate}
                            </Text>
                          </View>
                        )}
                    </View>

                    <View style={styles.textField}>
                      <Text style={styles.label}>
                        Playing <Text style={styles.requiredAsterisk}>*</Text>
                      </Text>
                      <TextInput
                        placeholder={"No. of players on the field"}
                        value={values.playing}
                        placeholderTextColor={colors.darkGray}
                        onChangeText={(text) => inputEvent("playing", text)}
                        style={styles.input}
                        onBlur={handleBlur("playing")}
                        id={"playing"}
                        inputMode="numeric"
                        onChange={(text) => {
                          handleChange("");
                        }}
                        ref={playingRef}
                      />
                      {touched.playing && errors.playing && (
                        <View>
                          <Text style={{ color: "red" }}>{errors.playing}</Text>
                        </View>
                      )}
                    </View>
                    <View style={styles.textField}>
                      <Text style={styles.label}>
                        Substitutes{" "}
                        <Text style={styles.requiredAsterisk}>*</Text>
                      </Text>
                      <TextInput
                        placeholder={"No. of players on the bench"}
                        value={values.subsitute}
                        placeholderTextColor={colors.darkGray}
                        onChangeText={(text) => inputEvent("subsitute", text)}
                        keyboardType={undefined}
                        style={styles.input}
                        onBlur={handleBlur("subsitute")}
                        id={"subsitute"}
                        onChange={handleChange}
                        ref={subsituteRef}
                      />
                      {touched.subsitute && errors.subsitute && (
                        <View>
                          <Text style={{ color: "red" }}>
                            {errors.subsitute}
                          </Text>
                        </View>
                      )}
                    </View>
                    <View style={styles.textField}>
                      <Text style={styles.label}>
                        Max No. of teams
                        <Text style={styles.requiredAsterisk}>*</Text>
                      </Text>
                      <TextInput
                        placeholder={"Max Number of Teams that can participate"}
                        value={values.NoOfTeamsparticipate}
                        placeholderTextColor={colors.darkGray}
                        onChangeText={(text) =>
                          inputEvent("NoOfTeamsparticipate", text)
                        }
                        keyboardType={undefined}
                        style={styles.input}
                        onBlur={handleBlur("NoOfTeamsparticipate")}
                        id={"NoOfTeamsparticipate"}
                        onChange={handleChange}
                        ref={NoOfTeamsparticipateRef}
                      />
                      {touched.NoOfTeamsparticipate &&
                        errors.NoOfTeamsparticipate && (
                          <View>
                            <Text style={{ color: "red" }}>
                              {errors.NoOfTeamsparticipate}
                            </Text>
                          </View>
                        )}
                    </View>
                    <View style={styles.textField}>
                      <Text style={styles.uploadLogoText}>
                        Format <Text style={styles.requiredAsterisk}>*</Text>
                      </Text>

                      <Picker
                        style={styles.dropdownContainer}
                        selectedValue={selectedValue}
                        onValueChange={(itemValue, itemIndex) =>
                          handleRoundType(itemValue)
                        }
                      >
                        <Picker.Item
                          label="Select format"
                          value="Select format"
                        />
                        <Picker.Item label="League" value="League" />

                        <Picker.Item label="KnockOut" value="KnockOut" />
                        <Picker.Item
                          label="League+KnockOut"
                          value="League+KnockOut"
                        />
                        <Picker.Item
                          label="Double Round Robin"
                          value="Double Round Robin"
                        />
                        <Picker.Item
                          label="Double Round Robin+KnockOut"
                          value="Double Round Robin+KnockOut"
                        />
                      </Picker>
                    </View>
                    <View style={styles.textField}>
                      <Text style={styles.label}>
                        Max Players in Squad
                        <Text style={styles.requiredAsterisk}>*</Text>
                      </Text>

                      <TextInput
                        placeholder={"Max Number of Player in a squad"}
                        value={values.MaxPlayersInSquad}
                        placeholderTextColor={colors.darkGray}
                        onChangeText={(text) =>
                          inputEvent("MaxPlayersInSquad", text)
                        }
                        keyboardType={undefined}
                        style={styles.input}
                        onBlur={handleBlur("MaxPlayersInSquad")}
                        id={"MaxPlayersInSquad"}
                        onChange={handleChange}
                        ref={MaxPlayersInSquadRef}
                      />
                      {touched.MaxPlayersInSquad &&
                        errors.MaxPlayersInSquad && (
                          <View>
                            <Text style={{ color: "red" }}>
                              {errors.MaxPlayersInSquad}
                            </Text>
                          </View>
                        )}
                    </View>
                    <View
                      style={{
                        marginTop: 10,
                        flexDirection: "column",
                        // alignItems: "center",
                        // justifyContent: "space-between",
                        flex: 1,
                        maxWidth: "50%",
                        minWidth: "46%",
                      }}
                    >
                      <Text style={styles.extRegHeading}>
                        Allow External Registrations?
                        <Text style={styles.requiredAsterisk}>*</Text>
                      </Text>

                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",

                          gap:
                            Platform.OS == "web" && screenWidth > 760 ? 40 : 5,
                        }}
                      >
                        <View
                          style={{ flexDirection: "row", alignItems: "center" }}
                        >
                          <RadioButton
                            value="first"
                            status={
                              checked === "first" ? "checked" : "unchecked"
                            }
                            onPress={() => setChecked("first")}
                            color={colors.aqua}
                            uncheckedColor={colors.darkGray}
                          />
                          <Text style={{ color: colors.white }}>Yes</Text>
                        </View>
                        <View
                          style={{ flexDirection: "row", alignItems: "center" }}
                        >
                          <RadioButton
                            value="second"
                            color={colors.aqua}
                            uncheckedColor={colors.darkGray}
                            status={
                              checked === "second" ? "checked" : "unchecked"
                            }
                            onPress={() => setChecked("second")}
                          />
                          <Text style={{ color: colors.white }}>No</Text>
                        </View>
                      </View>
                    </View>
                    {touched.checked && errors.checked && (
                      <View>
                        <Text style={{ color: "red" }}>{errors.checked}</Text>
                      </View>
                    )}
                    {Platform.OS === "web" ? (
                      <View
                        style={{
                          flex: 1,
                          // maxWidth: "50%",
                          minWidth: "35%",
                        }}
                      >
                        <Text
                          style={[styles.extRegHeading, { paddingTop: 10 }]}
                        >
                          Location{" "}
                          <Text style={styles.requiredAsterisk}>*</Text>
                        </Text>

                        <Venue
                          handlegetVenuedata={handlegetVenuedata}
                          VenueList={venueObj}
                          Index={0}
                          Nameflag={undefined}
                          VenueObject={venueObj}
                        />
                      </View>
                    ) : null}

                    <View style={styles.textField}>
                      <Text style={styles.label}>
                        Level <Text style={styles.requiredAsterisk}></Text>
                      </Text>

                      <TextInput
                        placeholder={
                          "Area/City/Zone/Region/State/National/International"
                        }
                        placeholderTextColor={colors.darkGray}
                        value={inputText.level}
                        onChangeText={(text) => inputEvent("level", text)}
                        keyboardType={undefined}
                        style={styles.input}
                        // onBlur={handleBlur("NoOfTeamsparticipate")}
                        id={"level"}
                        onChange={handleChange}
                      />
                      {/* {touched.NoOfTeamsparticipate &&
              errors.NoOfTeamsparticipate && (
                <View>
                  <Text style={{ color: "red" }}>
                    {errors.NoOfTeamsparticipate}
                  </Text>
                </View>
              )} */}
                    </View>
                    <View style={{ width: "100%", marginTop: 15 }}>
                      <Text style={styles.uploadLogoText}>
                        Upload Logo and Banner
                        <Text style={styles.requiredAsterisk}>*</Text>
                      </Text>

                      <View
                        style={{
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 15,
                          flexDirection:
                            Platform.OS === "web" && screenWidth > 760
                              ? "row"
                              : "column",
                        }}
                      >
                        {Platform.OS === "web" && (
                          <Pressable
                            onPress={() => pickImageAsync("Logo")}
                            style={{ width: 120 }}
                          >
                            <View style={styles.circleContainer}>
                              <View style={styles.circle}>
                                {selectedImage ? (
                                  <Image
                                    source={{ uri: selectedImage.URL }}
                                    style={{
                                      width: 100,
                                      height: 100,
                                      borderRadius: 60,
                                    }}
                                  />
                                ) : (
                                  <View style={styles.circle}>
                                    <Image
                                      source={require("../../../../../assets/icons/Camera.svg")}
                                      style={styles.cameraIcon}
                                    />
                                    <Text style={{ color: colors.darkGray }}>
                                      Upload Logo
                                    </Text>
                                  </View>
                                )}
                              </View>
                            </View>
                          </Pressable>
                        )}

                        <Pressable
                          onPress={() => pickImageAsync("Banner")}
                          style={{
                            flex: 1,
                            width:
                              Platform.OS === "web" && screenWidth > 760
                                ? undefined
                                : "100%",
                          }}
                        >
                          <View style={styles.boxContainer}>
                            <View style={styles.box}>
                              {banerselectedImage ? (
                                <BanerImageViewer
                                  placeholderImageSource={undefined}
                                  banerselectedImage={banerselectedImage}
                                />
                              ) : (
                                <View style={styles.circle}>
                                  <UploadIcon
                                    name="cloud-upload"
                                    size={40}
                                    color={colors.aqua}
                                  />
                                  <Text
                                    style={{
                                      color: colors.darkGray,
                                      marginVertical: 8,
                                    }}
                                  >
                                    Size 650* 400 (Max 1 Mb Only)
                                  </Text>
                                  <Text style={{ color: colors.darkGray }}>
                                    Upload Banner
                                  </Text>
                                </View>
                              )}
                            </View>
                          </View>
                        </Pressable>
                      </View>
                    </View>
                    <View
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        gap: 15,
                        flexDirection: "row",
                        marginTop: 25,
                        paddingBottom:
                          Platform.OS === "web" && screenWidth > 760
                            ? undefined
                            : 30,
                      }}
                    >
                      <Pressable
                        style={[
                          styles.cancelBtn,
                          {
                            backgroundColor:
                              hoverIn2 === true
                                ? colors.orangeColour
                                : "transparent",
                            borderColor:
                              hoverIn2 === true
                                ? colors.orangeColour
                                : colors.white,
                          },
                        ]}
                        onPress={() => {
                          navigation.navigate(`${lastURL}`);
                        }}
                        onHoverIn={handleHoverIn2}
                        onHoverOut={handleHoverOut2}
                      >
                        <Text style={[styles.cancelBtnText]}>Cancel</Text>
                      </Pressable>

                      <Pressable
                        style={[
                          styles.submitBtn,
                          {
                            backgroundColor:
                              hoverIn === true ? colors.orange : colors.aqua,
                            borderColor:
                              hoverIn === true ? colors.orange : colors.aqua,
                          },
                        ]}
                        onPress={() => {
                          handleFocus(
                            {
                              tournamentNameRef,
                              organizerNameRef,
                              organizerEmailRef,
                              playingRef,
                              subsituteRef,
                              MaxPlayersInSquadRef,
                              NoOfTeamsparticipateRef,
                            },
                            values
                          );
                          handleSubmit();
                        }}
                        onHoverIn={handleHoverIn}
                        onHoverOut={handleHoverOut}
                      >
                        <Text
                          style={[
                            styles.participatedButtonText,
                            {
                              fontSize: 16,
                              color: hoverIn === true ? "#fff" : "#222222",
                            },
                          ]}
                        >
                          Submit
                        </Text>
                      </Pressable>
                    </View>
                  </View>
                )}
              </Formik>
            </View>
          </View>
        </ScrollView>
        {errorModalVisible == true ? (
          <ErrorModal
            visibleModal={errorModalVisible}
            SuccessMessage={errorModalMesg}
            onSubmit={setErrorModalVisible(!errorModalVisible)}
            header={"Error!"}
          />
        ) : null}
        {SuccessModalVisible == true ? (
          <AutoExitModalTrophy
            visibleModal={SuccessModalVisible}
            SuccessMessage={SuccessModalMesg}
            handleOk={undefined}
          />
        ) : null}

        {logoErrorModalVisible == true ? (
          <ErrorModal
            visibleModal={logoErrorModalVisible}
            header={"Error!"}
            SuccessMessage={LogoErrorModalMesg}
            onSubmit={handleSubmitLogo}
          />
        ) : null}

        {banerErrorModalVisible == true ? (
          <ErrorModal
            visibleModal={banerErrorModalVisible}
            header={"Error!"}
            SuccessMessage={BanerErrorModalMesg}
            onSubmit={handleSubmitBaner}
          />
        ) : null}
      </Layout>
    </>
  );
};

const styles = StyleSheet.create({
  formBgContainer: {
    padding: 15,
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    marginBottom: Platform.OS === "web" && screenWidth > 760 ? 5 : 0,
  },
  // input: {
  //   height: 34,
  //   fontSize: 15,
  //   fontFamily: "PlusJakartaSansRegular",
  //   borderWidth: 1,
  //   borderRadius: 5,
  //   paddingLeft: 10,
  //   outlineColor: "transparent",
  //   outlineWidth: 0,
  //   borderColor: colors.borderColor,
  //   color: colors.white,
  // },
  errorText: {
    color: "red",
    fontSize: 12,
  },
  requiredAsterisk: {
    color: "red",
    marginLeft: 4,
  },
  Required: {
    // color: "#fff",
    color: "red",
  },
  formContainerCard: {
    padding: Platform.OS === "web" && screenWidth > 760 ? 20 : 10,

    backgroundColor: colors.royalBlue,
    borderRadius: 10,
    justifyContent:
      Platform.OS === "web" && screenWidth > 760 ? "space-evenly" : undefined,
    width: Platform.OS === "web" && screenWidth > 760 ? 750 : "100%",
    maxWidth: Platform.OS === "web" && screenWidth > 760 ? "90%" : "100%",
    position:
      Platform.OS === "web" && screenWidth > 760 ? "relative" : undefined,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
  },
  formWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 10,
  },
  heading: {
    color: colors.white,
    fontFamily: "PlusJakartaSansSemiBold",
    textAlign: "center",
    padding: Platform.OS === "web" && screenWidth > 760 ? undefined : 20,
  },
  textField: {
    flex: 1,
    maxWidth: Platform.OS === "web" && screenWidth > 760 ? "50%" : "100%",
    minWidth: Platform.OS === "web" && screenWidth > 760 ? "46%" : "100%",
    marginBottom: 0,
    color: colors.white,
  },
  input: {
    height: 34,
    fontSize: 15,
    fontFamily: "PlusJakartaSansExtraLight",
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 10,
    outlineColor: "transparent",
    outlineWidth: 0,
    borderColor: colors.borderColor,
    color: colors.white,
    // flex: 1,
    // maxWidth: Platform.OS === "web" && screenWidth > 760 ? "50%" : "100%",
    // minWidth: Platform.OS === "web" && screenWidth > 760 ? "46%" : "100%",
    width: "100%",
    marginBottom: 0,
  },
  dropdownContainer: {
    width: "100%",
    marginBottom: 0,
    height: 34,
    borderRadius: 5,
    backgroundColor: colors.royalBlue,
    color: colors.white,
    borderColor: colors.borderColor,
  },
  extRegHeading: {
    paddingBottom: 10,
    fontFamily: "PlusJakartaSansRegular",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 14 : 12,
    color: colors.white,
  },
  circleContainer: {
    width: 120,
    height: 120,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: "blue",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
  },
  circle: {
    width: "100%",
    height: "100%",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.bgColor,
  },
  cameraIcon: {
    height: 20,
    width: 20,
  },
  //
  boxContainer: {
    width: "100%",
    height: Platform.OS === "web" && screenWidth > 760 ? 180 : 120,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.bgColor,
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
  },
  box: {
    width: "100%",
    height: Platform.OS === "web" && screenWidth > 760 ? 180 : 120,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.bgColor,
  },
  // uploadIcon: {
  //   height: 20,
  //   width: 30,
  // },
  banerImage: {
    width: "100%",
    height: "100%",
    borderColor: colors.bgColor,
    borderWidth: 1,
  },
  uploadLogoText: {
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
    color: colors.white,
    paddingVertical: 10,
  },
  //
  submitBtn: {
    borderWidth: 1,
    padding: 5,
    borderRadius: 10,
    fontSize: 14,
    width: 120,
    height: Platform.OS === "web" && screenWidth > 760 ? 30 : 35,
    justifyContent: "center",
    alignItems: "center",
  },

  participatedButtonText: {
    color: "#222",
    textAlign: "center",
    fontFamily: "PlusJakartaSansSemiBold",
  },
  cancelBtn: {
    borderWidth: 1,
    // borderColor: colors.white,
    padding: 5,
    borderRadius: 10,
    fontSize: 14,
    width: 120,
    height: Platform.OS === "web" && screenWidth > 760 ? 30 : 35,
    justifyContent: "center",
    alignItems: "center",
  },
  cancelBtnText: {
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 16,
    textAlign: "center",
    color: colors.white,
  },
  label: {
    paddingBottom: 10,
    fontFamily: "PlusJakartaSansRegular",
    paddingTop: 10,
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 14 : 12,
    color: colors.white,
  },
});

export default CreateTournament;
function onImageSelect(
  uri: string,
  arg1: string,
  index: any,
  fileName: string
) {
  throw new Error("Function not implemented.");
}
