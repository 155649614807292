import React, { useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Dimensions,
  Platform,
  Modal,
  TextInput,
  ActivityIndicator,
  TouchableWithoutFeedback,
} from "react-native";
import { colors } from "../../../../components/reusable/colour";
import TrashIcon from "react-native-vector-icons/Ionicons";
import AddIcon from "react-native-vector-icons/Feather";
import { Button } from "@rneui/themed";
import UploadImage from "../../../../components/reusable/UploadImage";
import awsmobile from "../../../../aws-exports";

import { DataStore } from "aws-amplify/datastore";
import {
  CoachMaster,
  PlayerMaster,
  Squad,
  SquadPlayerMaster,
  TeamRegistrationsPlayerMaster,
  UserMaster,
} from "../../../../models";
import { getPlayersData } from "../../../../api/PlayersAPI";
import { getUserMasterData } from "../../../../api/UserMasterAPI";
import { getCoachMasterData } from "../../../../api/CoachMasterAPI";
import { uploadData } from "aws-amplify/storage";

import { AuthenticationContext } from "../../../../authentication/Authentication.js";
import { CustomDateField } from "../../../../components/reusable/textFeild";
import { Formik } from "formik";
import * as Yup from "yup";
import { SuccessAutoExitModal } from "../../../../components/reusable/AutoExitModal";
import {
  AreYouSureOrange,
  ErrorModal,
} from "../../../../components/reusable/AreYouSure";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { handlePlayerSerialNumber } from "../../../../components/reusable/CreatePlayerSerialNumber";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;
let admin1UserData;
const bucketName = awsmobile.aws_user_files_s3_bucket;

const TeamModal = ({ visibleModal, handleClose, squadId }) => {
  let playerSerialNumber: any;
  const [uuid, setUUID] = useState("");

  const [finalPlayerData, setFinalPlayerData] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [teamLogoUrl, setTeamLogoUrl] = useState("");
  const [coachName, setCoachName] = useState("");
  const [coachMobileNo, setCoachMobileNo] = useState("");
  const [adminName, setAdminName] = useState("");
  const [adminMobileNo, setAdminMobileNo] = useState("");
  const [deletedUUIDS, setDeletedUUIDs] = useState([]);
  const [playersList, setPlayersList]: any = useState([
    {
      Name: "",
      DOB: "",
      JerseyNo: "",
      ContactNo: "",
      PhotoURL: "",
      UUID: "",
      playerSummaryId: "",
      coachMasterId: "",
      enable: false,
    },
  ]);
  const [teamLogoURL, setTeamLogoURL] = useState("");
  const [profilePicsArray, setProfilePicsArray] = useState([
    {
      Pic: null,
      UploadedPercentage: "",
      Url: "",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [successModalMesg, setSuccessModalMesg] = useState("");
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalMesg, setErrorModalMesg] = useState("");
  const [modalVisible, setModalVisible] = useState(visibleModal);

  const [deleteModalMessage, setDeleteModalMessage] = useState("");
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [newPlayerAddedFlag, setNewPlayerAddedFlag] = useState(false);

  const handleOk = () => {
    setSuccessModalVisible(false);
  };

  const handleCloseErrorModal = () => {
    setErrorModalVisible(false);
  };

  let teamLogo = "";
  let coachesData = [];
  let coachesUserMaster = [];
  let squadData = [];

  let playerUserMaster = [];
  const { createAdminUser, getAdminUser } = useContext(AuthenticationContext);

  const getUserdata = async () => {
    let userDetails = await AsyncStorage.getItem("UserDetail");
    console.log("userDetails", userDetails);
    if (userDetails) {
      const userData = await DataStore.query(UserMaster, userDetails);
      console.log("userData", userData);
      admin1UserData = userData;
      setUUID(userData.UUID);
    } else {
      console.log("No user found in temp storage");
    }
  };

  const getTeamsData = async () => {
    const subscription = DataStore.observeQuery(Squad, (c) =>
      c.id.eq(squadId)
    ).subscribe(async (snapshot) => {
      const initialSquads = snapshot.items;
      console.log("InitialSquads ", initialSquads);

      setTeamName(initialSquads[0]?.Name);
      console.log("Logo : ", initialSquads[0]?.Logo);
      setTeamLogoUrl(initialSquads[0].Logo);
      teamLogo = initialSquads[0].Logo;

      let teamPlayersDataPromises = initialSquads.map(async (data) =>
        data.TeamPlayers.toArray()
      );
      console.log("TeamPlayersDataPromises", teamPlayersDataPromises);
      let playersData = await Promise.all(teamPlayersDataPromises);
      console.log("PlayersData", playersData);
      //matchesData

      for (let i = 0; i < squadData.length; i++) {
        squadData[i].pop;
      }

      for (let i = 0; i < playersData.length; i++) {
        for (let j = 0; j < playersData[i].length; j++) {
          await getPlayersData(playersData[i][j].playerMasterId).then(
            (response) => {
              if (response) {
                // add contact no
                console.log("response", response);
                squadData.push(response);
              }
            }
          );
        }
      }

      console.log("squadData : ", squadData);

      const updatedSquadData = await Promise.all(
        squadData.map(async (data) => {
          // Await the PlayerData promise for each squad data
          const playerData = await data.PlayerData;

          // Extract ContactNo if playerData is defined
          const contactNo = playerData ? playerData.ContactNo : null;

          // Return updated squad data with ContactNo added
          return {
            ...data,
            ContactNo: contactNo,
          };
        })
      );

      console.log("Updated squadData:", updatedSquadData);
      setPlayersList(updatedSquadData);

      for (let i = 0; i < playerUserMaster.length; i++) {
        playerUserMaster[i].pop;
      }

      for (let i = 0; i < squadData.length; i++) {
        console.log("Ind" + i + squadData[i].playerMasterPlayerDataUUID);
        await getUserMasterData(squadData[i].playerMasterPlayerDataUUID).then(
          (response) => {
            if (response) {
              playerUserMaster.push(response);
            }
          }
        );
      }
      console.log("playerUserMaster", playerUserMaster);
      for (let i = 0; i < initialSquads.length; i++) {
        console.log("Admin2 : ", initialSquads[i].Admin2);
        if (initialSquads[i].Admin2 != null) {
          console.log("Admin2 UUID : ", initialSquads[i].Admin2.UUID);
          const admin2UUID = initialSquads[i].Admin2.UUID;
          console.log("admin2UUID", admin2UUID);
          await getUserMasterData(admin2UUID).then((response) => {
            console.log("admin2 data", response);
            if (response) {
              console.log("admin2 data", response);
              const adminName = response?.Name;
              console.log("adminName", adminName);
              setAdminName(adminName);

              const adminMobileNo = response?.ContactNo.toString();
              console.log("adminMobileNo", adminMobileNo);
              setAdminMobileNo(adminMobileNo);
            }
          });
        } else {
          console.log("Admin2 is not available");
        }
      }
      playerUserMaster = playerUserMaster.map((obj) => ({
        ...obj,
        isSelected: false,
        enable: false,
      }));

      console.log("Team Data : ", squadData);
      console.log("Player Master : ", playerUserMaster);
      // setOldSquadPlayers(playerUserMaster);
      setFinalPlayerData(playerUserMaster);

      let teamCoachDataPromises = initialSquads.map(async (data) =>
        data.TeamCoaches.toArray()
      );
      console.log("TeamCoachesDataPromises", teamCoachDataPromises);
      let coachData = await Promise.all(teamCoachDataPromises);
      console.log("CoachesData", coachData);

      for (let i = 0; i < coachesData.length; i++) {
        coachesData[i].pop;
      }

      for (let i = 0; i < coachData.length; i++) {
        for (let j = 0; j < coachData[i].length; j++) {
          await getCoachMasterData(coachData[i][j].coachMasterId).then(
            (response) => {
              if (response) {
                coachesData.push(response);
              }
            }
          );
        }
      }

      console.log("Id : ", coachesData);
      for (let i = 0; i < coachesUserMaster.length; i++) {
        coachesUserMaster[i].pop;
      }
      for (let i = 0; i < coachesData.length; i++) {
        console.log("Ind" + i + coachesData[i].coachMasterCoachDataUUID);
        await getUserMasterData(coachesData[i].coachMasterCoachDataUUID).then(
          (response) => {
            if (response) {
              console.log("Index " + i + response);
              console.log("response", response);
              coachesUserMaster.push(response);
              console.log("Coach Name", response.Name);
              setCoachName(response.Name);
              console.log("Coach mobile number", response.ContactNo);
              setCoachMobileNo(response.ContactNo?.toString());
            }
          }
        );
      }
      console.log("Team Data 1: ", finalPlayerData);
      console.log("Coach Master Data : ", coachesUserMaster);
      // setFinalCoachData(coachesUserMaster);

      for (let i = 0; i < finalPlayerData.length; i++) {
        console.log("Aditya" + i + finalPlayerData[i].ContactNo);
        inputPlayerEvent("Name", finalPlayerData[i].Name, i);
        inputPlayerEvent("DOB", finalPlayerData[i].DateOfBirth, i);
        inputPlayerEvent("JerseyNo", finalPlayerData[i].JerseyNo, i);
        inputPlayerEvent("ContactNo", finalPlayerData[i].ContactNo, i);
        inputPlayerEvent("PhotoURL", finalPlayerData[i].PhotoURL, i);
        inputPlayerEvent("UUID", finalPlayerData[i].UUID, i);
      }

      // setFinalPlayerData(finalPlayerData);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const validationSchema = Yup.object().shape({
    teamName: Yup.string()
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        "Team Name must not contain special characters"
      )
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Team name required"),
    coachName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Coach name required"),
    coachMobileNo: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid Mobile Number")
      .required("Coach mobile number required"),
  });

  useEffect(() => {
    console.log("handleClose", handleClose, "visibleModal", visibleModal);
    const fetchData = async () => {
      await getUserdata();
      await getTeamsData();
    };
    fetchData();
  }, []);

  const handleAddPlayer = () => {
    const newPlayer = {
      Name: "",
      DOB: "",
      JerseyNo: "",
      MobileNo: "",
      PhotoURL: "",
      UUID: "",
      playerSummaryId: "",
      coachMasterId: "",
    };
    console.log("NewPlayer", newPlayer);
    setPlayersList((prevData: any) => [...prevData, newPlayer]);
    setNewPlayerAddedFlag(true);
  };

  const handleSetProfilePic = (e: any, field: any, index: any) => {
    console.log("targetvalue");
    console.log("We are in handle set PP");

    if (field === "Pic") {
      const { Pic, value } = e;
      const list = [...profilePicsArray];
      console.log("Pic : ", e);
      list[index].Pic = e;
      console.log("list", list);
      console.log("We are in Pic");
      setProfilePicsArray(list);
    }
    if (field === "UploadedPercentage") {
      console.log("Target", e);
      const list = [...profilePicsArray];

      const { UploadedPercentage } = e;
      console.log("Li", list[index]);
      list[index].UploadedPercentage = UploadedPercentage;
      console.log("UploadedPercentage", UploadedPercentage);
      console.log("list", list);
      console.log("We are in handle set Percentage");
      setProfilePicsArray(list);
    } else {
      const { Url, value } = e;
      const list = [...profilePicsArray];

      list[index].Url = e;
      console.log("list", list);
      console.log("We are in handle set Url");
      setProfilePicsArray(list);
    }
  };

  async function uploadLogoFileToStorage(uri: any) {
    try {
      const response = await fetch(uri);
      const blob = await response.blob();
      await uploadData({
        key: "team/" + teamName.replaceAll(" ", "_") + "/logo.png",
        data: blob,
        options: {
          onProgress: ({ transferredBytes, totalBytes }) => {
            console.log(`Uploaded: ${transferredBytes}/${totalBytes}`);
            let percentage = (transferredBytes / totalBytes) * 100;
            let url =
              `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/team/` +
              teamName.replaceAll(" ", "_") +
              "/logo.png";
            console.log("Percentage : ", percentage);
            setTeamLogoURL(url);
          },
          // contentType: 'image/png' // contentType is optional
        },
      });
    } catch (err) {
      console.log("Error uploading file:", err);
    }
  }

  async function uploadProfilePicToStorage(uri, index) {
    const number = Math.floor(100000 + Math.random() * 900000)
      .toString()
      .substring(0, 4);
    try {
      const response = await fetch(uri);
      const blob = await response.blob();
      await uploadData({
        key: "userPhotos/" + number + ".jpg",
        data: blob,
        options: {
          onProgress: ({ transferredBytes, totalBytes }) => {
            console.log(`Uploaded: ${transferredBytes}/${totalBytes}`);
            let percentage = (transferredBytes / totalBytes) * 100;
            let url =
              `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/userPhotos/` +
              number +
              ".jpg";
            console.log("Percentage : ", percentage);

            if (percentage === 100) {
              console.log("Percentage is 100");
              inputPlayerEvent("PhotoURL", url, index);
            }
            handleSetProfilePic(percentage, "UploadedPercentage", index);
            handleSetProfilePic(url, "Url", index);
          },
        },
        // contentType: 'image/png' // contentType is optional
      });
    } catch (err) {
      console.log("Error uploading file:", err);
    }
  }

  const handleImageSelection = (uri, kindOfFile, index) => {
    if (kindOfFile === "logo") {
      setTeamLogoURL(uri);
      uploadLogoFileToStorage(uri);
    } else if (kindOfFile == "profilePic") {
      uploadProfilePicToStorage(uri, index);
    }
  };

  const inputPlayerEvent = (field, event, idx) => {
    console.log("hellovalue", field, event, idx);
    const updatedPlayerList = playersList.map((player, index) => {
      if (index === idx) {
        return { ...player, [field]: event };
      }
      return player;
    });
    setPlayersList(updatedPlayerList);
  };

  const handleInputTeamDetailsChange = (fieldName: any, value: any) => {
    switch (fieldName) {
      case "teamName":
        console.log("Team Name", value);
        setTeamName(value);
        break;
      case "coachName":
        console.log("Coach Name", value);
        setCoachName(value);
        break;
      case "coachMobileNo":
        console.log("Coach Mobile No", value);
        setCoachMobileNo(value);
        break;
      case "adminName":
        console.log("Admin Name", value);
        setAdminName(value);
        break;
      case "adminMobileNo":
        console.log("Admin Mobile No", value);
        setAdminMobileNo(value);
        break;

      default:
        // Handle default case if needed
        break;
    }
  };

  // new code

  // Function to handle the delete action
  const handleDeletePlayer = (indexToDelete) => {
    // Set the delete index
    setDeleteIndex(indexToDelete);
    // Set the message for the delete modal
    setDeleteModalMessage("Do you want to delete this player?");
    // Show the delete modal
    setDeleteModalVisible(true);
  };

  // Function to confirm the delete action
  const confirmDelete = () => {
    if (deleteIndex !== null) {
      // Filter out the player to delete based on the given index
      const updatedData = playersList.filter(
        (_, index) => index !== deleteIndex
      );
      setPlayersList(updatedData);
      // Reset the delete index
      setDeleteIndex(null);
      // Close the delete modal
      setDeleteModalVisible(false);
    }
  };

  // Function to cancel the delete action
  const cancelDelete = () => {
    // Reset the delete index
    setDeleteIndex(null);
    // Close the delete modal
    setDeleteModalVisible(false);
  };

  const addUser = async (phoneNumber) => {
    let finalResponse;
    const userAttribute = [
      {
        Name: "phone_number" /* required */,
        Value: phoneNumber,
      },
    ];
    console.log("Respa", finalResponse);
    return finalResponse;
  };
  const cognitoUserDetails = async (phoneNumber) => {
    const response = await getAdminUser(phoneNumber);
    if (response) {
      console.log("UserDetails : ", response);
    }
    return response;
  };

  let coachSummary: any, coachUUID: any, userMasterCoach: any;

  // const handleUpdate = async () => {
  //   console.log("button clicked");
  //   try {
  //     setLoading(true);

  //     // Step:1 Create UserMaster record using coach mobile number & Create CoachMaster record using coach mobile number
  //     if (coachMobileNo && coachMobileNo.length > 0) {
  //       const resCoach = await cognitoUserDetails("+91" + coachMobileNo);
  //       console.log(resCoach.statusCode);
  //       if (resCoach.statusCode === 400) {
  //         const resAddCoach = await addUser("+91" + coachMobileNo);
  //         coachUUID = resAddCoach?.User?.Username;
  //       } else {
  //         console.log("Coach Found data : ", resCoach);
  //         coachUUID = resCoach?.Username;
  //       }
  //       await getUserMasterData(coachUUID).then(async (response) => {
  //         if (response) {
  //           console.log("Coach Data", response);
  //           userMasterCoach = response;
  //           if (!userMasterCoach.userMasterCoachSummaryId) {
  //             console.log("We are in if Coach Summary");
  //             try {
  //               coachSummary = await DataStore.save(
  //                 new CoachMaster({
  //                   Name: coachName,
  //                   Games: 0,
  //                   Won: 0,
  //                   Lost: 0,
  //                   Drawn: 0,
  //                 })
  //               );
  //             } catch (err) {
  //               console.log("Error in saving coach summary : ", coachSummary);
  //               setLoading(false);
  //             }
  //           } else {
  //             console.log("We are in else of coach summary");
  //             const original = await DataStore.query(
  //               CoachMaster,
  //               userMasterCoach.userMasterCoachSummaryId
  //             );
  //             try {
  //               const updatedItem = CoachMaster.copyOf(original, (updated) => {
  //                 updated.Name = coachName;
  //               });
  //               await DataStore.save(updatedItem);
  //               console.log("Updated Item:", updatedItem);
  //             } catch (error) {
  //               console.error("Error updating item:", error);
  //               setLoading(false);
  //             }
  //           }
  //           // try {
  //           //   userMasterCoach.Name = coachName;
  //           //   userMasterCoach.ContactNo = Number(coachMobileNo);
  //           //   await DataStore.save(userMasterCoach);
  //           //   console.log("UserMaster Coach details updated:", userMasterCoach);
  //           // } catch (error) {
  //           //   console.error("Error updating UserMaster Coach details:", error);
  //           //   setLoading(false);
  //           // }
  //         } else {
  //           console.log("No coach data found");
  //           try {
  //             coachSummary = await DataStore.save(
  //               new CoachMaster({
  //                 Name: coachName,
  //                 Games: 0,
  //                 Won: 0,
  //                 Lost: 0,
  //                 Drawn: 0,
  //               })
  //             );
  //             userMasterCoach = await DataStore.save(
  //               new UserMaster({
  //                 UUID: coachUUID,
  //                 Name: coachName,
  //                 ContactNo: Number(coachMobileNo),
  //                 EmailId: "",
  //                 PhotoURL: "",
  //                 CreatedBy: admin1UserData.Name,
  //                 LastUpdatedBy: admin1UserData.Name,
  //                 RoleCoach: true,
  //                 RolePlayer: false,
  //                 CoachSummary: coachSummary,
  //               })
  //             );
  //             const original = await DataStore.query(
  //               CoachMaster,
  //               userMasterCoach.userMasterCoachSummaryId
  //             );
  //             try {
  //               const updatedItem = CoachMaster.copyOf(original, (updated) => {
  //                 updated.coachMasterCoachDataUUID = userMasterCoach.UUID;
  //               });
  //               await DataStore.save(updatedItem);
  //               console.log("Updated Item:", updatedItem);
  //             } catch (error) {
  //               console.error("Error updating item:", error);
  //               setLoading(false);
  //             }
  //             console.log("UserMaster Coach : ", userMasterCoach);
  //           } catch (e) {
  //             console.log("Error : ", e);
  //             setLoading(false);
  //           }
  //         }
  //       });
  //     } else {
  //       console.log("CoachMobile number is not provided");
  //     }

  //     // Step:2 Create SquadMaster record using team details & Create PlayerMaster record using players details
  //     for (let i = 0; i < playersList.length; i++) {
  //       const playerData = playersList[i];
  //       console.log("playerData", playerData);
  //       let res = await cognitoUserDetails("+91" + playerData.ContactNo);
  //       if (res.statusCode === 400) {
  //         const resAddPlayer = await addUser("+91" + playerData.ContactNo);
  //         playerData.playerMasterPlayerDataUUID = resAddPlayer?.User?.Username;
  //       } else {
  //         playerData.playerMasterPlayerDataUUID = res.Username;
  //       }

  //       const playerUserMaster = await getUserMasterData(
  //         playerData.playerMasterPlayerDataUUID
  //       );
  //       console.log("playerUserMaster", playerUserMaster);
  //       let playerSummaryData;
  //       if (playerUserMaster && playerUserMaster.userMasterPlayerSummaryId) {
  //         playerSummaryData = await DataStore.query(
  //           PlayerMaster,
  //           playerUserMaster.userMasterPlayerSummaryId
  //         );
  //         playerSummaryData = await DataStore.save(
  //           PlayerMaster.copyOf(playerSummaryData, (updated) => {
  //             updated.Name = playerData.Name;
  //             updated.PhotoURL = playerData.PhotoURL;
  //             updated.JerseyNo = playerData.JerseyNo;
  //             updated.DateOfBirth = playerData.DateOfBirth;
  //           })
  //         );
  //         console.log("playerSummaryData", playerSummaryData);
  //       } else {
  //         playerSummaryData = await DataStore.save(
  //           new PlayerMaster({
  //             Name: playerData.Name,
  //             PhotoURL: playerData.PhotoURL,
  //             JerseyNo: playerData.JerseyNo,
  //             DateOfBirth: playerData.DateOfBirth,
  //           })
  //         );
  //         console.log("playerSummaryData", playerSummaryData);

  //         // let squadPlayerMaster = await DataStore.save(
  //         //   new SquadPlayerMaster({
  //         //     squadId: squadId,
  //         //     playerMasterId: playerSummaryData.id,
  //         //   })
  //         // );
  //         // console.log("squadPlayerMaster", squadPlayerMaster);

  //         let userData = await DataStore.save(
  //           new UserMaster({
  //             UUID: playerData?.playerMasterPlayerDataUUID,
  //             Name: playerData.Name,
  //             ContactNo: Number(playerData.ContactNo),
  //             EmailId: "",
  //             PhotoURL: playerData.PhotoURL,
  //             CreatedBy: admin1UserData.Name,
  //             LastUpdatedBy: admin1UserData.Name,
  //             RoleCoach: false,
  //             RolePlayer: true,
  //             PlayerSummary: playerSummaryData,
  //             DateOfBirth: playerData.DateOfBirth,
  //           })
  //         );
  //         console.log("userData", userData);
  //       }

  //       const existingSquadPlayer = await DataStore.query(
  //         SquadPlayerMaster,
  //         (c) =>
  //           c.playerMasterId.eq(playerSummaryData.id) && c.squadId.eq(squadId)
  //       );
  //       console.log("existingSquadPlayer", existingSquadPlayer);

  //       if (existingSquadPlayer.length === 0) {
  //         await DataStore.save(
  //           new SquadPlayerMaster({
  //             squadId: squadId,
  //             playerMasterId: playerSummaryData.id,
  //           })
  //         );
  //       } else {
  //         const existingRecord = existingSquadPlayer[0];
  //         console.log("existingRecord", existingRecord);
  //         const updatedSquadPlayer = SquadPlayerMaster.copyOf(
  //           existingRecord,
  //           (updated) => {
  //             updated.playerMasterId = playerSummaryData.id;
  //           }
  //         );
  //         await DataStore.save(updatedSquadPlayer);
  //         console.log(
  //           "Player already in SquadPlayerMaster, updated player data",
  //           updatedSquadPlayer
  //         );
  //       }

  //       if (newPlayerAddedFlag === true && squadId) {
  //         playerSummaryData = await DataStore.save(
  //           new PlayerMaster({
  //             Name: playerData.Name,
  //             PhotoURL: playerData.PhotoURL,
  //             JerseyNo: playerData.JerseyNo,
  //             DateOfBirth: playerData.DateOfBirth,
  //           })
  //         );
  //         console.log("playerSummaryData", playerSummaryData);

  //         let squadPlayerMaster = await DataStore.save(
  //           new SquadPlayerMaster({
  //             squadId: squadId,
  //             playerMasterId: playerSummaryData.id,
  //           })
  //         );
  //         console.log("squadPlayerMaster", squadPlayerMaster);
  //       }
  //     }

  //     setModalVisible(false);
  //     setSuccessModalVisible(true);
  //     setSuccessModalMesg("Team details updated successfully");
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //     setErrorModalVisible(true);
  //     setErrorModalMesg("An error occurred while submitting the form.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleUpdate = async () => {
    console.log("button clicked");
    try {
      setLoading(true);

      // Step:1 Create UserMaster record using coach mobile number & Create CoachMaster record using coach mobile number
      if (coachMobileNo && coachMobileNo.length > 0) {
        const resCoach = await cognitoUserDetails("+91" + coachMobileNo);
        console.log(resCoach.statusCode);
        if (resCoach.statusCode === 400) {
          const resAddCoach = await addUser("+91" + coachMobileNo);
          coachUUID = resAddCoach?.User?.Username;
        } else {
          console.log("Coach Found data : ", resCoach);
          coachUUID = resCoach?.Username;
        }
        await getUserMasterData(coachUUID).then(async (response) => {
          if (response) {
            console.log("Coach Data", response);
            userMasterCoach = response;
            if (!userMasterCoach.userMasterCoachSummaryId) {
              console.log("We are in if Coach Summary");
              try {
                coachSummary = await DataStore.save(
                  new CoachMaster({
                    Name: coachName,
                    Games: 0,
                    Won: 0,
                    Lost: 0,
                    Drawn: 0,
                  })
                );
              } catch (err) {
                console.log("Error in saving coach summary : ", coachSummary);
                setLoading(false);
              }
            } else {
              console.log("We are in else of coach summary");
              const original = await DataStore.query(
                CoachMaster,
                userMasterCoach.userMasterCoachSummaryId
              );
              try {
                const updatedItem = CoachMaster.copyOf(original, (updated) => {
                  updated.Name = coachName;
                });
                await DataStore.save(updatedItem);
                console.log("Updated Item:", updatedItem);
              } catch (error) {
                console.error("Error updating item:", error);
                setLoading(false);
              }
            }
            // try {
            //   userMasterCoach.Name = coachName;
            //   userMasterCoach.ContactNo = Number(coachMobileNo);
            //   await DataStore.save(userMasterCoach);
            //   console.log("UserMaster Coach details updated:", userMasterCoach);
            // } catch (error) {
            //   console.error("Error updating UserMaster Coach details:", error);
            //   setLoading(false);
            // }
          } else {
            console.log("No coach data found");
            try {
              coachSummary = await DataStore.save(
                new CoachMaster({
                  Name: coachName,
                  Games: 0,
                  Won: 0,
                  Lost: 0,
                  Drawn: 0,
                })
              );
              userMasterCoach = await DataStore.save(
                new UserMaster({
                  UUID: coachUUID,
                  Name: coachName,
                  ContactNo: Number(coachMobileNo),
                  EmailId: "",
                  PhotoURL: "",
                  CreatedBy: admin1UserData.Name,
                  LastUpdatedBy: admin1UserData.Name,
                  RoleCoach: true,
                  RolePlayer: false,
                  CoachSummary: coachSummary,
                })
              );
              const original = await DataStore.query(
                CoachMaster,
                userMasterCoach.userMasterCoachSummaryId
              );
              try {
                const updatedItem = CoachMaster.copyOf(original, (updated) => {
                  updated.coachMasterCoachDataUUID = userMasterCoach.UUID;
                });
                await DataStore.save(updatedItem);
                console.log("Updated Item:", updatedItem);
              } catch (error) {
                console.error("Error updating item:", error);
                setLoading(false);
              }
              console.log("UserMaster Coach : ", userMasterCoach);
            } catch (e) {
              console.log("Error : ", e);
              setLoading(false);
            }
          }
        });
      } else {
        console.log("CoachMobile number is not provided");
      }

      // Step:2 Create SquadMaster record using team details & Create PlayerMaster record using players details
      for (let i = 0; i < playersList.length; i++) {
        const playerData = playersList[i];

        // Validate player data before proceeding
        if (!playerData.ContactNo || !playerData.Name || !playerData.JerseyNo) {
          console.warn("Skipping incomplete player data:", playerData);
          continue;
        }

        try {
          console.log("Processing playerData:", playerData);

          // Fetch or create Cognito user
          let res = await cognitoUserDetails("+91" + playerData.ContactNo);
          if (res.statusCode === 400) {
            const resAddPlayer = await addUser("+91" + playerData.ContactNo);
            playerData.playerMasterPlayerDataUUID =
              resAddPlayer?.User?.Username;
          } else {
            playerData.playerMasterPlayerDataUUID = res.Username;
          }

          // Query or create PlayerMaster
          const playerUserMaster = await getUserMasterData(
            playerData.playerMasterPlayerDataUUID
          );
          let playerSummaryData: any;
          if (playerUserMaster?.userMasterPlayerSummaryId) {
            const existingPlayerSummary = await DataStore.query(
              PlayerMaster,
              playerUserMaster.userMasterPlayerSummaryId
            );
            playerSummaryData = await DataStore.save(
              PlayerMaster.copyOf(existingPlayerSummary, (updated) => {
                updated.Name = playerData.Name;
                updated.PhotoURL = playerData.PhotoURL;
                updated.JerseyNo = playerData.JerseyNo;
                updated.DateOfBirth = playerData.DOB;
              })
            );
          } else {
            playerSummaryData = await DataStore.save(
              new PlayerMaster({
                Name: playerData.Name,
                PhotoURL: playerData.PhotoURL,
                JerseyNo: playerData.JerseyNo,
                DateOfBirth: playerData.DOB,
              })
            );
          }

          // Add to SquadPlayerMaster if not already added
          const existingSquadPlayer = await DataStore.query(
            SquadPlayerMaster,
            (c) =>
              c.and((condition) => [
                condition.playerMasterId.eq(playerSummaryData.id),
                condition.squadId.eq(squadId),
              ])
          );

          if (!existingSquadPlayer?.length) {
            await DataStore.save(
              new SquadPlayerMaster({
                squadId: squadId,
                playerMasterId: playerSummaryData.id,
              })
            );
          }

          console.log("Player successfully processed:", playerData);
        } catch (error) {
          console.error("Error processing player data:", playerData, error);
        }
      }

      setModalVisible(false);
      setSuccessModalVisible(true);
      setSuccessModalMesg("Team details updated successfully");
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrorModalVisible(true);
      setErrorModalMesg("An error occurred while submitting the form.");
    } finally {
      setLoading(false);
    }
  };
  const handleClicked = () => {
    console.log("button clicked !!!");
  };

  return (
    <Formik
      initialValues={{
        teamName: "",
        coachName: "",
        coachMobileNo: "",
        // adminName: "",
        // adminMobileNo: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values: any) => {
        console.log("values", values);
        handleUpdate();
      }}
      enableReinitialize={true}
    >
      {({ handleSubmit, handleChange, handleBlur, touched, errors }) => (
        <View>
          {/* modal started */}
          <TouchableWithoutFeedback>
            <Modal
              animationType="slide"
              transparent={true}
              visible={modalVisible}
              onRequestClose={() => handleClose()}
            >
              <View
                style={[styles.centeredView, { backgroundColor: "#04040491" }]}
              >
                <View style={styles.modalView}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 15,
                      borderBottomWidth: 1,
                      borderBottomColor: "#9a9696",
                      paddingBottom: 10,
                      marginBottom: 10,
                    }}
                  >
                    <View style={{ gap: 10, alignItems: "center" }}>
                      <UploadImage imageUrl={teamLogoUrl} index={0} />
                      <Text
                        style={{
                          color: "red",
                          fontFamily: "PlusJakartaSansRegular",
                        }}
                      >
                        (Size 280 X 280)
                      </Text>
                    </View>
                    <View style={{ gap: 10, flex: 1 }}>
                      <View style={styles.teamModalHeadingWrapper2}>
                        <View style={styles.teamModalHeadingWrapper}>
                          <Text style={styles.teamModalLabel}>Team Name</Text>
                          <TextInput
                            editable={true}
                            style={styles.TeamModalInput}
                            placeholder={""}
                            id="teamName"
                            value={teamName}
                            onChangeText={(text) => {
                              handleInputTeamDetailsChange("teamName", text);
                            }}
                            onChange={handleChange}
                            onBlur={handleBlur("teamName")}
                          />
                          {touched.teamName &&
                            errors.teamName &&
                            teamName == "" && (
                              <View>
                                <Text style={{ color: "red" }}>
                                  {errors.teamName.toString()}
                                </Text>
                              </View>
                            )}
                        </View>
                      </View>
                      <View style={styles.teamModalHeadingWrapper2}>
                        <View style={styles.teamModalHeadingWrapper}>
                          <Text style={styles.teamModalLabel}>Coach Name</Text>
                          <TextInput
                            style={styles.TeamModalInput}
                            placeholder={""}
                            value={coachName}
                            editable={true}
                            id="coachName"
                            onChangeText={(text) =>
                              handleInputTeamDetailsChange("coachName", text)
                            }
                            onChange={handleChange}
                            inputMode="text"
                            onBlur={handleBlur("coachName")}
                          />

                          {touched.coachName &&
                            errors.coachName &&
                            coachName == "" && (
                              <View>
                                <Text style={{ color: "red" }}>
                                  {errors.coachName.toString()}
                                </Text>
                              </View>
                            )}
                        </View>
                        <View style={styles.teamModalHeadingWrapper}>
                          <Text style={styles.teamModalLabel}>
                            Coach Mobile No.
                          </Text>
                          <TextInput
                            style={styles.TeamModalInput}
                            placeholder={""}
                            editable={true}
                            value={coachMobileNo}
                            id="coachMobileNo"
                            onChangeText={(text) =>
                              handleInputTeamDetailsChange(
                                "coachMobileNo",
                                text
                              )
                            }
                            onChange={handleChange}
                            inputMode="numeric"
                            maxLength={10}
                            onBlur={handleBlur("coachMobileNo")}
                          />
                          {touched.coachMobileNo &&
                            errors.coachMobileNo &&
                            coachMobileNo == "" && (
                              <View>
                                <Text style={{ color: "red" }}>
                                  {errors.coachMobileNo.toString()}
                                </Text>
                              </View>
                            )}
                        </View>
                      </View>
                      <View style={styles.teamModalHeadingWrapper2}>
                        <View style={styles.teamModalHeadingWrapper}>
                          <Text style={styles.teamModalLabel}>
                            Manager Name
                          </Text>
                          <TextInput
                            style={styles.TeamModalInput}
                            placeholder={""}
                            value={adminName}
                            editable={false}
                            id="adminName"
                            onChangeText={(text) =>
                              handleInputTeamDetailsChange("adminName", text)
                            }
                            onChange={handleChange}
                            inputMode="text"
                            onBlur={handleBlur("adminName")}
                          />

                          {/* {touched.adminName && errors.adminName && (
                            <View>
                              <Text style={{ color: "red" }}>
                                {errors.adminName.toString()}
                              </Text>
                            </View>
                          )} */}
                        </View>
                        <View style={styles.teamModalHeadingWrapper}>
                          <Text style={styles.teamModalLabel}>
                            Manager Mobile No.
                          </Text>
                          <TextInput
                            style={styles.TeamModalInput}
                            placeholder={""}
                            value={adminMobileNo}
                            editable={false}
                            id="adminMobileNo"
                            onChangeText={(text) =>
                              handleInputTeamDetailsChange(
                                "adminMobileNo",
                                text
                              )
                            }
                            onChange={handleChange}
                            inputMode="numeric"
                            maxLength={10}
                            onBlur={handleBlur("adminMobileNo")}
                          />

                          {/* {touched.adminMobileNo && errors.adminMobileNo && (
                            <View>
                              <Text style={{ color: "red" }}>
                                {errors.adminMobileNo.toString()}
                              </Text>
                            </View>
                          )} */}
                        </View>
                      </View>
                    </View>
                  </View>
                  {/* table started here (WEB)*/}
                  <View
                    style={{
                      width: "100%",
                      marginTop: 0,
                      display:
                        Platform.OS === "web" && screenWidth > 760
                          ? "flex"
                          : "none",
                    }}
                  >
                    {/* table header started */}
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "flex-end",
                        padding: 6,
                      }}
                    >
                      <Pressable
                        style={styles.addTeamBtn}
                        onPress={handleAddPlayer}
                      >
                        <AddIcon
                          name="plus-circle"
                          size={16}
                          color={colors.white}
                        />
                        <Text
                          style={{
                            color: colors.white,
                            fontSize: 12,
                            fontFamily: "PlusJakartaSansSemiBold",
                          }}
                        >
                          Add Player
                        </Text>
                      </Pressable>
                    </View>
                    <View style={styles.tableHeaderContainer}>
                      <View style={[styles.tableHeaderTitle]}>
                        <Text
                          style={[
                            styles.tableHeaderTitleText,
                            { textAlign: "left" },
                          ]}
                        >
                          Player
                        </Text>
                      </View>
                      <View style={[styles.tableHeaderTitle3]}>
                        <Text style={styles.tableHeaderTitleText}>
                          Date of Birth
                        </Text>
                      </View>
                      <View style={[styles.tableHeaderJTitle]}>
                        <Text style={styles.tableHeaderTitleText}>J No. </Text>
                      </View>
                      <View style={[styles.tableHeaderTitle3]}>
                        <Text style={styles.tableHeaderTitleText}>
                          Mobile No.
                        </Text>
                      </View>
                      <View style={{ width: 30 }}></View>
                    </View>
                    {/* table header ended */}
                    <View
                      style={{
                        maxHeight: 0.3 * screenHeight,
                        overflow: "scroll",
                      }}
                    >
                      {/* table row started */}
                      {playersList && playersList.length > 0 ? (
                        playersList.map((data: any, index: any) => (
                          <View key={index} style={styles.tableDataContainer}>
                            <View
                              style={[
                                styles.tableDataCell,
                                {
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                },
                              ]}
                            >
                              <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  gap: 5,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    padding: 3,
                                    justifyContent: "center",
                                  }}
                                >
                                  <Text style={{ color: "white" }}>
                                    {index + 1}
                                  </Text>
                                </View>
                                <UploadImage
                                  style={{ width: 35, height: 35, padding: 0 }}
                                  imageUrl={data.PhotoURL ? data.PhotoURL : ""}
                                  onImageSelect={handleImageSelection}
                                  kindOfFile="logo"
                                />

                                <TextInput
                                  id={"Name"}
                                  editable={true}
                                  style={styles.TeamModalInput}
                                  placeholder={"Enter Name"}
                                  value={data.Name ? data.Name : ""}
                                  onChangeText={(text) =>
                                    inputPlayerEvent("Name", text, index)
                                  }
                                />
                              </View>
                              <View>
                                {data.Name == "" ? (
                                  <Text
                                    style={{ color: "red", paddingLeft: 40 }}
                                  >
                                    required
                                  </Text>
                                ) : null}
                              </View>
                            </View>

                            <View
                              style={[
                                styles.tableDataCell3,
                                { flexDirection: "column" },
                              ]}
                            >
                              <CustomDateField
                                id={"DOB"}
                                label={undefined}
                                containerStyle={{ marginTop: 0 }}
                                placeholder={undefined}
                                isRequired={undefined}
                                value={
                                  data.DateOfBirth ? data.DateOfBirth : null
                                }
                                onChangeText={(e) => {
                                  const newList = [...playersList];
                                  newList[index].DOB = e.target.value;
                                  setPlayersList(newList);
                                }}
                                onBlur={undefined}
                              />

                              {data.DateOfBirth == "" ? (
                                <Text style={{ color: "red" }}>required</Text>
                              ) : null}
                            </View>

                            <View style={styles.tableDataCell2}>
                              <TextInput
                                id={"JerseyNo"}
                                style={styles.TeamModalInput}
                                placeholder={"J.No"}
                                editable={true}
                                onChangeText={(text) =>
                                  inputPlayerEvent("JerseyNo", text, index)
                                }
                                value={data.JerseyNo ? data.JerseyNo : null}
                              />

                              {data.JerseyNo == "" ? (
                                <Text style={{ color: "red" }}>Required</Text>
                              ) : null}
                            </View>

                            <View style={styles.tableDataCell3}>
                              <TextInput
                                id="ContactNo"
                                style={styles.TeamModalInput}
                                editable={true}
                                placeholder={"Enter mobile no"}
                                onChangeText={(text) =>
                                  inputPlayerEvent("ContactNo", text, index)
                                }
                                maxLength={10}
                                inputMode="numeric"
                                value={data.ContactNo ? data.ContactNo : null}
                              />
                              {(data.ContactNo &&
                                data.ContactNo?.length < 10) ||
                              data.ContactNo?.length > 10 ? (
                                <Text style={{ color: "red" }}>
                                  Invalid Number
                                </Text>
                              ) : null}
                            </View>
                            <Pressable
                              style={styles.scoreSheetDeleteBtn}
                              onPress={() => handleDeletePlayer(index)}
                            >
                              <TrashIcon
                                name="trash-bin-sharp"
                                size={18}
                                color="red"
                              />
                            </Pressable>
                          </View>
                        ))
                      ) : (
                        <Text style={{ color: colors.white }}>
                          No players added yet !
                        </Text>
                      )}
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: 20,
                      marginTop: 15,
                    }}
                  >
                    <Pressable
                      onPress={handleClose}
                      style={[
                        styles.btnPressable,
                        {
                          borderWidth: 1.5,
                          borderColor: colors.white,
                        },
                      ]}
                    >
                      <Text style={styles.btnText}>Cancel</Text>
                    </Pressable>
                    <Button
                      buttonStyle={{
                        backgroundColor: "#12FFE3",
                        borderRadius: 10,
                        borderColor: "transparent",
                        width: 150,
                        height: 30,
                      }}
                      // onPress={() => handleSubmit()}
                      onPress={handleUpdate}
                      titleStyle={{
                        textAlign: "center",
                        fontFamily: "PlusJakartaSansMedium",
                        fontSize: 14,
                        color: colors.royalBlueColour,
                      }}
                      containerStyle={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      title="Submit"
                    />
                  </View>
                </View>
              </View>
            </Modal>
          </TouchableWithoutFeedback>
          {/* modal ended */}

          {successModalVisible == true ? (
            <SuccessAutoExitModal
              visibleModal={successModalVisible}
              SuccessMessage={successModalMesg}
              handleOk={handleOk}
            />
          ) : null}

          {errorModalVisible == true ? (
            <ErrorModal
              visibleModal={errorModalVisible}
              SuccessMessage={errorModalMesg}
              onSubmit={handleCloseErrorModal}
              header={undefined}
            />
          ) : null}

          {deleteModalVisible == true ? (
            <AreYouSureOrange
              visibleModal={deleteModalVisible}
              Message={deleteModalMessage}
              ButtonText={"Delete Player"}
              onCancel={cancelDelete}
              onConfirm={confirmDelete}
            />
          ) : null}

          {/* Loader */}
          {loading && (
            <Modal transparent animationType="none">
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
              >
                <ActivityIndicator size="large" color="#0000ff" />
                <Text style={{ color: colors.white, fontSize: 12 }}>
                  Updating
                </Text>
              </View>
            </Modal>
          )}
        </View>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  //
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    width: Platform.OS === "web" && screenWidth > 760 ? 700 : "95%",
    maxHeight: 0.95 * screenHeight,
    margin: 20,
    backgroundColor: colors.bgColor,
    borderRadius: 5,
    padding: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: "relative",
  },
  //
  scoreTeamLogo: {
    width: Platform.OS === "web" && screenWidth > 760 ? 100 : 60,
    height: Platform.OS === "web" && screenWidth > 760 ? 100 : 60,
    borderRadius: 50,
    resizeMode: "contain",
  },
  teamModalHeadingWrapper2: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 20,
  },
  teamModalHeadingWrapper: {
    flex: 1,
    gap: 5,
  },
  teamModalLabel: {
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 14 : 12,
    fontFamily: "PlusJakartaSansExtraLight",
    color: colors.white,
  },

  //   table
  tableHeaderContainer: {
    backgroundColor: colors.blue,
    display: "flex",
    flexDirection: "row",
    paddingRight: 15,
    marginBottom: 5,
  },
  tableHeaderTitle: {
    flex: 1,
    paddingVertical: 7,
    padding: 5,
  },
  tableHeaderTitle3: {
    flex: 1,
    maxWidth: 140,
    paddingVertical: 7,
    padding: 5,
  },
  tableHeaderJTitle: {
    width: 60,
    paddingVertical: 7,
    padding: 5,
  },
  tableHeaderTitleText: {
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
    color: colors.white,
    textAlign: "center",
  },
  tableDataContainer: {
    backgroundColor: colors.royalBlue,
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
    borderRadius: 5,
  },

  tableDataCell: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  tableDataCell3: {
    flex: 1,
    maxWidth: 140,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  scoreSheetDeleteBtn: {
    width: 30,
    justifyContent: "center",
    alignItems: "center",
  },
  tableDataCell2: {
    width: 60,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  tableDataCellText: {
    textAlign: "center",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
  },
  //   table ended
  btnText: {
    textAlign: "center",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
    color: colors.white,
  },
  btnPressable: {
    width: 150,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  topPlayerCount: {
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 5,
    borderRadius: 5,
    width: Platform.OS === "web" && screenWidth > 760 ? 150 : 110,
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
  mobTeamPlayer: {
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    alignItems: "center",
    borderRadius: 5,
  },
  addTeamBtn: {
    backgroundColor: "#FF5733",
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    justifyContent: "center",
    width: 100,
    borderRadius: 5,
    padding: 4,
  },
  TeamModalInput: {
    borderColor: colors.borderColor,
    color: colors.white,
    backgroundColor: colors.royalBlue,
    borderWidth: 1,
    height: 30,
    width: "100%",
    borderRadius: 5,
    paddingHorizontal: 5,
    fontFamily: "PlusJakartaSansExtraLight",
  },
});
export default TeamModal;
