import React, { useEffect, useState } from "react";
import {
  View,
  Platform,
  ScrollView,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  Linking,
  Modal,
  ActivityIndicator,
} from "react-native";
import { CommonHeading } from "../../../components/reusable/TextComponent";
const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;
import Graph from "react-native-vector-icons/Entypo";
import Play from "react-native-vector-icons/Entypo";
import AdminLayout from "../../../components/include/AdminLayout";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { LinearGradient } from "expo-linear-gradient";
import { DataStore } from "aws-amplify/datastore";
import {
  MatchMaster,
  TeamRegistrations,
  Tournament,
  UserMaster,
} from "../../../models";
import { useNavigation } from "@react-navigation/native";
import {
  convertTo12HourFormat,
  formatDate,
} from "../../../components/FormattedDate";
import { colors } from "../../../components/reusable/colour";
import AppBar from "../../../components/include/AppBar";
import AddPlayerModal from "../Fixture/SubComp/AddPlayerModal";
import { ErrorModal } from "../../../components/reusable/AreYouSure";
import ZoomableImage from "../../../components/reusable/ZoomableImage";

const MyMatches = () => {
  const [userMatches, setUserMatches] = useState([]);
  const [filteredCompletedMatches, setFilteredCompletedMatches] = useState([]);
  const [filteredLiveMatches, setFilteredLiveMatches] = useState([]);
  const [filteredUpcomingMatches, setFilteredUpcomingMatches] = useState([]);
  const [selectedMatchId, setSelectedMatchId] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [completedMatchesFlag, setCompletedMatchesFlag] = useState(false);
  const [liveMatchesFlag, setLiveMatchesFlag] = useState(false);
  const [upcomingMatchesFlag, setUpcomingMatchesFlag] = useState(false);
  const [registrationlist, setRegistrationlist] = useState([]);
  const [SelecteMatchTeam, setSelecteMatchTeam]: any = useState({});
  const [SelectedRegistration, setSelectedRegistration]: any = useState({});
  const [TournamentObj, setTournamentObj]: any = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalMesg, setErrorModalMesg] = useState("");

  const [showCompletedMatchesCards, setShowCompletedMatchesCards] = useState(
    []
  );
  const [showLiveMatchesCards, setShowLiveMatchesCards] = useState([]);
  const [showUpcomingMatchesCards, setShowUpcomingMatchesCards] = useState([]);
  const [activeTab, setActiveTab] = useState("upcoming"); // Default tab is "upcoming"

  const navigation: any = useNavigation();

  const handleFilterChange = (tab: any) => {
    setActiveTab(tab);
  };

  const handleMatchesResultDetailsNavigation = async (
    tournamentId: any,
    matchId: any
  ) => {
    navigation.navigate("TournamentDetails", {
      tournamentId: tournamentId,
      matchId: matchId,
      initialTab: 2,
      showResultDetailsFromMatches: true,
      showResultPage: false,
      matchNavigation: true,
    });
    const matchesPropsObject = {
      tournamentId: tournamentId,
      matchId: matchId,
      initialTab: 2,
      showResultDetailsFromMatches: true,
      showResultPage: false,
      matchNavigation: true,
    };
    console.log("matchesPropsObject", matchesPropsObject);

    await AsyncStorage.setItem(
      "matchesResultDetailsAdmin",
      JSON.stringify(matchesPropsObject)
    );
    if (matchId) {
      console.log("matchId", matchId);
      setSelectedMatchId(matchId);
      // If the same match is clicked again, close the details
      setShowDetails(true);
    } else {
      // Otherwise, open details for the selected match
      setShowDetails(false);
    }
  };

  const getUserMatchesData = async () => {
    const userID = await AsyncStorage.getItem("UserDetail");
    console.log("userID", userID);
    const userMatches = await DataStore.query(MatchMaster, (match) =>
      match.MatchUsers.userMaster.UUID.eq(userID)
    );
    console.log("userMatches", userMatches);
    setUserMatches(userMatches);

    // create match object with team1&team2&tournament data
    const matchObject = await Promise.all(
      userMatches.map(async (element: any) => ({
        ...element,
        team1: await element.Team1,
        team2: await element.Team2,
        tournament: await element.tournamentData,
      }))
    );
    console.log("matchObject", matchObject);

    // Completed matches
    return matchObject;
  };
  const getTeamRegistrationMatches = async () => {
    const userID = await AsyncStorage.getItem("UserDetail");
    const Registrationsall = await DataStore.query(TeamRegistrations, (match) =>
      match.Admins.userMaster.UUID.eq(userID)
    );
    console.log("Registrationsall", Registrationsall);
    setRegistrationlist(Registrationsall);
    const promises = Registrationsall.map(async (ele) => {
      const matchlist = await DataStore.query(MatchMaster, (match) =>
        match.MatchTeams.teamRegistrations.id.eq(ele.id)
      );
      console.log("matchlist", matchlist);
      return matchlist;
    });

    let MatchArr = await Promise.all(promises);

    let MatchListAll = MatchArr.flat().filter(
      (obj) => Object.keys(obj).length !== 0
    );
    console.log("MatchListAll", MatchListAll.length > 0);
    let finalvlaue =
      MatchListAll &&
      MatchListAll.length > 0 &&
      MatchListAll.map(async (element) => ({
        ...element,
        team1: await element.Team1,
        team2: await element.Team2,
        team1flag: await filterTeam(await element.Team1, Registrationsall),
        team2flag: await filterTeam(await element.Team2, Registrationsall),
        tournament: await element.tournamentData,
      }));
    console.log("finalvlaue", finalvlaue);

    let MatchlistwithTeam = finalvlaue;
    console.log("MatchlistwithTeam", MatchlistwithTeam);
    return MatchlistwithTeam;
  };

  const filterTeam = (Team1, teamlist) => {
    console.log("helloTeams", Team1, teamlist);
    let data = teamlist.filter(
      (ele) => ele.TeamName.trim() == Team1.TeamName.trim()
    );
    console.log("filterTeam", data);
    return data.length > 0 ? true : false;
  };

  // const handleFetchData = async () => {
  //   let Data;
  //   setLoading(true);

  //   Data = await getTeamRegistrationMatches();
  //   console.log("Data", Data);
  //   if (Data && Data.length == 0) {
  //     Data = await getUserMatchesData();
  //   }

  //   // Completed matches
  //   if (Data && Data.length > 0) {
  //     const filteredCompletedMatches = Data
  //       ? Data.filter((matchData: any) => matchData.Status == "COMPLETED")
  //       : [];
  //     console.log("filteredCompletedMatches", filteredCompletedMatches);
  //     setFilteredCompletedMatches(filteredCompletedMatches);
  //     if (filteredCompletedMatches.length > 0) {
  //       console.log(
  //         "filteredCompletedMatches",
  //         filteredCompletedMatches.length
  //       );
  //       setCompletedMatchesFlag(true);
  //     } else {
  //       setCompletedMatchesFlag(false);
  //     }
  //   } else {
  //     console.error("matchesObjData is undefined or empty");
  //     setLoading(false);
  //   }

  //   // Live matches or InProgress matches
  //   if (Data && Data.length > 0) {
  //     const filteredLiveMatches = Data
  //       ? Data.filter((matchData: any) => matchData.Status === "INPROGRESS")
  //       : [];
  //     console.log("filteredLiveMatches", filteredLiveMatches);
  //     setFilteredLiveMatches(filteredLiveMatches);
  //     if (filteredLiveMatches.length > 0) {
  //       console.log("filteredLiveMatches", filteredLiveMatches.length);
  //       setLiveMatchesFlag(true);
  //     } else {
  //       setLiveMatchesFlag(false);
  //     }
  //   } else {
  //     console.error("matchesObjData is undefined or empty");
  //     setLoading(false);
  //   }

  //   // Upcoming or SCHEDULED matches
  //   if (Data && Data.length > 0) {
  //     const filteredUpcomingMatches = Data
  //       ? Data.filter(
  //           (matchData: any) =>
  //             matchData.Status === "SCHEDULED" || matchData.Status === "CREATED"
  //         )
  //       : [];
  //     console.log("filteredUpcomingMatches", filteredUpcomingMatches);
  //     setFilteredUpcomingMatches(filteredUpcomingMatches);
  //     if (filteredUpcomingMatches.length > 0) {
  //       console.log("filteredUpcomingMatches", filteredUpcomingMatches.length);
  //       setUpcomingMatchesFlag(true);
  //     } else {
  //       setUpcomingMatchesFlag(false);
  //     }
  //   } else {
  //     console.error("matchesObjData is undefined or empty");
  //   }
  //   setLoading(false);
  // };
  const handleFetchData = async () => {
    let Data;
    setLoading(true);
    let timeoutReached = false;

    // Set a timeout to stop the loader after 20 seconds
    const timeout = setTimeout(() => {
      timeoutReached = true;
      setLoading(false);
      console.error("No data available after 20 seconds.");
      alert("No data available.");
    }, 20000); // 20 seconds

    try {
      Data = await getTeamRegistrationMatches();
      console.log("Data", Data);
      if (Data && Data.length == 0) {
        Data = await getUserMatchesData();
      }

      // Completed matches
      if (Data && Data.length > 0) {
        const filteredCompletedMatches = Data
          ? Data.filter((matchData: any) => matchData.Status == "COMPLETED")
          : [];
        console.log("filteredCompletedMatches", filteredCompletedMatches);
        setFilteredCompletedMatches(filteredCompletedMatches);
        if (filteredCompletedMatches.length > 0) {
          console.log(
            "filteredCompletedMatches",
            filteredCompletedMatches.length
          );
          setCompletedMatchesFlag(true);
        } else {
          setCompletedMatchesFlag(false);
        }
      } else {
        console.error("matchesObjData is undefined or empty");
        setLoading(false);
      }

      // Live matches or InProgress matches
      if (Data && Data.length > 0) {
        const filteredLiveMatches = Data
          ? Data.filter((matchData: any) => matchData.Status === "INPROGRESS")
          : [];
        console.log("filteredLiveMatches", filteredLiveMatches);
        setFilteredLiveMatches(filteredLiveMatches);
        if (filteredLiveMatches.length > 0) {
          console.log("filteredLiveMatches", filteredLiveMatches.length);
          setLiveMatchesFlag(true);
        } else {
          setLiveMatchesFlag(false);
        }
      } else {
        console.error("matchesObjData is undefined or empty");
        setLoading(false);
      }

      // Upcoming or SCHEDULED matches
      if (Data && Data.length > 0) {
        const filteredUpcomingMatches = Data
          ? Data.filter(
              (matchData: any) =>
                matchData.Status === "SCHEDULED" ||
                matchData.Status === "CREATED"
            )
          : [];
        console.log("filteredUpcomingMatches", filteredUpcomingMatches);
        setFilteredUpcomingMatches(filteredUpcomingMatches);
        if (filteredUpcomingMatches.length > 0) {
          console.log(
            "filteredUpcomingMatches",
            filteredUpcomingMatches.length
          );
          setUpcomingMatchesFlag(true);
        } else {
          setUpcomingMatchesFlag(false);
        }
      } else {
        console.error("matchesObjData is undefined or empty");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    } finally {
      if (!timeoutReached) {
        clearTimeout(timeout); // Clear the timeout if data is processed
      }
    }
  };

  useEffect(() => {
    handleFetchData();
  }, [completedMatchesFlag, liveMatchesFlag, upcomingMatchesFlag]);

  const handleBack = () => {
    navigation.goBack();
  };

  const handleOnClickWatchMatch = async (LiveURL: string) => {
    if (LiveURL) {
      await Linking.openURL(LiveURL);
    } else {
      setErrorModalVisible(true);
      setErrorModalMesg("No Live URL is provided ");

      // alert("No Live URL is provided ");
    }
  };

  const handleTeamSubmit = async (objectdata) => {
    console.log("object", objectdata, registrationlist);
    // let matchingTeamsObject: any = {};
    let MatchTeamObj: any = objectdata;

    let matchingTeamsObject = registrationlist.find(
      (ele) => ele.TeamName.trim() == objectdata.TeamName.trim()
    );

    console.log("matchingTeamsObject", matchingTeamsObject);
    let ArrayofPlayers = [];
    let playermasterdata = [];
    let playersDataPromises: any = await [matchingTeamsObject.Players];
    let playersData = await playersDataPromises[0].values;
    playersData.map(async (obj) => {
      let MasterPlayer = await obj.playerMaster;
      playermasterdata.push(MasterPlayer);
      let Playerdata = await MasterPlayer.PlayerData;
      if (Playerdata !== undefined) {
        ArrayofPlayers.push(Playerdata);
      }
    });
    let Teamobj = {
      Adminlist: [],
      Coachdata: {},
      ID: matchingTeamsObject.id,
      Logo: matchingTeamsObject.Logo,
      Playerdata: ArrayofPlayers,
      TeamName: matchingTeamsObject.TeamName,
      Tournament: matchingTeamsObject.Tournament,
      playermasterList: playermasterdata,
    };
    let Tournamentobj = await DataStore.query(
      Tournament,
      matchingTeamsObject.Tournament
    );

    console.log("Teamobj", Tournamentobj, Teamobj);
    setSelectedRegistration(Teamobj);
    setSelecteMatchTeam(MatchTeamObj);
    setTournamentObj(Tournamentobj);
    setModalVisible(true);
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  const handleSubmit = () => {
    setErrorModalVisible(false);
  };

  return (
    <AdminLayout>
      {Platform.OS !== "web" && (
        <AppBar
          title={"My Matches"}
          showBackButton={true}
          onBackPress={handleBack}
        />
      )}
      <ScrollView style={{ padding: 5 }}>
        <View>
          {Platform.OS === "web" && <CommonHeading props={"My Matches"} />}

          <View style={styles.filterContainer}>
            <Pressable
              style={[
                styles.filterButton,
                {
                  backgroundColor:
                    activeTab === "upcoming" ? "#ff5733" : "#ababab",
                },
              ]}
              onPress={() => handleFilterChange("upcoming")}
            >
              <Text style={styles.filterBtnText}>Upcoming</Text>
            </Pressable>
            <Pressable
              style={[
                styles.filterButton,
                {
                  backgroundColor: activeTab === "live" ? "#ff5733" : "#ababab",
                },
              ]}
              onPress={() => handleFilterChange("live")}
            >
              <Text style={styles.filterBtnText}>Live</Text>
            </Pressable>
            <Pressable
              style={[
                styles.filterButton,
                {
                  backgroundColor:
                    activeTab === "completed" ? "#ff5733" : "#ababab",
                },
              ]}
              onPress={() => handleFilterChange("completed")}
            >
              <Text style={styles.filterBtnText}>Completed</Text>
            </Pressable>
          </View>

          <View style={styles.dashboardMatchContainer}>
            {/* Live matches */}
            {activeTab === "live" ? (
              filteredLiveMatches && filteredLiveMatches.length > 0 ? (
                filteredLiveMatches.map((match, index) => (
                  <LinearGradient
                    style={styles.matchCard}
                    start={{ x: 0, y: 1 }}
                    end={{ x: 1, y: 1 }}
                    colors={[colors.orange, colors.royalBlue]}
                    key={index}
                  >
                    {/* <View style={styles.matchCardColor} key={index}> */}
                    <Text style={styles.matchCardHeadingWhite}>
                      {match.tournament ? match.tournament.Name : null}
                    </Text>
                    <View style={styles.matchDetailsWrapper}>
                      <View style={styles.matchTeamNAmeLogo}>
                        <View style={styles.matchClubLogo}>
                          <ZoomableImage
                            imageSource={{
                              uri: match.team1
                                ? match.team1.LogoURL
                                : require("../../../../assets/home/logo.png"),
                            }}
                            imageStyle={styles.matchClubLogo}
                            modalStyle={undefined}
                          />
                        </View>
                        <Text style={styles.matchCardDateTextWhite}>
                          {match.team1 ? match.team1.TeamName : null}
                        </Text>
                      </View>
                      <View style={styles.matchScoreBadge}>
                        <Text style={[styles.matchScoreTextWhite]}>
                          {`${
                            match.team1
                              ? match.team1.Score
                                ? match.team1.Score.FirstHalf +
                                  match.team1.Score.SecondHalf +
                                  match.team1.Score.ExtraTime
                                : "0"
                              : "0"
                          }-${
                            match.team2
                              ? match.team2.Score
                                ? match.team2.Score.FirstHalf +
                                  match.team2.Score.SecondHalf +
                                  match.team2.Score.ExtraTime
                                : "0"
                              : "0"
                          }`}
                        </Text>
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 3,
                          }}
                        >
                          <View
                            style={{
                              width: 6,
                              height: 6,
                              borderRadius: 3,
                              backgroundColor: "red",
                            }}
                          ></View>
                          <Text
                            style={{
                              color: "#fff",
                              fontFamily: "PlusJakartaSansMedium",
                            }}
                          >
                            Live
                          </Text>
                        </View>
                      </View>
                      <View style={styles.matchTeamNAmeLogo}>
                        <View style={styles.matchClubLogo}>
                          <ZoomableImage
                            imageSource={{
                              uri: match.team2
                                ? match.team2.LogoURL
                                : require("../../../../assets/home/logo.png"),
                            }}
                            imageStyle={styles.matchClubLogo}
                            modalStyle={undefined}
                          />
                        </View>
                        <Text style={styles.matchCardDateTextWhite}>
                          {match.team2 ? match.team2.TeamName : null}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.matchCardDateWrapper}>
                      <Text style={styles.matchCardDateTextWhite}>
                        {match && match.Date ? formatDate(match.Date) : null}
                      </Text>
                      <Text style={styles.matchCardDateTextWhite}>
                        {match && match.Time
                          ? convertTo12HourFormat(match.Time)
                          : null}
                      </Text>
                      <Text style={styles.matchCardDateTextWhite}>
                        {match && match.Venue ? match.Venue.Name : null}
                      </Text>
                    </View>
                    <View
                      style={[styles.horizontalLine, { marginVertical: 10 }]}
                    />
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Pressable
                        style={styles.matchCardRow}
                        onPress={() => {
                          // handleSelectMatch(match.id);
                          handleMatchesResultDetailsNavigation(
                            match.tournament.id,
                            match.id
                          );
                        }}
                      >
                        <Graph name="bar-graph" size={16} color="#fff" />
                        <Text style={styles.matchCardBtnText}>
                          View Details
                        </Text>
                      </Pressable>
                      <Pressable
                        style={styles.matchCardRow}
                        onPress={() => {
                          handleOnClickWatchMatch(
                            match.LiveURL ? match.LiveURL : ""
                          );
                        }}
                      >
                        <Play name="controller-play" size={16} color="#fff" />
                        <Text style={styles.matchCardBtnText}>Watch Match</Text>
                      </Pressable>
                    </View>
                    {/* </View> */}
                  </LinearGradient>
                ))
              ) : (
                <View style={{ alignItems: "center", width: "100%" }}>
                  <Image
                    source={require("../../../../assets/noData/stats.png")}
                    style={styles.noDataImage}
                    resizeMode="contain"
                  />
                  <Text style={styles.noDataText}>
                    There is No Live Match for you.
                  </Text>
                </View>
              )
            ) : null}

            {/* Upcoming matches */}
            {activeTab === "upcoming" ? (
              filteredUpcomingMatches && filteredUpcomingMatches.length > 0 ? (
                filteredUpcomingMatches.map((match, index) => (
                  <View style={styles.matchCard} key={index}>
                    <Text style={styles.matchCardHeading}>
                      {match.tournament ? match.tournament.Name : null}
                    </Text>
                    <View style={styles.matchDetailsWrapper}>
                      <View style={styles.matchTeamNAmeLogo}>
                        <View style={styles.matchClubLogo}>
                          <ZoomableImage
                            imageSource={{
                              uri: match.team1
                                ? match.team1.LogoURL
                                : require("../../../../assets/home/logo.png"),
                            }}
                            imageStyle={styles.matchClubLogo}
                            modalStyle={undefined}
                          />
                        </View>
                        <Text style={styles.matchCardDateText}>
                          {match.team1 ? match.team1.TeamName : null}
                        </Text>
                        {match.team1flag == true &&
                        match.team1.TeamSubmitted == false ? (
                          <Pressable
                            style={[
                              styles.matchCardRow,
                              {
                                backgroundColor: colors.orange,
                                paddingVertical: 3,
                                paddingHorizontal: 5,
                                borderRadius: 3,
                              },
                            ]}
                            onPress={() => handleTeamSubmit(match.team1)}
                          >
                            <Text
                              style={[
                                styles.matchCardBtnTextColor,
                                { fontFamily: "PlusJakartaSansExtraLight" },
                              ]}
                            >
                              Submit Team
                            </Text>
                          </Pressable>
                        ) : null}
                      </View>
                      <View style={styles.matchScoreBadge}>
                        <Text style={styles.matchCardVsText}>VS</Text>
                      </View>
                      <View style={styles.matchTeamNAmeLogo}>
                        <View style={styles.matchClubLogo}>
                          <ZoomableImage
                            imageSource={{
                              uri: match.team2
                                ? match.team2.LogoURL
                                : require("../../../../assets/home/logo.png"),
                            }}
                            imageStyle={styles.matchClubLogo}
                            modalStyle={undefined}
                          />
                        </View>
                        <Text style={styles.matchCardDateText}>
                          {match.team2 ? match.team2.TeamName : null}
                        </Text>
                        {match.team2flag == true &&
                        match.team2.TeamSubmitted == false ? (
                          <Pressable
                            style={[
                              styles.matchCardRow,
                              {
                                backgroundColor: colors.orange,
                                paddingVertical: 3,
                                paddingHorizontal: 5,
                                borderRadius: 3,
                              },
                            ]}
                            onPress={() => handleTeamSubmit(match.team2)}
                          >
                            <Text
                              style={[
                                styles.matchCardBtnTextColor,
                                { fontFamily: "PlusJakartaSansExtraLight" },
                              ]}
                            >
                              Submit Team
                            </Text>
                          </Pressable>
                        ) : null}
                      </View>
                    </View>
                    <View style={styles.matchCardDateWrapper}>
                      <Text style={styles.matchCardDateText}>
                        {match && match.Date ? formatDate(match.Date) : null}
                      </Text>
                      <Text style={styles.matchCardDateText}>
                        {match && match.Time
                          ? convertTo12HourFormat(match.Time)
                          : null}
                      </Text>
                      <Text style={styles.matchCardDateText}>
                        {match && match.Venue ? match.Venue.Name : null}
                      </Text>
                    </View>
                    <View
                      style={[styles.horizontalLine, { marginVertical: 10 }]}
                    />
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Pressable
                        style={styles.matchCardRow}
                        onPress={() => {
                          // handleSelectMatch(match.id);
                          handleMatchesResultDetailsNavigation(
                            match.tournament.id,
                            match.id
                          );
                        }}
                      >
                        <Graph
                          name="bar-graph"
                          size={16}
                          color={colors.white}
                        />
                        <Text style={styles.matchCardBtnTextColor}>
                          View Details
                        </Text>
                      </Pressable>

                      <Pressable
                        style={styles.matchCardRow}
                        onPress={() => {
                          handleOnClickWatchMatch(
                            match.LiveURL ? match.LiveURL : ""
                          );
                        }}
                      >
                        <Play
                          name="controller-play"
                          size={16}
                          color={colors.white}
                        />
                        <Text style={styles.matchCardBtnTextColor}>
                          Watch Match
                        </Text>
                      </Pressable>
                    </View>
                  </View>
                ))
              ) : (
                <View style={{ alignItems: "center", width: "100%" }}>
                  <Image
                    source={require("../../../../assets/noData/stats.png")}
                    style={styles.noDataImage}
                    resizeMode="contain"
                  />
                  <Text style={styles.noDataText}>
                    There is not any Upcoming Match schedule for you.
                  </Text>
                </View>
              )
            ) : null}

            {/* completed matches */}
            {activeTab === "completed" ? (
              filteredCompletedMatches &&
              filteredCompletedMatches.length > 0 ? (
                filteredCompletedMatches.map((match, index) => (
                  <View style={styles.matchCard} key={index}>
                    <Text style={styles.matchCardHeading}>
                      {match.tournament ? match.tournament.Name : null}
                    </Text>
                    <View style={styles.matchDetailsWrapper}>
                      <View style={styles.matchTeamNAmeLogo}>
                        <View style={styles.matchClubLogo}>
                          <ZoomableImage
                            imageSource={{
                              uri: match.team1
                                ? match.team1.LogoURL
                                : require("../../../../assets/home/logo.png"),
                            }}
                            imageStyle={styles.matchClubLogo}
                            modalStyle={undefined}
                          />
                        </View>
                        <Text style={styles.matchCardDateText}>
                          {match.team1 ? match.team1.TeamName : null}
                        </Text>
                      </View>
                      <View style={styles.matchScoreBadge}>
                        <Text style={[styles.matchScoreTextWhite]}>
                          {`${
                            match.team1
                              ? match.team1.Score
                                ? match.team1.Score.FirstHalf +
                                  match.team1.Score.SecondHalf +
                                  match.team1.Score.ExtraTime
                                : "0"
                              : "0"
                          }-${
                            match.team2
                              ? match.team2.Score
                                ? match.team2.Score.FirstHalf +
                                  match.team2.Score.SecondHalf +
                                  match.team2.Score.ExtraTime
                                : "0"
                              : "0"
                          }`}
                        </Text>
                      </View>
                      <View style={styles.matchTeamNAmeLogo}>
                        <View style={styles.matchClubLogo}>
                          <ZoomableImage
                            imageSource={{
                              uri: match.team2
                                ? match.team2.LogoURL
                                : require("../../../../assets/home/logo.png"),
                            }}
                            imageStyle={styles.matchClubLogo}
                            modalStyle={undefined}
                          />
                        </View>
                        <Text style={styles.matchCardDateText}>
                          {match.team2 ? match.team2.TeamName : null}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.matchCardDateWrapper}>
                      <Text style={styles.matchCardDateText}>
                        {match && match.Date ? formatDate(match.Date) : null}
                      </Text>
                      <Text style={styles.matchCardDateText}>
                        {match && match.Time
                          ? convertTo12HourFormat(match.Time)
                          : null}
                      </Text>
                      <Text style={styles.matchCardDateText}>
                        {match && match.Venue ? match.Venue.Name : null}
                      </Text>
                    </View>
                    <View
                      style={[styles.horizontalLine, { marginVertical: 10 }]}
                    />
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Pressable
                        style={styles.matchCardRow}
                        onPress={() => {
                          // handleSelectMatch(match.id);
                          handleMatchesResultDetailsNavigation(
                            match.tournament.id,
                            match.id
                          );
                        }}
                      >
                        <Graph
                          name="bar-graph"
                          size={16}
                          color={colors.white}
                        />
                        <Text style={styles.matchCardBtnTextColor}>
                          View Details
                        </Text>
                      </Pressable>

                      <Pressable
                        style={styles.matchCardRow}
                        onPress={() => {
                          handleOnClickWatchMatch(
                            match.LiveURL ? match.LiveURL : ""
                          );
                        }}
                      >
                        <Play
                          name="controller-play"
                          size={16}
                          color={colors.white}
                        />
                        <Text style={styles.matchCardBtnTextColor}>
                          Watch Match
                        </Text>
                      </Pressable>
                    </View>
                  </View>
                ))
              ) : (
                <View style={{ alignItems: "center", width: "100%" }}>
                  <Image
                    source={require("../../../../assets/noData/stats.png")}
                    style={styles.noDataImage}
                    resizeMode="contain"
                  />
                  <Text style={styles.noDataText}>
                    You have not completed any match.
                  </Text>
                </View>
              )
            ) : null}
          </View>
        </View>
        {errorModalVisible == true ? (
          <ErrorModal
            visibleModal={errorModalVisible}
            SuccessMessage={errorModalMesg}
            onSubmit={handleSubmit}
            header={"Attention"}
          />
        ) : null}
        {modalVisible == true ? (
          <AddPlayerModal
            SelectedTeam={SelecteMatchTeam}
            TeamDetails={SelectedRegistration}
            Tournamentdata={TournamentObj}
            visibleModal={modalVisible}
            Roundobj={undefined}
            MatchTeamMasterPlayerUpdate={undefined}
            handleFetchData={handleFetchData}
            handleClose={handleClose}
          />
        ) : null}
      </ScrollView>
      {loading && (
        <Modal transparent animationType="none">
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <ActivityIndicator size="large" color="#0000ff" />
            <Text style={{ color: "white" }}>Loading</Text>
          </View>
        </Modal>
      )}
    </AdminLayout>
  );
};

const styles = StyleSheet.create({
  DashboardMainContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  DashboardContentContainer: {
    flex: 1,
    padding: 20,
    backgroundColor: "#FAFAFA",
    paddingTop: 70,
    position: "relative",
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 220 : 20,
    minHeight: screenHeight,
  },
  //
  dashboardMatchContainer: {
    width: "100%",
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
    gap: 25,
    flexWrap: "wrap",
    paddingBottom: 20,
  },
  matchCard: {
    backgroundColor: colors.royalBlue,
    padding: 15,
    flex: 1,
    maxWidth: 400,
    minWidth: 300,
    borderRadius: 10,
    shadowOffset: {
      width: 3,
      height: 4,
    },
    shadowColor: "#000000",
    shadowOpacity: 0.22,
    shadowRadius: 10,
    elevation: 3,
  },
  matchCardColor: {
    backgroundColor: "#02205F",
    padding: 15,
    flex: 1,
    maxWidth: 400,
    minWidth: 300,
    borderRadius: 10,
    shadowOffset: {
      width: 3,
      height: 4,
    },
    shadowColor: "#000000",
    shadowOpacity: 0.22,
    shadowRadius: 10,
    elevation: 3,
  },
  matchCardHeading: {
    textAlign: "center",
    fontSize: 16,
    marginBottom: 15,
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
  },
  matchCardHeadingWhite: {
    color: colors.white,
    textAlign: "center",
    fontSize: 16,
    marginBottom: 15,
    fontFamily: "PlusJakartaSansSemiBold",
  },
  matchClubLogo: {
    width: 60,
    height: 60,
    borderRadius: 60,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  matchDetailsWrapper: {
    flexDirection: "row",
    gap: 10,
    justifyContent: "space-between",
  },
  matchTeamNAmeLogo: {
    alignItems: "center",
    gap: 3,
    flex: 1,
  },
  matchCardDateText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    textAlign: "center",
  },
  matchCardDateTextWhite: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    textAlign: "center",
  },
  matchScoreBadge: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 85,
    gap: 5,
  },
  matchCardVsText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansBold",
    fontSize: 22,
  },
  matchCardDateWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 5,
    marginTop: 15,
  },
  horizontalLine: {
    width: "100%",
    height: 1,
    backgroundColor: "#dbd6d6",
  },
  matchCardRow: {
    flexDirection: "row",
    gap: 10,
  },
  matchCardBtnTextColor: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
  },
  matchScoreTextWhite: {
    fontSize: 20,
    fontWeight: "600",
    color: colors.white,
  },
  matchCardBtnText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
  },
  filterContainer: {
    marginTop: 20,
    flexDirection: "row",
    gap: 15,
  },
  filterButton: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 6,
  },
  filterBtnText: {
    fontSize: 14,
    fontFamily: "PlusJakartaSansMedium",
    color: "#fff",
  },
  noDataImage: {
    width: 270,
    height: 270,
  },
  noDataText: {
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
    fontSize: 20,
    maxWidth: 400,
    textAlign: "center",
  },
});

export default MyMatches;
