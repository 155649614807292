import React, { useEffect, useState } from "react";

import {
  View,
  Platform,
  ScrollView,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  TextInput,

} from "react-native";
import { Card, Avatar } from 'react-native-elements';
import { CommonHeading } from "../../../components/reusable/TextComponent";
const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;
import * as ImagePicker from "expo-image-picker";
import { useNavigation, useRoute } from "@react-navigation/native";
import { colors } from "../../../components/reusable/colour";
import CameraIcon from "react-native-vector-icons/MaterialCommunityIcons";
import AdminLayout from "../../../components/include/AdminLayout";
import MyTeams from "../Teams/MyTeams";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { DataStore } from "aws-amplify/datastore";
import { uploadData } from "aws-amplify/storage";
import { PlayerAwards, StatisticsMaster, UserMaster } from "../../../models";
import { Formik, FormikHelpers, FormikValues } from "formik";
import * as Yup from "yup";
import AppBar from "../../../components/include/AppBar";
import UploadImage from "../../../components/reusable/UploadImage";
import awsmobile from "../../../aws-exports";
import { ErrorModal } from "../../../components/reusable/AreYouSure";
import { SuccessAutoExitModal } from "../../../components/reusable/AutoExitModal";
import { getStatisticsMaster } from "../../../graphql/queries";
import { PlayerMaster } from "../../../models";
// import CropImagePicker from "../../../components/reusable/CropImagePicker";



const ImageViewer = ({ placeholderImageSource, selectedImage }) => {
  const imageSource = selectedImage
    ? { uri: selectedImage }
    : placeholderImageSource;

  return <Image source={imageSource} style={styles.image} />;
};

const MyProfile = () => {
  const [selectedImage, setSelectedImage]: any = useState({});
  const bucketName = awsmobile.aws_user_files_s3_bucket;
  console.log("Bucket :", bucketName);

  const [UserDteaildata, setUserDteaildata]: any = useState();
  const [SuccessModalVisible, setSuccessModalVisible] = useState(false);
  const [SunccessModalMesg, setSunccessModalMesg] = useState("");

  const [TotalParticipateTournament, seTotalParticipateTournament]: any =
    useState("");
  const [TotalRegistrationTeam, seTotalRegistrationTeam]: any = useState("");
  const [TotalTeams, setTotalTeams]: any = useState("");
  const [playerAwards, setPlayerAwards]: any = useState([]); // Use an object if it's not an array
  const [staticPlayerAwards, setStaticPlayerAwards]: any = useState([]); // Use an object if it's not an array
  const [staticCoachAwards, setStaticCoachAwards]: any = useState([]); // Use an object if it's not an array
  const [playerStatistics, setPlayerStatistics]: any = useState([]);
  const [coachAwards, setcoachAwards]: any = useState([]); // Use an object if it's not an array
  const [errorMessage, setErrorMessage] = useState(""); // Error message state
  const [games, setGames] = useState(""); // Error message state
  const [won, setWon] = useState(""); // Error message state
  const [lost, setLost] = useState(""); // Error message state
  const [drawn, setDrawn] = useState(""); // Error message state
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalMesg, setErrorModalMesg] = useState("");
  const [inputText, setinputText]: any = useState({
    FirstName: "",
    LastName: "",
    MobileNo: "",
    EmailAddress: "",
    City: "",
    State: "",
    PinCode: "",
    Country: "",
    logo: "",
  });

  // This is code for UserAwards

  let userDetails: any;
  const bestDefenderImg = require('../../../../assets/AwardsImg/defender.png');
  const bestForwardImg = require('../../../../assets/AwardsImg/bestforwords.png');
  const bestGoalkeeperImg = require('../../../../assets/AwardsImg/goalkeeper.png');
  const bestMidfielderImg = require('../../../../assets/AwardsImg/midfielder.png');
  const playerOfTheTournamentImg = require('../../../../assets/AwardsImg/playerofthetournament.png');

  const awardImage = {
    BestDefender: bestDefenderImg,
    BestForward: bestForwardImg,
    BestGoalkeeper: bestGoalkeeperImg,
    BestMidfielder: bestMidfielderImg,
    PlayerOfTheTournament: playerOfTheTournamentImg,
  };

  const coachawardImage = {
    Winner: bestDefenderImg,
    RunnerUp: bestForwardImg,
    ThirdPlace: bestGoalkeeperImg,
    SemiFinalist: bestMidfielderImg,
    FairPlay: playerOfTheTournamentImg,
  };

  // Function to format award names
  const formatAwardName = (awardName) => {
    return awardName
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  };

  const getUserdata = async (UserDetails) => {
    console.log("UserDetails", UserDetails);
    const UserObject = await DataStore.query(UserMaster, UserDetails);

    console.log("UserObject", UserObject);

    const wordsArray = UserObject.Name.split(/\s+/);
    console.log("wordsArray", wordsArray);
    // Get the number of words
    setSelectedImage({
      URL: UserObject.PhotoURL,
    });
    setUserDteaildata(UserObject);
    setinputText({
      FirstName: wordsArray[0],
      LastName: wordsArray.length > 1 ? wordsArray[1] : "",
      MobileNo: UserObject.ContactNo.toString(),
      EmailAddress: UserObject.EmailId,
      City: UserObject.City,
      State: UserObject.State,
      PinCode: UserObject.PinCode,
      Country: UserObject.Country,
      logo: UserObject.PhotoURL,
    });


    let tournamentdata: any = await [UserObject.UserTournaments];
    let TournamentData = await tournamentdata[0].values;

    let TeamReg: any = await [UserObject.TeamRegistrations];
    let TeamRegPromise = await TeamReg[0].values;

    let Teamsdata: any = await [UserObject.UserTeams];
    let Teamspromisedata = await Teamsdata[0].values;


    console.log("getUserdatainProfilepage", TournamentData, Teamspromisedata);
    seTotalRegistrationTeam(TeamRegPromise.length);
    seTotalParticipateTournament(TournamentData.length);
    setTotalTeams(Teamspromisedata.length);

    // Define your static PlayerAwards with default values
    const staticPlayerAwards = {
      PlayerOfTheTournament: { Count: 0, Tournaments: ['null'] },
      BestForward: { Count: 0, Tournaments: ['null'] },
      BestGoalkeeper: { Count: 0, Tournaments: ['null'] },
      BestMidfielder: { Count: 0, Tournaments: ['null'] },
      BestDefender: { Count: 0, Tournaments: ['null'] },
    };

    // Map the awards from PlayerSum.Awards or use default values if not present

    const awardsToDisplay = Object.keys(staticPlayerAwards).map(awardName => ({
      awardName,
      count: staticPlayerAwards[awardName].Count,
      tournaments: staticPlayerAwards[awardName].Tournaments,
    }));

    setStaticPlayerAwards(awardsToDisplay);

    // Default static Coach Awards

    const staticCoachAwards = {
      Winner: { Count: 0, Tournaments: ['null'] },
      RunnerUp: { Count: 0, Tournaments: ['null'] },
      ThirdPlace: { Count: 0, Tournaments: ['null'] },
      SemiFinalist: { Count: 0, Tournaments: ['null'] },
      FairPlay: { Count: 0, Tournaments: ['null'] },
    };

    // Map the awards from PlayerSum.Awards or use default values if not present

    const awardsDisplay = Object.keys(staticCoachAwards).map(awardName => ({
      awardName,
      count: staticCoachAwards[awardName].Count,
      tournaments: staticCoachAwards[awardName].Tournaments,
    }));

    setStaticCoachAwards(awardsDisplay);


    // This is PLayersummary Data 
    try {
      let PlayerSum = await UserObject.PlayerSummary;
      console.log("Player Summary Data:", PlayerSum); // Log entire object

      if (PlayerSum && PlayerSum.Awards) {
        console.log("Pawan PlayerSummary Awards:", PlayerSum.Awards); // Log Awards data

        // Flatten the object values if it's an object with nested arrays/objects
        const flatArray = Object.entries(PlayerSum.Awards).map(([awardName, awardData]) => ({
          awardName, // Award name
          count: awardData.Count, // No. of awards
          tournaments: awardData.Tournaments // Tournaments array
        }));

        console.log("Stored PlayerSummary inside FlatArray ", flatArray);

        // Set the flattened array in the state
        setPlayerAwards(flatArray); // Store the flattened array in the state

      } else {
        console.error("Awards data is not available in PlayerSum");
        setPlayerAwards([]); // Set empty array or handle the error case
        // setErrorMessage("Player summary data not available."); // Set error message
      }
    } catch (error) {
      console.error("Error fetching PlayerSummary:", error);
      setPlayerAwards([]); // Handle error by setting empty awards
      // setErrorMessage("Error fetching player summary data."); // Set error message for error case
    }

    // Player Statistics Data
    // get PlayerStatistics Data Goals, Games, RedCards, YellowCards


    try {
      // Fetch PlayerSummary
      let PlayerSum = await UserObject.PlayerSummary;
      console.log("Player Summary Data:", PlayerSum); // Log entire object

      // Access PlayerStatistics
      const playerStatistics: any = await PlayerSum?.PlayerStatistics.toArray(); // Use optional chaining

      console.log("Flattened Player Statistics Array:", playerStatistics);
      const promiseData = await Promise.all(playerStatistics);
      console.log("Promise Player Statistics Array:", promiseData);
      // Check if playerStatistics is defined and is an array

      // Map through the playerStatistics
      const statArray = Object.entries(promiseData).map(([key, data]) => ({
        Goals: data.Goals || 0,            // Extract Goals
        Games: data.Games || 0,            // Extract Games
        RedCards: data.RedCards || 0,      // Extract RedCards
        YellowCards: data.YellowCards || 0  // Extract YellowCards
      }));

      console.log("Flattened Player Statistics:", statArray);
      // Here you can set the state or use the statArray as needed
      setPlayerStatistics(statArray);

    } catch (error) {
      console.error("Error fetching PlayerSummary or PlayerStatistics:", error);
      // Handle error appropriately
    }













    // This is CouchSummery data

    try {
      let coachData = await UserObject.CoachSummary;
      console.log("Pawan CoachSummary data ", coachData.Awards);
      if (coachData && coachData.Awards) {
        console.log("Pawan CoachSummary Awards:", coachData.Awards); // Log Awards data

        // Flatten the object values if it's an object with nested arrays/objects
        const CoachArray = Object.entries(coachData.Awards).map(([awardName, awardData]) => ({
          awardName, // Award name
          count: awardData.Count, // No. of awards
          tournaments: awardData.Tournaments // Tournaments array
        }));

        console.log("Stored CoachSummary inside FlatArray ", CoachArray);

        // Set the flattened array in the state
        setcoachAwards(CoachArray); // Store the flattened array in the state

      } else {
        console.error("Awards data is not available");
        setcoachAwards([]); // Set empty array or handle the error case
        // setErrorMessage("Player summary data not available."); // Set error message
      }
    } catch (error) {
      console.error("error fetching CoachSummary Data");
    }


    let Games: any = (await UserObject.CoachSummary).Games ?? 0;  // Use nullish coalescing operator
    console.log("Games ", Games);
    setGames(Games);

    let Won: any = (await UserObject.CoachSummary).Won ?? 0;
    console.log("Won games", Won);
    setWon(Won);

    let Lost: any = (await UserObject.CoachSummary).Lost ?? 0;
    console.log("Lost Game ", Lost);
    setLost(Lost);  // Fixed the variable name from 'Won' to 'Lost'

    let Drawn: any = (await UserObject.CoachSummary).Drawn ?? 0;
    console.log("Drawn Game", Drawn);
    setDrawn(Drawn);  // Fixed the variable name from 'Won' to 'Drawn'


  };


  const inputEvent = (name, value) => {
    console.log("hellotext", name, value);
    setinputText((lastValue) => {
      return {
        ...lastValue,
        [name]: value,
      };
    });
  };


  const uploadLogoFileToStorage = async (folderName, key) => {
    // const contentType = key.split(";")[0].split(":")[1];
    console.log("folder name", folderName, "FileName", key.FileName);

    try {
      const response = await fetch(key.URL); //
      const blob = await response.blob();
      let Response;
      await uploadData({
        key: `${folderName}/${key.FileName}`,
        data: blob,
      });
      console.log("Bucket", bucketName);
      Response = `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/${folderName}/${key.FileName}`;
      return Response;
    } catch (err) {
      console.log("Error uploading file:", err);
    }
  }; //     Response = `https://trackmytournament-dev125444-dev.s3.ap-south-1.amazonaws.com/public/${folderName}/${key.FileName}`;

  // const uploadLogoFileToStorage = async (folderName, key) => {
  //   // const contentType = key.split(";")[0].split(":")[1];
  //   console.log("folder name", folderName, "FileName", key.FileName);

  //   try {
  //     const response = await fetch(key.URL);
  //     const blob = await response.blob();
  //     let Response;
  //     await Storage.put(`${folderName}/${key.FileName}`, blob, {
  //       progressCallback: async (progress) => {
  //         console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
  //         let percentage = (progress.loaded / progress.total) * 100;
  //       },
  //     });
  //     return Response;
  //   } catch (err) {
  //     console.log("Error uploading file:", err);
  //   }
  // }

  const handleUpdate = async () => {
    console.log("helloupdate");

    const UpdateUserProfile = await DataStore.query(
      UserMaster,
      UserDteaildata.UUID
    );
    let Userlogo = "";
    if (selectedImage) {
      Userlogo = await uploadLogoFileToStorage(
        `UserProfile/${UpdateUserProfile.Name.trim().split(" ").join("_")}`,
        selectedImage
      );
    }
    console.log("Userlogo", inputText.logo, Userlogo);

    const UpdateprofileUser = UserMaster.copyOf(
      UpdateUserProfile,
      (updated) => {
        updated.Name = `${inputText.FirstName.trim()} ${inputText.LastName.trim()}`;
        updated.EmailId = inputText.EmailAddress.trim();
        updated.PhotoURL = Userlogo !== "" ? Userlogo : inputText.logo;
        updated.City = inputText.City;
        updated.State = inputText.State;
        updated.Country = inputText.Country;
        updated.PinCode = Number(inputText.PinCode);
        // Assuming FixturesCreated is the correct property name
        return updated;
      }
    );
    console.log("UpdateprofileUser", UpdateprofileUser);
    const UserProfileUpdate = await DataStore.save(UpdateprofileUser);
    if (UserProfileUpdate) {
      getUserdata(UserProfileUpdate.UUID);
      setSuccessModalVisible(true);
      setSunccessModalMesg("User Detail Updated Successfully");
    } else {
      setSuccessModalVisible(false);
    }
    console.log("Updated RoundObj:", UserProfileUpdate);
  };

  const handleImageSelection = (uri, name, idx, filename) => {
    console.log("handlegetURl", filename, uri, name, idx);
    let obj = { URL: uri, FileName: filename };

    setSelectedImage(obj);
  };

  // const handleImageSelection = (uri, kindOfFile, index) => {

  //   console.log("handleImageSelection", uri);
  //   if (kindOfFile == "logo") {
  //     setSelectedImage(uri);
  //     // uploadLogoFileToStorage(uri);
  //   }
  // };

  useEffect(() => {
    const getUserDetails = async () => {
      userDetails = await AsyncStorage.getItem("UserDetail");
      console.log("userDetails", userDetails);

      console.log("userDetails", userDetails);
      await getUserdata(userDetails);
    };

    getUserDetails();
  }, [SuccessModalVisible]);

  const handleOk = () => {
    setSuccessModalVisible(false);
    setSunccessModalMesg("");
  };
  const validationSchema = Yup.object().shape({
    emailId: Yup.string().email("Invalid email address").required("Required"),
  });

  const navigation = useNavigation();

  const handleBack = () => {
    navigation.goBack();
  };

  const pickImageAsync = async (kindOfFile) => {
    const input = document.createElement("input");
    input.type = "file";
    // input.accept = "image/*";
    input.onchange = (event) => {
      const target = event.target as HTMLInputElement;
      const file = target.files && target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const uri = e.target.result as string;
          const fileName = file.name;

          // setSelectedImage(uri); // consistent keys

          if (kindOfFile == "Logo") {
            console.log("logo", fileName);
            let obj = { URL: uri, FileName: fileName };
            console.log("SelectedImage", obj);
            setSelectedImage(obj);
            console.log("logo name:", fileName);
          }
        };
        reader.readAsDataURL(file);
      } else {
        setErrorModalVisible(true);
        setErrorModalMesg("You did not select any image.");
        // alert("You did not select any image.");
      }
    };
    input.click();
  };

  const handleSubmitError = () => {
    setErrorModalVisible(false);
  };
  return (
    <>

      <View>
        {Platform.OS !== "web" && (
          <AppBar
            title={"My Profile"}
            showBackButton={true}
            onBackPress={handleBack}
          />
        )}
        <AdminLayout>
          <ScrollView>
            {UserDteaildata && (
              <Formik
                initialValues={{
                  emailId: "",
                }}
                onSubmit={() => handleUpdate()}
                validationSchema={validationSchema}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  isSubmitting,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <View
                    style={{
                      paddingBottom:
                        Platform.OS === "web" && screenWidth > 760
                          ? undefined
                          : 40,
                    }}
                  >
                    {Platform.OS == "web" && (
                      <CommonHeading props={"My Profile"} />
                    )}
                    {/* <CropImagePicker onImagePicked={undefined} /> */}
                    {/* <ImageCropper /> */}

                    <View style={styles.myProfileCard}>
                      <View style={styles.myProfileLeft}>
                        <View
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Pressable
                            onPress={() => pickImageAsync("Logo")}
                            style={{ width: 120 }}
                          >
                            <View style={styles.circleContainer}>
                              <View style={styles.circle}>
                                <UploadImage
                                  imageUrl={selectedImage.URL}
                                  onImageSelect={handleImageSelection}
                                  kindOfFile="logo"
                                  index={0}
                                />
                                {/* <ImageViewer
                              selectedImage={selectedImage}
                              placeholderImageSource={undefined}
                            /> */}
                                <View style={styles.cameraIconWrapper}>
                                  <CameraIcon
                                    name="camera-plus"
                                    size={17}
                                    color="#fff"
                                  />
                                </View>
                              </View>
                            </View>
                          </Pressable>
                        </View>
                        <Text style={styles.myProfileAdminName}>
                          {UserDteaildata.Name}
                        </Text>
                        {Platform.OS === "web" && screenWidth > 760 && (
                          <View style={styles.myProfileDataContainer}>
                            <Pressable style={styles.myProfileDataItem}>
                              <Text style={styles.mpdSmallText}>
                                No. of Participated Tournaments
                              </Text>
                              <Text style={styles.mpdBoldText}>
                                {TotalRegistrationTeam}
                              </Text>
                            </Pressable>
                            <Pressable style={styles.myProfileDataItem}>
                              <Text style={styles.mpdSmallText}>
                                No. of Teams
                              </Text>
                              <Text style={styles.mpdBoldText}>{TotalTeams}</Text>
                            </Pressable>
                            <Pressable style={styles.myProfileDataItem}>
                              <Text style={styles.mpdSmallText}>
                                No. of Total Tournaments
                              </Text>
                              <Text style={styles.mpdBoldText}>
                                {TotalParticipateTournament}
                              </Text>
                            </Pressable>
                          </View>
                        )}
                      </View>
                      {Platform.OS === "web" && screenWidth > 760 && (
                        <View style={styles.verticalLine} />
                      )}
                      <View style={styles.myProfileRight}>
                        <View style={[styles.settingContainer]}>
                          <View style={styles.settingHalfItem}>
                            <Text style={styles.TextInputLabel}>First Name</Text>
                            <TextInput
                              style={styles.inputStyle}
                              value={inputText.FirstName}
                              onChangeText={(text) =>
                                inputEvent("FirstName", text)
                              }
                              placeholder=""
                            />
                          </View>
                          <View style={styles.settingHalfItem}>
                            <Text style={styles.TextInputLabel}>Last Name</Text>
                            <TextInput
                              style={styles.inputStyle}
                              value={inputText.LastName}
                              onChangeText={(text) =>
                                inputEvent("LastName", text)
                              }
                              placeholder=""
                            />
                          </View>
                          <View style={styles.settingHalfItem}>
                            <Text style={styles.TextInputLabel}>
                              Mobile Number
                            </Text>
                            <TextInput
                              style={styles.inputStyle}
                              value={inputText.MobileNo}
                              aria-disabled={true}
                              placeholder=""
                            />
                          </View>
                          <View style={styles.settingHalfItem}>
                            <Text style={styles.TextInputLabel}>
                              Email Address
                            </Text>
                            <TextInput
                              style={styles.inputStyle}
                              value={inputText.EmailAddress}
                              onChangeText={(text) =>
                                inputEvent("EmailAddress", text)
                              }
                              id="emailId"
                              placeholder=""
                              onChange={handleChange}
                              onBlur={handleBlur("emailId")}
                              
                            />
                          </View>
                          {touched.emailId && errors.emailId && (
                            <View>
                              <Text style={{ color: "red" }}>
                                {errors.emailId}
                              </Text>
                            </View>
                          )}
                          <View style={styles.settingHalfItem}>
                            <Text style={styles.TextInputLabel}>Country</Text>
                            <TextInput
                              style={styles.inputStyle}
                              value={inputText.Country}
                              onChangeText={(text) => inputEvent("Country", text)}
                              placeholder=""
                            />
                          </View>
                          <View style={styles.settingHalfItem}>
                            <Text style={styles.TextInputLabel}>State</Text>
                            <TextInput
                              style={styles.inputStyle}
                              onChangeText={(text) => inputEvent("State", text)}
                              value={inputText.State}
                              placeholder=""
                            />
                          </View>
                          <View style={styles.settingHalfItem}>
                            <Text style={styles.TextInputLabel}>City</Text>
                            <TextInput
                              style={styles.inputStyle}
                              value={inputText.City}
                              onChangeText={(text) => inputEvent("City", text)}
                              placeholder=""
                            />
                          </View>
                          <View style={styles.settingHalfItem}>
                            <Text style={styles.TextInputLabel}>Pincode</Text>
                            <TextInput
                              style={styles.inputStyle}
                              onChangeText={(text) => inputEvent("PinCode", text)}
                              value={inputText.PinCode}
                              placeholder=""
                            />
                          </View>
                        </View>
                        <View style={styles.btnWrapper}>
                          <Pressable style={styles.cancelBtn}>
                            <Text style={styles.btnText}>Cancel</Text>
                          </Pressable>
                          <Pressable
                            style={styles.saveChangesBtn}
                            onPress={handleUpdate}
                          >
                            <Text style={styles.btnText1}>Save Changes</Text>
                          </Pressable>
                        </View>
                      </View>
                    </View>
                    <Text style={design.heading}>As a Player</Text>
                    <Pressable style={styles.myProfileCard}>
                      <ScrollView style={design.container}>
                        {/* <Text style={design.heading}>Player Games Statistics</Text> */}

                        {playerStatistics.map((stat, index) => (
                          <View key={index} style={design.horizontalGrid}>
                            <View style={design.statsCard}>
                              <Text style={design.gameTitle}>Goals</Text>
                              <Text style={design.gameCount}>{stat.Goals}</Text>
                            </View>

                            <View style={design.statsCard}>
                              <Text style={design.gameTitle}>Games</Text>
                              <Text style={design.gameCount}>{stat.Games}</Text>
                            </View>

                            <View style={design.statsCard}>
                              <Text style={design.gameTitle}>Red Cards</Text>
                              <Text style={design.gameCount}>{stat.RedCards}</Text>
                            </View>

                            <View style={design.statsCard}>
                              <Text style={design.gameTitle}>Yellow Cards</Text>
                              <Text style={design.gameCount}>{stat.YellowCards}</Text>
                            </View>
                          </View>
                        ))}

                        <View style={design.grid}>
                          {playerAwards.length > 0 ? (
                            playerAwards.map((award, index) => (
                              <View key={index} style={design.card}>
                                <Card containerStyle={design.cardContainer}>
                                  {/* Image on Top */}
                                  <Avatar
                                    rounded
                                    source={{ uri: awardImage[award.awardName] }} // Use mapped image URL
                                    size="medium"
                                    containerStyle={design.image}
                                  />

                                  {/* Award Name Below Image */}
                                  <Text h4 style={design.awardName}>
                                    {formatAwardName(award.awardName)} : {award.count}
                                  </Text>

                                  {/* Horizontal layout for awards count and tournaments */}
                                  <View style={{ flexDirection: 'row', marginTop: 10 }}>
                                    {/* Right Side: Tournaments */}
                                    <View style={design.tournamentContainer}>
                                      <Text style={design.tournamentHeader}>Tournaments:</Text>
                                      {award.tournaments.length > 0 ? (
                                        award.tournaments.map((tournament, tIndex) => (
                                          <Text key={tIndex} style={design.tournament}>
                                            - {tournament}
                                          </Text>
                                        ))
                                      ) : (
                                        <Text style={design.tournament}>No Awards Warm So Far</Text>
                                      )}
                                    </View>
                                  </View>
                                </Card>
                              </View>
                            ))
                          ) : (
                            // This is static data if player Awards not present
                            <View style={design.staticcart}>
                              {staticPlayerAwards.map((award, index) => (
                                <View key={index} style={design.card}>
                                  <Card containerStyle={design.cardContainer}>
                                    {/* Image on Top */}
                                    <Avatar
                                      rounded
                                      source={{ uri: awardImage[award.awardName] }} // Use mapped image URL
                                      size="medium"
                                      containerStyle={design.image}
                                    />

                                    {/* Award Name Below Image */}
                                    <Text h4 style={design.awardName}>
                                      {formatAwardName(award.awardName)} : {award.count}
                                    </Text>

                                    {/* Horizontal layout for awards count and tournaments */}
                                    <View style={{ flexDirection: 'row', marginTop: 10 }}>
                                      {/* Right Side: Tournaments */}
                                      <View style={design.tournamentContainer}>
                                        <Text style={design.tournamentHeader}>Tournaments:</Text>
                                        {award.tournaments.length > 0 && award.tournaments[0] !== 'null' ? (
                                          award.tournaments.map((tournament, tIndex) => (
                                            <Text key={tIndex} style={design.tournament}>
                                              - {tournament}
                                            </Text>
                                          ))
                                        ) : (
                                          <Text style={design.tournament}>No Awards Warm So Far</Text>
                                        )}
                                      </View>
                                    </View>
                                  </Card>
                                </View>
                              ))}
                            </View>

                          )}
                        </View>
                      </ScrollView>
                    </Pressable>
                    {/* Coach Awards */}
                    <Text style={design.heading}>As a Coach</Text>

                    <Pressable style={styles.myProfileCard}>
                      <ScrollView style={design.container}>
                        {/* This Player Games Data */}
                        {/* <Text style={design.heading}>Player Games Data</Text> */}

                        <View style={design.horizontalGrid}>
                          <View style={design.statsCard}>
                            <Text style={design.gameTitle}>Games</Text>
                            <Text style={design.gameCount}>{games || 0}</Text> {/* Show 0 if games is empty */}
                          </View>

                          <View style={design.statsCard}>
                            <Text style={design.gameTitle}>Won</Text>
                            <Text style={design.gameCount}>{won || 0}</Text> {/* Show 0 if won is empty */}
                          </View>

                          <View style={design.statsCard}>
                            <Text style={design.gameTitle}>Lost</Text>
                            <Text style={design.gameCount}>{lost || 0}</Text> {/* Show 0 if lost is empty */}
                          </View>

                          <View style={design.statsCard}>
                            <Text style={design.gameTitle}>Drawn</Text>
                            <Text style={design.gameCount}>{drawn || 0}</Text> {/* Show 0 if drawn is empty */}
                          </View>
                        </View>


                        {/* <ScrollView style={design.container}> */}
                        <View style={design.grid}>
                          {coachAwards.length > 0 ? (
                            coachAwards.map((award, index) => (
                              <View key={index} style={design.card}>
                                <Card containerStyle={design.cardContainer}>
                                  {/* Image on Top */}
                                  <Avatar
                                    rounded
                                    source={{ uri: coachawardImage[award.awardName] }} // Use mapped image URL
                                    size="medium"
                                    containerStyle={design.image}
                                  />

                                  {/* Award Name Below Image */}
                                  <Text h4 style={design.awardName}>
                                    {formatAwardName(award.awardName)} : {award.count}
                                  </Text>

                                  {/* Horizontal layout for awards count and tournaments */}
                                  <View style={{ flexDirection: 'row', marginTop: 10 }}>
                                    {/* Right Side: Tournaments */}
                                    <View style={design.tournamentContainer}>
                                      <Text style={design.tournamentHeader}>Tournaments:</Text>
                                      {award.tournaments.length > 0 ? (
                                        award.tournaments.map((tournament, tIndex) => (
                                          <Text key={tIndex} style={design.tournament}>
                                            - {tournament}
                                          </Text>
                                        ))
                                      ) : (
                                        <Text style={design.tournament}>No Awards Warm So Far</Text>
                                      )}
                                    </View>
                                  </View>
                                </Card>
                              </View>
                            ))
                          ) : (
                            <View style={design.staticcart}>
                            {staticCoachAwards.map((award, index) => (
                              <View key={index} style={design.card}>
                                <Card containerStyle={design.cardContainer}>
                                  {/* Image on Top */}
                                  <Avatar
                                    rounded
                                    source={{ uri: coachawardImage[award.awardName] }} // Use mapped image URL
                                    size="medium"
                                    containerStyle={design.image}
                                  />

                                  {/* Award Name Below Image */}
                                  <Text h4 style={design.awardName}>
                                    {formatAwardName(award.awardName)} : {award.count}
                                  </Text>

                                  {/* Horizontal layout for awards count and tournaments */}
                                  <View style={{ flexDirection: 'row', marginTop: 10 }}>
                                    {/* Right Side: Tournaments */}
                                    <View style={design.tournamentContainer}>
                                      <Text style={design.tournamentHeader}>Tournaments:</Text>
                                      {award.tournaments.length > 0 && award.tournaments[0] !== 'null' ? (
                                        award.tournaments.map((tournament, tIndex) => (
                                          <Text key={tIndex} style={design.tournament}>
                                            - {tournament}
                                          </Text>
                                        ))
                                      ) : (
                                        <Text style={design.tournament}>No Awards Warm So Far</Text>
                                      )}
                                    </View>
                                  </View>
                                </Card>
                              </View>
                            ))}
                          </View>

                          )}
                        </View>
                      </ScrollView>

                    </Pressable>

                  </View>
                )}
              </Formik>
            )}
          </ScrollView>

          {SuccessModalVisible == true ? (
            <SuccessAutoExitModal
              visibleModal={SuccessModalVisible}
              SuccessMessage={SunccessModalMesg}
              handleOk={handleOk}
            />
          ) : // <SuccessModal
            // visibleModal={SuccessModalVisible}
            //   handleClose={undefined}
            //   SuccessMessage={SunccessModalMesg}
            //   handleConfirm={undefined}
            //   handleOk={handleOk}
            // />
            null}

          {errorModalVisible == true ? (
            <ErrorModal
              visibleModal={errorModalVisible}
              SuccessMessage={errorModalMesg}
              onSubmit={handleSubmitError}
              header={"Attention"}
            />
          ) : null}
        </AdminLayout>
      </View >

    </>
  );
};

const styles = StyleSheet.create({
  myProfileCard: {
    borderColor: Platform.OS === "web" && screenWidth > 760 ? "#ddd" : null,
    borderWidth: Platform.OS === "web" && screenWidth > 760 ? 1 : null,
    shadowColor: Platform.OS === "web" && screenWidth > 760 ? "#000" : null,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: Platform.OS === "web" && screenWidth > 760 ? 0.2 : null,
    shadowRadius: Platform.OS === "web" && screenWidth > 760 ? 10 : null,
    elevation: Platform.OS === "web" && screenWidth > 760 ? 5 : null,
    borderRadius: Platform.OS === "web" && screenWidth > 760 ? 15 : null,
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    justifyContent:
      Platform.OS === "web" && screenWidth > 760 ? "space-between" : null,
    padding: Platform.OS === "web" && screenWidth > 760 ? 15 : 10,
    marginTop: 10,
    gap: 15,
  },
  //   upload photo css start
  circleContainer: {
    width: 120,
    height: 120,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: "blue",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
  },
  circle: {
    width: "100%",
    height: "100%",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.grayColour,
    position: "relative",
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 200,
  },
  //   upload photo css end
  myProfileLeft: {
    width: Platform.OS === "web" && screenWidth > 760 ? "30%" : "100%",
    maxWidth: Platform.OS === "web" && screenWidth > 760 ? 300 : null,
    alignItems: "center",
    gap: Platform.OS === "web" && screenWidth > 760 ? 8 : null,
    paddingBottom: Platform.OS === "web" && screenWidth > 760 ? null : 10,
  },
  myProfileRight: {
    flex: 1,
    gap: Platform.OS === "web" && screenWidth > 760 ? 20 : null,
    justifyContent:
      Platform.OS === "web" && screenWidth > 760 ? "space-between" : null,
  },
  myProfileAdminName: {
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 22,
    color: "white",
  },
  verticalLine: {
    width: 1,
    height: "100%",
    backgroundColor: "#d3d3d3",
  },
  cameraIconWrapper: {
    backgroundColor: "#140759",
    width: 25,
    height: 25,
    borderRadius: 15,
    position: "absolute",
    right: -2,
    bottom: -1,
    justifyContent: "center",
    alignItems: "center",
  },
  myProfileDataContainer: {
    gap: 10,
    width: "100%",
    marginTop: 15,
  },
  myProfileDataItem: {
    flexDirection: "row",
    backgroundColor: colors.royalBlue,
    borderColor: colors.royalBlue,
    borderWidth: 1,
    width: "100%",
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  mpdSmallText: {
    fontFamily: "PlusJakartaSansMedium",
    color: colors.white,
  },
  mpdBoldText: {
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 20,
    color: colors.orangeColour,
  },
  settingContainer: {
    width: "100%",

    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    gap: 15,
    flexWrap: "wrap",
    paddingBottom: Platform.OS === "web" && screenWidth > 760 ? null : 20,
  },
  settingHalfItem: {
    minWidth: Platform.OS === "web" && screenWidth > 760 ? "40%" : "100%",
    maxWidth: Platform.OS === "web" && screenWidth > 760 ? "50%" : "100%",

    gap: 5,
    flex: 1,
  },
  TextInputLabel: {
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
    color: "white",
  },
  inputStyle: {
    width: "100%",
    height: Platform.OS === "web" && screenWidth > 760 ? 35 : 40,
    borderColor: colors.borderColor,
    borderWidth: 1,
    paddingHorizontal: 8,
    fontFamily: "PlusJakartaSansExtraLight",
    // outlineColor: "transparent",
    // outlineWidth: 0,
    borderRadius: 5,
    color: colors.white,
    backgroundColor: colors.royalBlue,
  },
  btnWrapper: {
    flexDirection: "row",
    justifyContent:
      Platform.OS === "web" && screenWidth > 760 ? "flex-end" : "center",
    gap: 15,
    paddingBottom: Platform.OS === "web" && screenWidth > 760 ? undefined : 40,
  },
  cancelBtn: {
    borderColor: colors.aqua,
    borderWidth: 1,
    width: 110,
    height: Platform.OS === "web" && screenWidth > 760 ? 30 : 40,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  saveChangesBtn: {
    backgroundColor: "#12FFE3",
    borderColor: "#12FFE3",
    borderWidth: 1,
    width: 110,
    height: Platform.OS === "web" && screenWidth > 760 ? 30 : 40,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  btnText: {
    fontFamily: "PlusJakartaSansMedium",
    color: colors.aqua,
  },
  btnText1: {
    fontFamily: "PlusJakartaSansMedium",
    color: colors.royalBlue,
  },

  tableContainer: {
    marginTop: 10,
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  tableHeaderText: {
    fontWeight: "bold",
    flex: 1,
  },
  tableCell: {
    flex: 1,
    fontSize: 14,
  },

});

const design = StyleSheet.create({
  // Styling for the game stats section
  statsGrid: {
    width: '100%',

    marginBottom: 20,
  },
  horizontalGrid: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: 10,
  },
  statsCard: {
    width: Platform.OS === 'web' ? '20%' : '50%', // Adjust width for responsiveness
    backgroundColor: colors.royalBlue, // royalBlue
    padding: 15,
    height: "70%",
    marginBottom: 10,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 2, // Shadow for Android
  },
  gameTitle: {
    color: '#FFFFFF', // white
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 10,
    textAlign: 'center',
  },
  gameCount: {
    color: '#FFA500', // orange
    fontWeight: 'bold',
    fontSize: 24,
    textAlign: 'center',
  },
  container: {
    padding: Platform.OS === 'web' ? 15 : 10,
    backgroundColor: colors.bgColor,
  },
  heading: {
    fontSize: 24,
    fontWeight: 'bold',
    marginLeft: 20,
    // textAlign: 'center', 
    marginVertical: 10,
    color: colors.white, // Adjust based on your theme
  },
  noDataText: {
    fontSize: 16,
    color: 'red', // Customize as needed
    textAlign: 'center', // Center the text horizontally
  },
  noDataContainer: {
    alignItems: 'center',
    margin: 'auto'
  },

  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    backgroundColor: colors.bgColor,
  },
  card: {
    width: Platform.OS === 'web' && screenWidth > 760 ? '32%' : screenWidth < 500 ? '100%' : '48%', // Responsive card width
    marginBottom: 10,
    elevation: Platform.OS === 'android' ? 2 : 0, // Shadow for Android only
    backgroundColor: colors.bgColor,
  },
  cardContainer: {
    borderRadius: 10,
    backgroundColor: colors.bgColor,
    padding: 10,
    shadowColor: Platform.OS === 'web' ? '#000' : 'transparent',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 1,
    textAlign: 'center',
    elevation: Platform.OS === 'android' ? 2 : 0,
  },
  staticcart: {
    margin: 'auto',
    flexDirection: Platform.OS === 'web' ? 'row' : 'column', // Column for mobile, row for web
    flexWrap: Platform.OS === 'web' ? 'wrap' : 'nowrap', // Wrap on web
    justifyContent: Platform.OS === 'web' ? 'flex-start' : 'center', // Align items differently based on platform
    alignItems: 'center',
    width: '100%',
  },
  cart: {
    width: Platform.OS === 'web' ? '30%' : '90%', // Adjust width for web and mobile
    margin: 10, // Margin for spacing between cards
  },
  leftContainer: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center', // Adjust alignment to center vertically
    textAlign: Platform.OS === 'web' ? 'justify' : 'left',
  },
  awardName: {
    borderColor: colors.borderColor,
    fontWeight: 'bold',
    color: colors.orange,
    backgroundColor: colors.royalBlue,
    borderRadius: 5,
    textAlign: 'center',
    paddingVertical: 5,
  },
  awardsCount: {
    color: colors.white,
    fontWeight: 'bold',
    marginBottom: 5,
    textAlign: 'left',
  },
  image: {
    marginVertical: 10,
    alignSelf: 'center',
  },
  tournamentContainer: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center', // Center tournaments vertically as well
  },
  tournamentHeader: {
    fontWeight: 'bold',
    color: 'white',
  },
  tournament: {
    color: colors.orange,
  },
});


export default MyProfile;
