import React, { useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Dimensions,
  Platform,
  Modal,
  TextInput,
  ActivityIndicator,
} from "react-native";
import { colors } from "../../../../components/reusable/colour";
import TrashIcon from "react-native-vector-icons/Ionicons";
import AddIcon from "react-native-vector-icons/Feather";

import { Button } from "react-native-elements";
import UploadImage from "../../../../components/reusable/UploadImage";
import { getUserMasterData } from "../../../../api/UserMasterAPI";
import { DataStore } from "aws-amplify/datastore";
import { uploadData } from "aws-amplify/storage";
import { AuthenticationContext } from "../../../../authentication/Authentication";
import awsmobile from "../../../../aws-exports";
import {
  UserMaster,
  CoachMaster,
  PlayerMaster,
  Squad,
  SquadUserMaster,
  SquadPlayerMaster,
  SquadCoachMaster,
} from "../../../../models";

import { getCoachMasterData } from "../../../../api/CoachMasterAPI";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Formik } from "formik";
import * as Yup from "yup";
import { CustomDateField } from "../../../../components/reusable/textFeild";
import { ErrorModal } from "../../../../components/reusable/AreYouSure";
import { SuccessAutoExitModal } from "../../../../components/reusable/AutoExitModal";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { handlePlayerSerialNumber } from "../../../../components/reusable/CreatePlayerSerialNumber";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

let admin1UserData;

const AddTeamModal = ({ visibleModal, handleClose }) => {
  const route = useRoute();
  const navigation: any = useNavigation();
  const bucketName = awsmobile.aws_user_files_s3_bucket;
  const { userUUID, squadIds, currentUserName }: any = route.params || {};
  const { createAdminUser, getAdminUser } = useContext(AuthenticationContext);

  const [uuid, setUUID] = useState("");

  const [teamLogoURL, setTeamLogoURL] = useState("");
  const [profilePicsArray, setProfilePicsArray] = useState([
    {
      Pic: null,
      UploadedPercentage: "",
      Url: "",
    },
  ]);
  const [teamName, setTeamName] = useState("");
  const [coachName, setCoachName] = useState("");
  const [coachMobileNo, setCoachMobileNo] = useState("");
  const [adminName, setAdminName] = useState("");
  const [adminMobileNo, setAdminMobileNo] = useState("");
  const [admin2UUID, setAdmin2UUID] = useState("");
  const [modalVisible, setModalVisible] = useState(visibleModal);
  const [loading, setLoading] = useState(false);
  const [playersList, setPlayersList]: any = useState([
    {
      Name: "",
      DOB: "",
      JerseyNo: "",
      MobileNo: "",
      PhotoURL: "",
      UUID: "",
      playerSummaryId: "",
      coachMasterId: "",
    },
  ]);

  let playerSerialNumber: any;

  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [successModalMesg, setSuccessModalMesg] = useState("");
  const [errorModalMesg, setErrorModalMesg] = useState("");

  const validationSchema = Yup.object().shape({
    teamName: Yup.string()
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        "Team Name must not contain special characters"
      )
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Team name required"),
    coachName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Coach name required"),
    coachMobileNo: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid Mobile Number")
      .required("Coach mobile number required"),
    adminName: Yup.string().matches(
      /^[a-zA-Z\s]+$/,
      "Manager Name must contain only letters and spaces"
    ),

    adminMobileNo: Yup.string().matches(/^[0-9]{10}$/, "Invalid Mobile Number"),
  });

  const getUserdata = async () => {
    let userDetails = await AsyncStorage.getItem("UserDetail");
    console.log("userDetails", userDetails);
    if (userDetails) {
      const userData = await DataStore.query(UserMaster, userDetails);
      console.log("userData", userData);
      admin1UserData = userData;
      setUUID(userData.UUID);
    } else {
      console.log("No user found in temp storage");
    }
  };

  const generateSerialNumber = async () => {
    const originLocation = window.location.origin;
    console.log("origin location", originLocation);

    try {
      const serialNumber = await handlePlayerSerialNumber(originLocation);
      // setPlayerSerialNumber(serialNumber);
      playerSerialNumber = serialNumber;
      console.log("Generated Player Serial Number:", serialNumber);
    } catch (error) {
      console.error("Error generating player serial number", error);
    }
  };

  useEffect(() => {
    getUserdata();
    console.log("visibleModal", visibleModal, "handleClose", handleClose);
    let list1 = [];
    for (let i = 0; i < 2; i++) {
      list1.push({
        Name: "",
        DOB: "",
        JerseyNo: "",
        MobileNo: "",
        PhotoURL: "",
        UUID: "",
        playerSummaryId: "",
        coachMasterId: "",
      });
    }
    console.log("PlayerList Length : ", list1.length);
    setPlayersList(list1);
    let list2 = [];
    for (let i = 0; i < 2; i++) {
      list2.push({
        Pic: null,
        UploadedPercentage: "",
        Url: "",
      });
    }
    setProfilePicsArray(list2);
    console.log("ProfilePics Length : ", list2.length);
  }, []);

  async function uploadLogoFileToStorage(uri) {
    try {
      const response = await fetch(uri);
      const blob = await response.blob();
      await uploadData({
        key: "team/" + teamName.replaceAll(" ", "_") + "/logo.png",
        data: blob,
      });
      let url =
        `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/team/` +
        teamName.replaceAll(" ", "_") +
        "/logo.png";
      setTeamLogoURL(url);
    } catch (err) {
      console.log("Error uploading file:", err);
    }
  }

  async function uploadProfilePicToStorage(uri, index) {
    const number = Math.floor(100000 + Math.random() * 900000)
      .toString()
      .substring(0, 4);
    try {
      const response = await fetch(uri);
      const blob = await response.blob();
      await uploadData({
        key: "userPhotos/" + number + ".jpg",
        data: blob,
        options: {
          onProgress: ({ transferredBytes, totalBytes }) => {
            if (totalBytes) {
              console.log(
                `Upload progress ${Math.round(
                  (transferredBytes / totalBytes) * 100
                )} %`
              );
            }
            let percentage = Math.round((transferredBytes / totalBytes) * 100);
            let url =
              `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/userPhotos/` +
              number +
              ".jpg";
            if (percentage === 100) {
              console.log("Percentage is 100");
              inputPlayerEvent("PhotoURL", url, index);
            }
            handleSetProfilePic(percentage, "UploadedPercentage", index);
            handleSetProfilePic(url, "Url", index);
          },
        },
        // contentType: 'image/png' // contentType is optional
      });
    } catch (err) {
      console.log("Error uploading file:", err);
    }
  }

  const handleImageSelection = async (uri, kindOfFile, index) => {
    if (kindOfFile == "logo") {
      setTeamLogoURL(uri);
      await uploadLogoFileToStorage(uri);
    } else if (kindOfFile == "profilePic") {
      await uploadProfilePicToStorage(uri, index);
    }
  };

  const handleOnSubmit = async () => {
    try {
      setLoading(true); // Start loading

      let admin2UserData: any,
        coachSummary: any,
        coachUUID: any,
        userMasterCoach: any,
        userMasterAdmin: any,
        resCognitoAdmin2: any,
        squad: any,
        squadUserMaster: any,
        squadCoachMaster: any,
        resAdmin2UUID: any;

      // Step: 1:Coach functionality

      if (coachMobileNo && coachMobileNo.length > 0) {
        const resCoach = await cognitoUserDetails("+91" + coachMobileNo);
        console.log(resCoach.statusCode);
        if (resCoach.statusCode === 400) {
          const resAddCoach = await addUser("+91" + coachMobileNo);
          coachUUID = resAddCoach?.User?.Username;
        } else {
          console.log("Coach Found data : ", resCoach);
          coachUUID = resCoach?.Username;
        }
        await getUserMasterData(coachUUID).then(async (response) => {
          if (response) {
            console.log("Coach Data", response);
            userMasterCoach = response;
            if (userMasterCoach.userMasterCoachSummaryId == "") {
              console.log("We are in if Coach Summary");
              try {
                coachSummary = await DataStore.save(
                  new CoachMaster({
                    Name: coachName,
                    Games: 0,
                    Won: 0,
                    Lost: 0,
                    Drawn: 0,
                  })
                );
              } catch (err) {
                console.log("Error in saving coach summary : ", coachSummary);
                setLoading(false);
              }
            } else {
              console.log("We are in else of coach summary");
              await getCoachMasterData(
                userMasterCoach.userMasterCoachSummaryId
              ).then((value) => {
                coachSummary = value;
              });
            }
          } else {
            console.log("No coach data found");
            try {
              coachSummary = await DataStore.save(
                new CoachMaster({
                  Name: coachName,
                  Games: 0,
                  Won: 0,
                  Lost: 0,
                  Drawn: 0,
                })
              );
              userMasterCoach = await DataStore.save(
                new UserMaster({
                  UUID: coachUUID,
                  Name: coachName,
                  ContactNo: Number(coachMobileNo),
                  EmailId: "",
                  PhotoURL: "",
                  CreatedBy: admin1UserData.Name,
                  LastUpdatedBy: admin1UserData.Name,
                  RoleCoach: true,
                  RolePlayer: false,
                  CoachSummary: coachSummary,
                })
              );
              const updateCoachUserMasterId = {
                coachMasterCoachDataUUID: userMasterCoach.UUID,
              };
              let updatedItem;
              const original = await DataStore.query(
                CoachMaster,
                userMasterCoach.userMasterCoachSummaryId
              );
              try {
                if (updateCoachUserMasterId) {
                  console.log("Current Item : ", updateCoachUserMasterId);
                  updatedItem = CoachMaster.copyOf(original, (ele) => {
                    ele.coachMasterCoachDataUUID =
                      updateCoachUserMasterId.coachMasterCoachDataUUID;
                  });
                  await DataStore.save(updatedItem);
                  console.log("Updated Item:", updatedItem);
                } else {
                  console.error("Item not found");
                }
                return updatedItem;
              } catch (error) {
                console.error("Error updating item:", error);
                setLoading(false);
              }
              console.log("UserMaster Coach : ", userMasterCoach);
            } catch (e) {
              console.log("Error : ", e);
              setLoading(false);
            }
          }
        });
      } else {
        console.log("CoachMobile number is not provided");
      }

      // Step : 2 :Admin mobile functionality

      if (adminMobileNo && adminMobileNo.length > 0) {
        resCognitoAdmin2 = await cognitoUserDetails("+91" + adminMobileNo);
        if (resCognitoAdmin2.statusCode === 400) {
          const resAddAdmin2 = await addUser("+91" + adminMobileNo);
          console.log("Resp : ", resAddAdmin2);
          resAdmin2UUID = resAddAdmin2?.User?.Username;
          console.log("Admin UUID ", resAdmin2UUID);

          if (resAdmin2UUID) {
            try {
              userMasterAdmin = await DataStore.save(
                new UserMaster({
                  UUID: resAdmin2UUID,
                  Name: adminName,
                  ContactNo: Number(adminMobileNo),
                  EmailId: "",
                  PhotoURL: "",
                  CreatedBy: admin1UserData.Name,
                  LastUpdatedBy: admin1UserData.Name,
                  RoleCoach: false,
                  RolePlayer: false,
                })
              );
              console.log("UserMaster Admin: ", userMasterAdmin);
            } catch (e) {
              console.log("Error : ", e);
              setLoading(false);
            }
          }
        } else {
          resAdmin2UUID = resCognitoAdmin2?.Username;
          console.log("Else user data uuid : ", resAdmin2UUID);

          await getUserMasterData(resAdmin2UUID).then(async (response) => {
            if (response) {
              console.log("Admin Data", response);
              admin2UserData = response;
              console.log("admin2UserData", admin2UserData);
            } else {
              console.log("No admin data found");

              const resAddAdmin2 = await addUser("+91" + adminMobileNo);
              console.log("Resp : ", resAddAdmin2);
              resAdmin2UUID = resAddAdmin2?.User?.Username;
              console.log("Admin UUID ", resAdmin2UUID);

              try {
                userMasterAdmin = await DataStore.save(
                  new UserMaster({
                    UUID: resAdmin2UUID,
                    Name: adminName,
                    ContactNo: Number(adminMobileNo),
                    EmailId: "",
                    PhotoURL: "",
                    CreatedBy: admin1UserData.Name,
                    LastUpdatedBy: admin1UserData.Name,
                    RoleCoach: false,
                    RolePlayer: false,
                  })
                );
                console.log("UserMaster Admin: ", userMasterAdmin);
              } catch (e) {
                console.log("Error : ", e);
                setLoading(false);
              }
            }
          });
        }
      } else {
        console.log("admin mobile number not found");
      }

      // Squad functionality

      const admin2Obj = {
        UUID: resAdmin2UUID ? resAdmin2UUID : "",
        Name: adminName,
        PhotoURL: "",
      };
      const admin1Obj = {
        UUID: uuid,
        Name: admin1UserData.Name,
        PhotoURL: admin1UserData.PhotoURL,
      };

      // Step 3: Squad functionality

      try {
        squad = await DataStore.save(
          new Squad({
            Name: teamName,
            Logo: teamLogoURL,
            TournamentsPlayed: 0,
            CreatedBy: admin1UserData.Name,
            LastUpdatedBy: admin1UserData.Name,
            Admin1: admin1Obj,
            Admin2: admin2Obj,
          })
        );
        console.log("Squad Data : ", squad);
      } catch (err) {
        console.log("Error in creating squad : ", err);
        setLoading(false);
      }

      if (admin1UserData) {
        try {
          squadUserMaster = await DataStore.save(
            new SquadUserMaster({
              userMasterUUID: admin1UserData.UUID,
              squadId: squad.id,
            })
          );
          console.log("Squad Usermaster data for admin1 : ", squadUserMaster);
        } catch (err) {
          console.log("Error in creating squad userMaster for admin 1 : ", err);
          setLoading(false);
        }
      }
      if (userMasterAdmin) {
        try {
          squadUserMaster = await DataStore.save(
            new SquadUserMaster({
              userMasterUUID: userMasterAdmin.UUID,
              squadId: squad.id,
            })
          );
          console.log("Squad Usermaster data for admin2 : ", squadUserMaster);
        } catch (err) {
          console.log("Error in creating squad userMaster for admin 2 : ", err);
          setLoading(false);
        }
      }
      if (coachSummary && userMasterCoach) {
        try {
          squadUserMaster = await DataStore.save(
            new SquadUserMaster({
              userMasterUUID: userMasterCoach.UUID,
              squadId: squad.id,
            })
          );
          console.log("Squad Usermaster data for coach : ", squadUserMaster);
        } catch (err) {
          console.log("Error in creating squad userMaster for coach : ", err);
          setLoading(false);
        }
        try {
          squadCoachMaster = await DataStore.save(
            new SquadCoachMaster({
              coachMasterId: coachSummary.id,
              squadId: squad.id,
            })
          );
          console.log("Squad Usermaster Coach data : ", squadCoachMaster);
        } catch (err) {
          console.log("Error in creating squad userMaster coach : ", err);
          setLoading(false);
        }
      }
      // Player functionality
      await handleAddUpdatePlayer(squad.id);
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
      setErrorModalMesg(
        `An error occurred while submitting the form in ${error}`
      );
      setErrorModalVisible(true);
    } finally {
      setLoading(false); // Stop loading
    }
    // final statement
    setSuccessModalVisible(true);
    setSuccessModalMesg("Team details submitted successfully");
    setModalVisible(false);
    navigation.navigate("MyTeams", {
      userUUID: userUUID,
      squadIds: squadIds,
      currentUserName: currentUserName,
    });
  };

  // const handleAddUpdatePlayer = async (SquadID) => {
  //   for (let i = 0; i < playersList.length; i++) {
  //     if (playersList[i].MobileNo !== "") {
  //       let res = await cognitoUserDetails("+91" + playersList[i].MobileNo);
  //       // Check the response for mobileNo is exist or not
  //       if (res.statusCode === 400) {
  //         console.log("res1", res);
  //         const resAddPlayer = await addUser("+91" + playersList[i].MobileNo);
  //         console.log("Resp : ", resAddPlayer);
  //         playersList[i].UUID = resAddPlayer.User.Username;
  //         console.log(" playersList[i].UUID", playersList[i].UUID);
  //       } else {
  //         await generateSerialNumber();
  //         let playerSummaryData = await DataStore.save(
  //           new PlayerMaster({
  //             Name: playersList[i].Name,
  //             JerseyNo: playersList[i].JerseyNo,
  //             PhotoURL: playersList[i].PhotoURL,
  //             DateOfBirth: playersList[i].DOB,
  //             SrNo: playerSerialNumber,
  //           })
  //         );
  //         console.log("playerSummaryData", playerSummaryData);
  //         let playerUserMasterData = await DataStore.save(
  //           new UserMaster({
  //             UUID: playersList[i].UUID,
  //             Name: playersList[i].Name,
  //             ContactNo: Number(playersList[i].MobileNo),
  //             EmailId: "",
  //             PhotoURL: playersList[i].PhotoURL,
  //             CreatedBy: admin1UserData.Name,
  //             LastUpdatedBy: admin1UserData.Name,
  //             RoleCoach: false,
  //             RolePlayer: true,
  //             PlayerSummary: playerSummaryData,
  //             DateOfBirth: playersList[i].DOB,
  //           })
  //         );
  //         console.log("playerUserMasterData", playerUserMasterData);

  //         const original = await DataStore.query(
  //           PlayerMaster,
  //           playerUserMasterData.userMasterPlayerSummaryId
  //         );
  //         console.log("original", original);

  //         let squadPlayerMaster = await DataStore.save(
  //           new SquadPlayerMaster({
  //             squadId: SquadID,
  //             playerMasterId: playerSummaryData.id,
  //           })
  //         );
  //         console.log("squadPlayerMaster", squadPlayerMaster);

  //         try {
  //           if (playerUserMasterData.UUID) {
  //             let updatedItem = PlayerMaster.copyOf(original, (ele) => {
  //               ele.playerMasterPlayerDataUUID = playerUserMasterData.UUID;
  //             });
  //             await DataStore.save(updatedItem);
  //             console.log("Updated Item:", updatedItem);
  //           } else {
  //             console.error("Item not found");
  //           }
  //         } catch (error) {
  //           console.error("Error updating item 400:", error);
  //           setLoading(false);
  //           setErrorModalVisible(true);
  //           setErrorModalMesg(`Error occured ${error}`);
  //         }

  //         console.log("res2", res);
  //         let UserMasterdata = await getUserMasterData(res.Username);
  //         console.log("UserMasterdata", UserMasterdata);

  //         if (UserMasterdata !== undefined) {
  //           if (
  //             UserMasterdata &&
  //             (UserMasterdata.userMasterPlayerSummaryId == "" ||
  //               UserMasterdata.userMasterPlayerSummaryId == null)
  //           ) {
  //             try {
  //               await generateSerialNumber();
  //               let PlayercreateObj = await DataStore.save(
  //                 new PlayerMaster({
  //                   Name: playersList[i].Name,
  //                   PhotoURL: playersList[i].PhotoURL,
  //                   JerseyNo: playersList[i].JerseyNo,
  //                   DateOfBirth: playersList[i].DOB,
  //                   PlayerData: UserMasterdata,
  //                   SrNo: playerSerialNumber,
  //                 })
  //               );
  //               console.log("PlayercreateObj", PlayercreateObj);
  //               let squadPlayerMaster = await DataStore.save(
  //                 new SquadPlayerMaster({
  //                   squadId: SquadID,
  //                   playerMasterId: PlayercreateObj.id,
  //                 })
  //               );
  //               console.log("squadPlayerMaster", squadPlayerMaster);
  //             } catch (error) {
  //               console.log(
  //                 "Player Summary Error not Playermaster Exists : ",
  //                 error
  //               );
  //               setLoading(false);
  //               setErrorModalVisible(true);
  //               setErrorModalMesg(`Error occured ${error}`);
  //             }
  //           } else {
  //             const original = await DataStore.query(
  //               PlayerMaster,
  //               UserMasterdata.userMasterPlayerSummaryId
  //             );
  //             console.log("original", original);
  //           }
  //         } else {
  //           await generateSerialNumber();
  //           let playerSummaryData = await DataStore.save(
  //             new PlayerMaster({
  //               Name: playersList[i].Name,
  //               JerseyNo: playersList[i].JerseyNo,
  //               PhotoURL: playersList[i].PhotoURL,
  //               DateOfBirth: playersList[i].DOB,
  //               SrNo: playerSerialNumber,
  //             })
  //           );
  //           console.log("playerSummaryData", playerSummaryData);
  //           let playerUserMasterData = await DataStore.save(
  //             new UserMaster({
  //               UUID: res.Username,
  //               Name: playersList[i].Name,
  //               ContactNo: Number(playersList[i].MobileNo),
  //               EmailId: "",
  //               PhotoURL: playersList[i].PhotoURL,
  //               CreatedBy: admin1UserData.Name,
  //               LastUpdatedBy: admin1UserData.Name,
  //               RoleCoach: false,
  //               RolePlayer: true,
  //               PlayerSummary: playerSummaryData,
  //               DateOfBirth: playersList[i].DOB,
  //             })
  //           );
  //           console.log("playerUserMasterData", playerUserMasterData);

  //           const original = await DataStore.query(
  //             PlayerMaster,
  //             playerUserMasterData.userMasterPlayerSummaryId
  //           );
  //           console.log("original", original);
  //           try {
  //             if (playerUserMasterData.UUID) {
  //               let updatedItem = PlayerMaster.copyOf(original, (ele) => {
  //                 ele.playerMasterPlayerDataUUID = playerUserMasterData.UUID;
  //               });
  //               await DataStore.save(updatedItem);
  //               console.log("Updated Item:", updatedItem);
  //             } else {
  //               console.error("Item not found");
  //             }
  //           } catch (error) {
  //             console.error("Error updating item 400:", error);
  //             setLoading(false);
  //             setErrorModalVisible(true);
  //             setErrorModalMesg(`Error occured ${error}`);
  //           }
  //         }
  //       }
  //     } else {
  //       await generateSerialNumber();
  //       let PlayerData = await DataStore.save(
  //         new PlayerMaster({
  //           Name: playersList[i].Name,
  //           PhotoURL: playersList[i].PhotoURL,
  //           JerseyNo: playersList[i].JerseyNo,
  //           DateOfBirth: playersList[i].DOB,
  //           SrNo: playerSerialNumber,
  //           // PlayerData: value,
  //         })
  //       );
  //       console.log("PlayersData", PlayerData);
  //       let squadPlayerMaster = await DataStore.save(
  //         new SquadPlayerMaster({
  //           squadId: SquadID,
  //           playerMasterId: PlayerData.id,
  //         })
  //       );
  //       console.log("squadPlayerMaster : ", squadPlayerMaster);
  //     }
  //   }
  // };

  const handleAddUpdatePlayer = async (SquadID) => {
    for (let i = 0; i < playersList.length; i++) {
      if (playersList[i].MobileNo !== "") {
        try {
          let res = await cognitoUserDetails("+91" + playersList[i].MobileNo);
          if (res.statusCode === 400) {
            // Mobile number does not exist, create a new user
            const resAddPlayer = await addUser("+91" + playersList[i].MobileNo);
            playersList[i].UUID = resAddPlayer.User.Username;
          } else {
            playersList[i].UUID = res.Username;
          }
          await handlePlayerData(playersList[i], SquadID);
        } catch (error) {
          console.error(
            `Error processing player ${playersList[i].Name}:`,
            error
          );
          setLoading(false);
          setErrorModalVisible(true);
          setErrorModalMesg(`Error occurred ${error}`);
        }
      } else {
        try {
          await handlePlayerData(playersList[i], SquadID);
        } catch (error) {
          console.error(
            `Error processing player ${playersList[i].Name}:`,
            error
          );
          setLoading(false);
          setErrorModalVisible(true);
          setErrorModalMesg(`Error occurred ${error}`);
        }
      }
    }
  };

  const handlePlayerData = async (player, SquadID) => {
    await generateSerialNumber();
    let playerSummaryData = await DataStore.save(
      new PlayerMaster({
        Name: player.Name,
        JerseyNo: player.JerseyNo,
        PhotoURL: player.PhotoURL,
        DateOfBirth: player.DOB,
        SrNo: playerSerialNumber,
      })
    );

    console.log("playerSummaryData", playerSummaryData);

    let playerUserMasterData = await DataStore.save(
      new UserMaster({
        UUID: player.UUID,
        Name: player.Name,
        ContactNo: Number(player.MobileNo),
        EmailId: "",
        PhotoURL: player.PhotoURL,
        CreatedBy: admin1UserData.Name,
        LastUpdatedBy: admin1UserData.Name,
        RoleCoach: false,
        RolePlayer: true,
        PlayerSummary: playerSummaryData,
        DateOfBirth: player.DOB,
      })
    );

    console.log("playerUserMasterData", playerUserMasterData);

    let squadPlayerMaster = await DataStore.save(
      new SquadPlayerMaster({
        squadId: SquadID,
        playerMasterId: playerSummaryData.id,
      })
    );

    console.log("squadPlayerMaster", squadPlayerMaster);

    if (playerUserMasterData.UUID) {
      const original = await DataStore.query(
        PlayerMaster,
        playerUserMasterData.userMasterPlayerSummaryId
      );
      console.log("original", original);
      let updatedItem = PlayerMaster.copyOf(original, (ele) => {
        ele.playerMasterPlayerDataUUID = playerUserMasterData.UUID;
      });
      await DataStore.save(updatedItem);
      console.log("Updated Item:", updatedItem);
    } else {
      console.error("Item not found");
    }
  };

  const handleInputTeamDetailsChange = (fieldName, value) => {
    switch (fieldName) {
      case "teamName":
        console.log("Team Name", value);
        setTeamName(value);
        break;
      case "coachName":
        console.log("Coach Name", value);
        setCoachName(value);
        break;
      case "coachMobileNo":
        console.log("Coach Mobile No", value);
        setCoachMobileNo(value);
        break;
      case "adminName":
        console.log("Admin Name", value);
        setAdminName(value);
        break;
      case "adminMobileNo":
        console.log("Admin Mobile No", value);
        setAdminMobileNo(value);
        break;
      default:
        // Handle default case if needed
        console.log("default case is executed !!");
        break;
    }
  };

  const handleSetProfilePic = (e, field, index) => {
    console.log("targetvalue");
    console.log("We are in handle set PP");

    if (field === "Pic") {
      const { Pic, value } = e;
      const list = [...profilePicsArray];
      console.log("Pic : ", e);
      list[index].Pic = e;
      console.log("list", list);
      console.log("We are in Pic");
      setProfilePicsArray(list);
    }
    if (field === "UploadedPercentage") {
      console.log("Target", e);
      const list = [...profilePicsArray];

      const { UploadedPercentage } = e;
      console.log("Li", list[index]);
      list[index].UploadedPercentage = UploadedPercentage;
      console.log("list", list);
      console.log("We are in handle set Percentage");
      setProfilePicsArray(list);
    } else {
      const { Url, value } = e;
      const list = [...profilePicsArray];

      list[index].Url = e;
      console.log("list", list);
      console.log("We are in handle set Url");
      setProfilePicsArray(list);
    }
  };

  const handleAddPlayer = () => {
    const newPlayer = {
      Name: "",
      DOB: "",
      JerseyNo: "",
      MobileNo: "",
      PhotoURL: "",
      UUID: "",
      playerSummaryId: "",
      coachMasterId: "",
    };
    setPlayersList((prevData: any) => [...prevData, newPlayer]);
  };

  const addUser = async (phoneNumber) => {
    let finalResponse;
    const userAttribute = [
      {
        Name: "phone_number" /* required */,
        Value: phoneNumber,
      },
    ];
    const response = await createAdminUser(phoneNumber, userAttribute).then(
      async (data) => {
        console.log("CreateUserResponse : ", data);
        finalResponse = data;
      }
    );
    console.log("response", response);
    return finalResponse;
  };
  const cognitoUserDetails = async (phoneNumber) => {
    const response = await getAdminUser(phoneNumber);
    if (response) {
      console.log("response", response);
    } else {
      console.log("response is not found");
    }

    return response;
  };

  const handleDeletePlayer = (indexToDelete) => {
    // Filter out the player to delete based on the given index
    const updatedData = playersList.filter(
      (_, index) => index !== indexToDelete
    );
    setPlayersList(updatedData);
  };

  const inputPlayerEvent = (field, event, idx) => {
    console.log("hellovalue", field, event, idx);
    const updatedPlayerList = playersList.map((player, index) => {
      if (index === idx) {
        return { ...player, [field]: event };
      }
      return player;
    });
    setPlayersList(updatedPlayerList);
  };

  const handleCloseErrorModal = () => {
    setErrorModalVisible(false);
  };

  return (
    <Formik
      initialValues={{
        teamName: "",
        coachName: "",
        coachMobileNo: "",
        adminName: "",
        adminMobileNo: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values: any) => {
        console.log("values", values);
        handleOnSubmit();
      }}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <View>
          {/* modal started */}

          <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => handleClose()}
          >
            <View
              style={[styles.centeredView, { backgroundColor: "#04040491" }]}
            >
              <View style={styles.modalView}>
                <View
                  style={[
                    styles.teamModalHeadingWrapper2,
                    { marginBottom: 10 },
                  ]}
                >
                  <View style={styles.teamModalHeadingWrapper}>
                    <Text style={styles.teamModalLabel}>Team Name</Text>

                    <TextInput
                      style={styles.TeamModalInput}
                      placeholder={"Enter Team Name"}
                      value={values.teamName}
                      id="teamName"
                      inputMode="text"
                      onChangeText={(text) => {
                        handleInputTeamDetailsChange("teamName", text);
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur("teamName")}
                    />

                    {touched.teamName && errors.teamName && (
                      <View>
                        <Text style={{ color: "red" }}>
                          {errors.teamName.toString()}
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
                <View
                  style={{
                    maxHeight: 0.65 * screenHeight,
                    overflow: "scroll",
                  }}
                >
                  <View
                    style={{
                      flexDirection:
                        Platform.OS == "web" && screenWidth > 760
                          ? "row"
                          : "column",
                      alignItems:
                        Platform.OS == "web" && screenWidth > 760
                          ? "center"
                          : undefined,
                      gap: 15,
                      borderBottomWidth: 1,
                      borderBottomColor: "#9a9696",
                      paddingBottom: 10,
                      marginBottom: 10,
                    }}
                  >
                    <UploadImage
                      onImageSelect={handleImageSelection}
                      kindOfFile="logo"
                    />
                    <View
                      style={{
                        gap: Platform.OS == "web" && screenWidth > 760 ? 10 : 2,
                        flex:
                          Platform.OS == "web" && screenWidth > 760
                            ? 1
                            : undefined,
                        height:
                          Platform.OS == "web" && screenWidth > 760
                            ? undefined
                            : screenHeight * 0.7,
                      }}
                    >
                      <View style={styles.teamModalHeadingWrapper2}>
                        <View style={styles.teamModalHeadingWrapper}>
                          <Text style={styles.teamModalLabel}>Coach Name</Text>

                          <TextInput
                            style={styles.TeamModalInput}
                            placeholder="Enter Coach Name"
                            value={values.coachName}
                            id="coachName"
                            onChangeText={(text) =>
                              handleInputTeamDetailsChange("coachName", text)
                            }
                            onChange={handleChange}
                            inputMode="text"
                            onBlur={handleBlur("coachName")}
                          />

                          {touched.coachName && errors.coachName && (
                            <View>
                              <Text style={{ color: "red" }}>
                                {errors.coachName.toString()}
                              </Text>
                            </View>
                          )}
                        </View>

                        <View style={styles.teamModalHeadingWrapper}>
                          <Text style={styles.teamModalLabel}>
                            Coach Mobile No.
                          </Text>

                          <TextInput
                            style={styles.TeamModalInput}
                            placeholder="Enter Mobile No"
                            value={values.coachMobileNo}
                            id="coachMobileNo"
                            onChangeText={(text) =>
                              handleInputTeamDetailsChange(
                                "coachMobileNo",
                                text
                              )
                            }
                            onChange={handleChange}
                            inputMode="numeric"
                            maxLength={10}
                            onBlur={handleBlur("coachMobileNo")}
                          />

                          {touched.coachMobileNo && errors.coachMobileNo && (
                            <View>
                              <Text style={{ color: "red" }}>
                                {errors.coachMobileNo.toString()}
                              </Text>
                            </View>
                          )}
                        </View>
                      </View>
                      <View style={styles.teamModalHeadingWrapper2}>
                        <View style={styles.teamModalHeadingWrapper}>
                          <Text style={styles.teamModalLabel}>
                            Manager Name
                          </Text>

                          <TextInput
                            style={styles.TeamModalInput}
                            placeholder="Enter Manager Name"
                            value={values.adminName}
                            id="adminName"
                            onChangeText={(text) =>
                              handleInputTeamDetailsChange("adminName", text)
                            }
                            onChange={handleChange}
                            inputMode="text"
                            onBlur={handleBlur("adminName")}
                          />

                          {touched.adminName && errors.adminName && (
                            <View>
                              <Text style={{ color: "red" }}>
                                {errors.adminName.toString()}
                              </Text>
                            </View>
                          )}
                        </View>
                        <View style={styles.teamModalHeadingWrapper}>
                          <Text style={styles.teamModalLabel}>
                            Manager Mobile No.
                          </Text>

                          <TextInput
                            style={styles.TeamModalInput}
                            placeholder="Enter Mobile No"
                            value={values.adminMobileNo}
                            id="adminMobileNo"
                            onChangeText={(text) =>
                              handleInputTeamDetailsChange(
                                "adminMobileNo",
                                text
                              )
                            }
                            onChange={handleChange}
                            inputMode="numeric"
                            maxLength={10}
                            onBlur={handleBlur("adminMobileNo")}
                          />

                          {touched.adminMobileNo && errors.adminMobileNo && (
                            <View>
                              <Text style={{ color: "red" }}>
                                {errors.adminMobileNo.toString()}
                              </Text>
                            </View>
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                  {/* table started here (WEB)*/}
                  <View
                    style={{
                      width: "100%",
                      marginTop: 15,
                      display:
                        Platform.OS === "web" && screenWidth > 760
                          ? "flex"
                          : "none",
                    }}
                  >
                    {/* table header started */}
                    <View style={styles.tableHeaderContainer}>
                      <View style={[styles.tableHeaderTitle]}>
                        <Text
                          style={[
                            styles.tableHeaderTitleText,
                            { textAlign: "left" },
                          ]}
                        >
                          Player
                        </Text>
                      </View>
                      <View style={[styles.tableHeaderTitle3]}>
                        <Text style={styles.tableHeaderTitleText}>
                          Date of Birth
                        </Text>
                      </View>
                      <View style={[styles.tableHeaderJTitle]}>
                        <Text style={styles.tableHeaderTitleText}>J No. </Text>
                      </View>
                      <View style={[styles.tableHeaderTitle3]}>
                        <Text style={styles.tableHeaderTitleText}>
                          Mobile No.
                        </Text>
                      </View>
                      <View style={{ width: 30 }}></View>
                    </View>
                    {/* table header ended */}
                    <View>
                      {/* table row started */}
                      {playersList && playersList.length > 0 ? (
                        playersList.map((item, i) => (
                          <View style={styles.tableDataContainer} key={i}>
                            <View
                              style={[
                                styles.tableDataCell,
                                {
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                },
                              ]}
                            >
                              <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  gap: 5,
                                  width: "100%",
                                }}
                              >
                                <UploadImage
                                  style={{ width: 30, height: 30 }}
                                  onImageSelect={handleImageSelection}
                                  kindOfFile="profilePic"
                                  index={i}
                                />

                                <TextInput
                                  style={styles.TeamModalInput}
                                  placeholder={"Enter Name"}
                                  id={`playersList[${i}].Name`}
                                  value={item.Name}
                                  onChangeText={(text) =>
                                    inputPlayerEvent("Name", text, i)
                                  }
                                />
                              </View>
                              <View>
                                {item.Name == "" ? (
                                  <Text
                                    style={{ color: "red", paddingLeft: 40 }}
                                  >
                                    required
                                  </Text>
                                ) : null}
                              </View>
                            </View>

                            <View
                              style={[
                                styles.tableDataCell3,
                                { flexDirection: "column" },
                              ]}
                            >
                              <CustomDateField
                                containerStyle={undefined}
                                placeholder="DD-MM-YY"
                                isRequired={true}
                                value={item.DOB}
                                onChangeText={(e: any) => {
                                  const newList = [...playersList];
                                  newList[i].DOB = e.target.value;
                                  setPlayersList(newList);
                                }}
                                label={undefined}
                                onBlur={undefined}
                                id={undefined}
                              />
                              {item.DOB == "" ? (
                                <Text style={{ color: "red" }}>required</Text>
                              ) : null}
                            </View>

                            <View
                              style={[
                                styles.tableDataCell2,
                                { justifyContent: "flex-start" },
                              ]}
                            >
                              <TextInput
                                id={`playersList[${i}].JerseyNo`}
                                style={styles.TeamModalInput}
                                placeholder={"J.No"}
                                value={item.JerseyNo}
                                onChangeText={(text) =>
                                  inputPlayerEvent("JerseyNo", text, i)
                                }
                              />
                              {item.JerseyNo == "" ? (
                                <Text style={{ color: "red" }}>required</Text>
                              ) : null}
                            </View>

                            <View style={styles.tableDataCell3}>
                              <TextInput
                                id="MobileNo"
                                style={styles.TeamModalInput}
                                placeholder={"Enter mobile no"}
                                onChangeText={(text) =>
                                  inputPlayerEvent("MobileNo", text, i)
                                }
                                value={item.MobileNo}
                                maxLength={10}
                                inputMode="numeric"
                              />
                              {(item.MobileNo && item.MobileNo.length < 10) ||
                              item.MobileNo.length > 10 ? (
                                <Text style={{ color: "red" }}>
                                  Invalid Number
                                </Text>
                              ) : null}
                            </View>
                            <Pressable
                              onPress={() => handleDeletePlayer(i)}
                              style={styles.scoreSheetDeleteBtn}
                            >
                              <TrashIcon
                                name="trash-bin-sharp"
                                size={18}
                                color="red"
                              />
                            </Pressable>
                          </View>
                        ))
                      ) : (
                        <Text>No Data found</Text>
                      )}
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 6,
                  }}
                >
                  <Pressable
                    onPress={handleAddPlayer}
                    style={styles.addTeamBtn}
                  >
                    <AddIcon name="plus-circle" size={16} color="#fff" />
                    <Text
                      style={{
                        color: "#fff",
                        fontSize: 12,
                        fontFamily: "PlusJakartaSansSemiBold",
                      }}
                    >
                      Add Player
                    </Text>
                  </Pressable>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: 20,
                    marginTop: 15,
                  }}
                >
                  <Pressable
                    onPress={() => handleClose()}
                    style={[
                      styles.btnPressable,
                      {
                        borderWidth: 1.5,
                        borderColor: colors.white,
                      },
                    ]}
                  >
                    <Text style={styles.btnText}>Cancel</Text>
                  </Pressable>

                  <Button
                    buttonStyle={{
                      backgroundColor: "#12FFE3",
                      borderRadius: 10,
                      borderColor: "transparent",
                      width: 150,
                      height: 30,
                    }}
                    titleStyle={{
                      textAlign: "center",
                      fontFamily: "PlusJakartaSansMedium",
                      fontSize: 14,
                      color: colors.royalBlueColour,
                    }}
                    containerStyle={{
                      // width: 200,
                      // height: 30,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onPress={() => handleSubmit()}
                    title="Submit"
                  />
                </View>
              </View>
            </View>
          </Modal>

          {errorModalVisible == true ? (
            <ErrorModal
              visibleModal={errorModalVisible}
              SuccessMessage={errorModalMesg}
              onSubmit={handleCloseErrorModal}
              header={undefined}
            />
          ) : null}

          {/* modal ended */}

          {successModalVisible == true ? (
            <SuccessAutoExitModal
              visibleModal={successModalVisible}
              SuccessMessage={successModalMesg}
              handleOk={handleClose}
            />
          ) : null}

          {/* Loader */}
          {loading && (
            <Modal transparent animationType="none">
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
              >
                <ActivityIndicator size="large" color="#0000ff" />
                <Text style={{ color: colors.white }}>Submitting</Text>
              </View>
            </Modal>
          )}
        </View>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  //
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    width: Platform.OS === "web" && screenWidth > 760 ? 700 : "95%",
    maxHeight: 0.95 * screenHeight,
    margin: 20,
    backgroundColor: colors.bgColor,
    borderRadius: 5,
    padding: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: "relative",
  },
  //
  scoreTeamLogo: {
    width: Platform.OS === "web" && screenWidth > 760 ? 100 : 60,
    height: Platform.OS === "web" && screenWidth > 760 ? 100 : 60,
    borderRadius: 50,
    resizeMode: "contain",
  },
  teamModalHeadingWrapper2: {
    flexDirection: Platform.OS == "web" && screenWidth > 760 ? "row" : "column",
    justifyContent: "space-between",
    gap: Platform.OS == "web" && screenWidth > 760 ? 20 : 2,
  },
  teamModalHeadingWrapper: {
    flex: Platform.OS == "web" && screenWidth > 760 ? 1 : undefined,
    gap: Platform.OS == "web" && screenWidth > 760 ? 5 : 2,
  },
  teamModalLabel: {
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 14 : 12,
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
  },

  // table
  tableHeaderContainer: {
    backgroundColor: colors.blue,
    display: "flex",
    flexDirection: "row",
    paddingRight: 15,
    marginBottom: 5,
  },
  tableHeaderTitle: {
    flex: 1,
    paddingVertical: 7,
    padding: 5,
  },
  tableHeaderJTitle: {
    width: 90,
    paddingVertical: 7,
    padding: 5,
  },
  tableHeaderTitleText: {
    fontFamily: "PlusJakartaSansBold",
    fontSize: 14,
    color: colors.white,
    textAlign: "center",
  },
  tableDataContainer: {
    backgroundColor: colors.royalBlue,
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
    borderRadius: 5,
  },

  tableDataCell: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  scoreSheetDeleteBtn: {
    width: 30,
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: 8,
  },
  tableDataCell2: {
    width: 60,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  tableDataCellText: {
    textAlign: "center",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
  },
  //   table ended
  btnText: {
    textAlign: "center",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
    color: colors.white,
  },
  btnPressable: {
    width: 150,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  topPlayerCount: {
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 5,
    borderRadius: 5,
    width: Platform.OS === "web" && screenWidth > 760 ? 150 : 110,
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
  mobTeamPlayer: {
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: colors.white,
    alignItems: "center",
    borderRadius: 5,
  },
  addTeamBtn: {
    backgroundColor: "#FF5733",
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    justifyContent: "center",
    width: 100,
    borderRadius: 5,
    padding: 4,
  },
  TeamModalInput: {
    borderColor: colors.borderColor,
    color: colors.white,
    backgroundColor: colors.royalBlue,
    borderWidth: 1,
    height: 30,
    width: "100%",
    borderRadius: 5,
    paddingHorizontal: 5,
    fontFamily: "PlusJakartaSansExtraLight",
  },
  tableHeaderTitle3: {
    flex: 1,
    maxWidth: 140,
    paddingVertical: 7,
    padding: 5,
  },
  tableDataCell3: {
    flex: 1,
    maxWidth: 140,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "flex-start",
    alignItems: "center",
  },
});
export default AddTeamModal;
